import { Link, useLocation } from "react-router-dom"
import {
  Flex,
  Text,
  Box,
  Tabs,
  TabList,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Button,
  Icon,
  FormControl,
  InputGroup,
  Input,
  InputRightElement,
  Select,
  Stack,
  Skeleton,
  InputLeftElement,
  Modal,
  ModalOverlay,
  ModalContent,
  Image,
  ModalCloseButton,
  Spinner,
  Center,
} from "@chakra-ui/react"
import { SearchIcon, CalendarIcon } from "@heroicons/react/outline"
import { useEffect, useState } from "react"
import {
  getPayments,
  getPaymentConfirmationByPaymentConfirmationId,
  updateStatusPaymentByPaymentId,
} from "../../api/payment"
import { PAYMENT_STATUS } from "../../constants/payment"
import { utcToLocal } from "../../helpers/date"
import { convertToRupiah } from "../../helpers/rupiah"
import { PaymentBadge } from "../../components/Badge"

const useQuery = () => new URLSearchParams(useLocation().search)

function PaymentConfirmation(props) {
  const initPaymentConfirmation = {
    id: "",
    sender: "",
    senderNumber: "",
    nominal: "",
    createdAt: "",
    payment: {
      amount: 0,
      id: "",
      status: "processed",
      userContent: {
        id: "",
        user: {
          id: "",
          email: "",
          phoneNumber: "",
          name: "",
        },
        content: {
          type: "",
          classBK: {
            name: "",
          },
        },
      },
    },
    bank: {
      bankName: "",
      accountName: "",
      accountNumber: "",
    },
    image: {
      id: "",
      url: "",
    },
  }
  const { paymentConfirmationModal, setPaymentConfirmationModal } = props
  const [paymentConfirmation, setPaymentConfirmation] = useState({
    data: { ...initPaymentConfirmation },
    isLoading: false,
    error: null,
  })
  useEffect(() => {
    const GetPaymentConfirm = async () => {
      setPaymentConfirmation({ ...paymentConfirmation, isLoading: true })
      const result = await getPaymentConfirmationByPaymentConfirmationId(paymentConfirmationModal.paymentConfirmId)
      if (result.success) {
        setPaymentConfirmation({ ...paymentConfirmation, data: result.data, isLoading: false })
      }
      return () => {
        setPaymentConfirmation({
          data: { ...initPaymentConfirmation },
          isLoading: false,
          error: null,
        })
      }
    }
    if (paymentConfirmationModal.paymentConfirmId !== "") {
      GetPaymentConfirm()
    }
  }, [paymentConfirmationModal.paymentConfirmId])
  const onClose = () => {
    setPaymentConfirmationModal({ ...paymentConfirmationModal, paymentConfirmId: "", isOpen: false })
  }
  const updateStatusPayment = async (id, status) => {
    setPaymentConfirmationModal({ ...paymentConfirmationModal, isLoading: true })
    const result = await updateStatusPaymentByPaymentId({ id, status })
    if (result.success) {
      paymentConfirmation.data.payment.status = status
      setPaymentConfirmationModal({ ...paymentConfirmationModal, isLoading: false })
    }
  }

  return (
    <Modal isOpen={paymentConfirmationModal.isOpen} onClose={onClose} size="5xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton color={["white", "gray.700"]} />
        {paymentConfirmation.isLoading && (
          <Center minH="75vh">
            <Spinner thickness="4px" speed="1s" emptyColor="gray.200" color="purple.450" size="lg" />
          </Center>
        )}
        {!paymentConfirmation.isLoading && (
          <Stack direction={["column", "row"]} minH="lg">
            <Stack direction="column" w={["full", "40%"]} bg="gray.700">
              <Box px="8" py="10">
                <Image
                  src={paymentConfirmation.data.image.url}
                  alt={`image payment confirmation form ${paymentConfirmation.data.payment.userContent.user.name}`}
                />
              </Box>
            </Stack>
            <Flex direction="column" w={["full", "60%"]} px="6" py="8">
              <Stack direction={["column", "row"]} alignItems="center">
                <Flex w="full">
                  <Text fontSize="xl" fontWeight="bold" color="gray.700">
                    Detail Pembayaran
                  </Text>
                </Flex>
                <Flex w="full">
                  <PaymentBadge
                    w="full"
                    display={PAYMENT_STATUS[paymentConfirmation.data.payment.status.toLocaleUpperCase()].display}
                    bgColor={PAYMENT_STATUS[paymentConfirmation.data.payment.status.toLocaleUpperCase()].bgColor}
                    textColor={PAYMENT_STATUS[paymentConfirmation.data.payment.status.toLocaleUpperCase()].textColor}
                  />
                </Flex>
              </Stack>
              <Stack direction={["column", "row"]} mt="6">
                <Flex direction="column" w="full">
                  <Stack spacing="3">
                    <Stack direction="column" spacing="1">
                      <Text fontWeight="medium" color="gray.700">
                        Kelas
                      </Text>
                      <Text color="gray.600">{paymentConfirmation.data.payment.userContent.content.classBK.name}</Text>
                    </Stack>
                    <Stack direction="column" spacing="1">
                      <Text fontWeight="medium" color="gray.700">
                        Kelompok
                      </Text>
                      <Text color="gray.600" textTransform="capitalize">
                        {paymentConfirmation.data.payment.userContent.content.type}
                      </Text>
                    </Stack>
                    <Stack direction="column" spacing="1">
                      <Text fontWeight="medium" color="gray.700">
                        Jumlah
                      </Text>
                      <Text color="gray.600">{convertToRupiah(paymentConfirmation.data.payment.amount)}</Text>
                    </Stack>
                    <Stack direction="column" spacing="1">
                      <Text fontWeight="medium" color="gray.700">
                        Nama
                      </Text>
                      <Text color="gray.600">{paymentConfirmation.data.payment.userContent.user.name}</Text>
                    </Stack>
                    <Stack direction="column" spacing="1">
                      <Text fontWeight="medium" color="gray.700">
                        No. Handphone
                      </Text>
                      <Text color="gray.600">{paymentConfirmation.data.payment.userContent.user.phoneNumber}</Text>
                    </Stack>
                    <Stack direction="column" spacing="1">
                      <Text fontWeight="medium" color="gray.700">
                        Email
                      </Text>
                      <Text color="gray.600">{paymentConfirmation.data.payment.userContent.user.email}</Text>
                    </Stack>
                    <Stack direction="column" spacing="1">
                      <Text fontWeight="medium" color="gray.700">
                        Bank Tujuan
                      </Text>
                      <Text color="gray.600">{paymentConfirmation.data.bank.bankName}</Text>
                    </Stack>
                  </Stack>
                </Flex>
                <Stack direction="column" w="full" spacing="3">
                  <Stack direction="column" spacing="1">
                    <Text fontWeight="medium" color="gray.700">
                      Nama Rekening
                    </Text>
                    <Text color="gray.600">{paymentConfirmation.data.sender}</Text>
                  </Stack>
                  <Stack direction="column" spacing="1">
                    <Text fontWeight="medium" color="gray.700">
                      Nomor Rekening
                    </Text>
                    <Text color="gray.600">{paymentConfirmation.data.senderNumber}</Text>
                  </Stack>
                  <Stack direction="column" spacing="1">
                    <Text fontWeight="medium" color="gray.700">
                      Nominal
                    </Text>
                    <Text color="gray.600">{convertToRupiah(paymentConfirmation.data.nominal)}</Text>
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction="row" justify="flex-end" spacing="4" mt="6">
                {paymentConfirmation.data.payment.status === PAYMENT_STATUS.PROCESSED.status && (
                  <Button
                    size="md"
                    px="10"
                    variant="outline"
                    onClick={() =>
                      updateStatusPayment(paymentConfirmation.data.payment.id, PAYMENT_STATUS.FAILED.status)
                    }
                    isDisabled={paymentConfirmationModal.isLoading}
                  >
                    Tolak
                  </Button>
                )}
                {paymentConfirmation.data.payment.status === PAYMENT_STATUS.PROCESSED.status && (
                  <Button
                    onClick={() =>
                      updateStatusPayment(paymentConfirmation.data.payment.id, PAYMENT_STATUS.REFUND.status)
                    }
                    size="md"
                    px="10"
                    isDisabled={paymentConfirmationModal.isLoading}
                  >
                    Refund
                  </Button>
                )}
                {paymentConfirmation.data.payment.status === PAYMENT_STATUS.SUCCESS.status && (
                  <Button
                    size="md"
                    px="10"
                    onClick={() =>
                      updateStatusPayment(paymentConfirmation.data.payment.id, PAYMENT_STATUS.REFUND.status)
                    }
                    isDisabled={paymentConfirmationModal.isLoading}
                  >
                    Refund
                  </Button>
                )}
                {paymentConfirmation.data.payment.status === PAYMENT_STATUS.PROCESSED.status && (
                  <Button
                    px="10"
                    onClick={() =>
                      updateStatusPayment(paymentConfirmation.data.payment.id, PAYMENT_STATUS.SUCCESS.status)
                    }
                    size="md"
                    isDisabled={paymentConfirmationModal.isLoading}
                  >
                    Terima
                  </Button>
                )}
              </Stack>
            </Flex>
          </Stack>
        )}
      </ModalContent>
    </Modal>
  )
}

export default function ManualPayments() {
  const shows = [10, 20, 50]
  const [query, setQuery] = useState({
    page: 0,
    show: 10,
    tab: useQuery().get("tab") ? parseInt(useQuery().get("tab")) : 0,
    search: "",
  })
  const menuPayments = [
    { display: "Semua", status: "" },
    ...Object.values(PAYMENT_STATUS).filter((item) => item.status != "unpaid"),
  ]
  const [payments, setPayments] = useState({ data: [], error: null, isLoading: false })
  const [pagination, setPagination] = useState({ totalItems: 0, totalPages: 0, currentPage: 0 })
  const [paymentConfirmationModal, setPaymentConfirmationModal] = useState({
    isOpen: false,
    paymentConfirmId: "",
    loading: false,
  })

  useEffect(() => {
    const GetPayments = async () => {
      setPayments({ ...payments, isLoading: true })
      const result = await getPayments({ ...query, status: menuPayments[query.tab].status })
      if (result.success) {
        const { totalItems, totalPages, currentPage } = result.data
        setPayments({ ...payments, data: result.data.payments, isLoading: false })
        setPagination({ totalItems, totalPages, currentPage })
      }
    }
    GetPayments()
    return () => {
      setPayments({ data: [], error: null, isLoading: false })
      setPagination({ totalItems: 0, totalPages: 0, currentPage: 0 })
    }
  }, [query])

  return (
    <Flex direction="column">
      <Text color="gray.750" fontSize="2xl" fontWeight="medium">
        Pembayaran Manual
      </Text>
      <Box bg="white" mt="4" px={[4, 5, 6]} py={8}>
        <Flex direction="column" minH="70vh">
          <Flex direction="column" mt={2}>
            <Tabs overflowX="auto">
              <TabList>
                {menuPayments.map((menu, index) => (
                  <Link to={`/transaction/manual-payment?tab=${index}`} key={index}>
                    <Box
                      p={4}
                      borderBottom={
                        parseInt(useQuery().get("tab")) === index || (useQuery().get("tab") === null && index === 0)
                          ? "2px"
                          : "none"
                      }
                      borderBottomColor={
                        parseInt(useQuery().get("tab")) === index || (useQuery().get("tab") === null && index === 0)
                          ? "purple.450"
                          : ""
                      }
                      position="relative"
                      mb="-2px"
                      onClick={() => setQuery({ ...query, tab: index.toString() })}
                    >
                      <Text
                        color={useQuery().get("tab") === index ? "purple.450" : "gray.750"}
                        fontSize="sm"
                        fontWeight="medium"
                      >
                        {menu.display}
                      </Text>
                    </Box>
                  </Link>
                ))}
              </TabList>
            </Tabs>
            <Stack direction={["column", "row"]} justify="space-between" py={8}>
              <Stack direction="row" w={["full", "xs"]}>
                <FormControl>
                  <Select onChange={(e) => setQuery({ ...query, show: e.target.value })}>
                    {shows.map((show, index) => (
                      <option value={show} key={index}>
                        {show}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl w={["lg", "2xl"]}>
                  <InputGroup>
                    <Input type="date" />
                    <InputLeftElement>
                      <Icon as={CalendarIcon} color="gray.550" h="4" w="4" />
                    </InputLeftElement>
                  </InputGroup>
                </FormControl>
              </Stack>
              <FormControl width={["full", "xs"]}>
                <InputGroup>
                  <Input
                    type="text"
                    placeholder="Search"
                    onChange={(e) => {
                      if (e.target.value.length === 0 || e.target.value.length >= 3) {
                        return setQuery({ ...query, search: e.target.value })
                      }
                    }}
                  />
                  <InputRightElement>
                    <Button variant="ghost" _focus={{ boxShadow: "none" }}>
                      <Icon as={SearchIcon} color="gray.550" h="4" w="4" />
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
            </Stack>
            <Flex overflowX="auto" overflowY="hidden" direction="column">
              {payments.isLoading && (
                <Stack spacing={6}>
                  {[...Array(6)].map((e, i) => (
                    <Stack key={i}>
                      <Skeleton startColor="gray.100" endColor="gray.200" height="30px" />
                    </Stack>
                  ))}
                </Stack>
              )}
              {!payments.isLoading && (
                <Table variant="simple" size="md" mb={4}>
                  <Thead>
                    <Tr color="gray.100" borderTop="1px">
                      <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                        DATE
                      </Th>
                      <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                        NAMA
                      </Th>
                      <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                        EMAIl
                      </Th>
                      <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                        TOTAL
                      </Th>
                      <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                        STATUS
                      </Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {payments.data.map((payment, index) => (
                      <Tr key={index} fontSize="sm" color="gray.550">
                        <Td>{utcToLocal(payment.createdAt, "DD MMMM YYYY HH:mm")}</Td>
                        <Td>{payment.userContent.user.name}</Td>
                        <Td>{payment.userContent.user.email}</Td>
                        <Td>{convertToRupiah(payment.amount)}</Td>
                        <Td>
                          <PaymentBadge
                            display={PAYMENT_STATUS[payment.status.toUpperCase()].display}
                            bgColor={PAYMENT_STATUS[payment.status.toUpperCase()].bgColor}
                            textColor={PAYMENT_STATUS[payment.status.toUpperCase()].textColor}
                          />
                        </Td>
                        <Td>
                          {payment.paymentConfirm !== null && (
                            <Button
                              size="sm"
                              fontSize="xs"
                              variant="outline"
                              color="purple.450"
                              borderColor="purple.450"
                              px="4"
                              onClick={() =>
                                setPaymentConfirmationModal({
                                  isOpen: true,
                                  paymentConfirmId: payment.paymentConfirm.id,
                                })
                              }
                            >
                              Detail
                            </Button>
                          )}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                  <Tfoot></Tfoot>
                </Table>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Stack spacing="2" direction={["column", "row"]} justify="space-between" mt="4">
          <Text fontSize="sm" color="gray.550">
            Menampilkan 1 sampai {query.show} dari {pagination.totalItems} data
          </Text>
          {pagination.totalItems > query.show && (
            <Stack direction="row" spacing="1">
              {pagination.currentPage > 0 && (
                <Button variant="outline" onClick={() => setQuery({ ...query, page: query.page - 1 })}>
                  Sebelumnya
                </Button>
              )}
              {[...Array(pagination.totalPages)].map((e, i) => (
                <Button
                  variant={i === pagination.currentPage ? "solid" : "outline"}
                  onClick={() => setQuery({ ...query, page: i })}
                  key={i}
                >
                  {i + 1}
                </Button>
              ))}
              {pagination.currentPage < pagination.totalPages - 1 && (
                <Button variant="outline" onClick={() => setQuery({ ...query, page: query.page + 1 })}>
                  Berikutnya
                </Button>
              )}
            </Stack>
          )}
        </Stack>
      </Box>
      <PaymentConfirmation
        paymentConfirmationModal={paymentConfirmationModal}
        setPaymentConfirmationModal={setPaymentConfirmationModal}
      />
    </Flex>
  )
}
