import { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { joiResolver } from "@hookform/resolvers/joi"
import Joi from "joi"
import {
  Flex,
  Button,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Text,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Icon,
  HStack,
  Skeleton,
  InputGroup,
  InputRightElement,
  Alert,
  AlertIcon,
  Box,
} from "@chakra-ui/react"
import { PlusIcon, ChevronDownIcon } from "@heroicons/react/solid"
import { PencilIcon, TrashIcon, SearchIcon } from "@heroicons/react/outline"
import {
  getStudyTypes,
  getStudyTypeByStudyTypeId,
  storeStudyType,
  updateStudyTypeByStudyTypeId,
  deleteStudyTypeByStudyTypeId,
} from "../../../../api/studyType"
import { MODAL_TYPE } from "../../../../constants/modal"
import { DeleteAlert } from "../../../../components/Alert"

const schema = Joi.object({
  id: Joi.string().allow(""),
  name: Joi.string().required(),
})

export default function StudyType() {
  const [changeState, setChangeState] = useState(false)
  const [studyTypes, setStudyTypes] = useState({ data: [], error: null, isLoading: false })
  const [studyType, setStudyType] = useState({
    isLoading: false,
    error: null,
  })
  const [formModal, setFormModal] = useState({
    type: MODAL_TYPE.STORE,
    isOpen: false,
    isLoading: false,
    error: null,
  })
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    studyTypeId: "",
    error: null,
    isLoading: false,
  })
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ resolver: joiResolver(schema) })

  useEffect(() => {
    const GetStudyTypes = async () => {
      setStudyTypes({ ...studyTypes, isLoading: true })
      const result = await getStudyTypes()
      if (result.success === true) {
        setStudyTypes({ ...studyTypes, data: result.data.studyTypes, isLoading: false })
      } else {
        setStudyTypes({ ...studyTypes, error: result.error.message, isLoading: false })
      }
    }
    GetStudyTypes()
    return () => {
      setStudyTypes({ data: [], error: null, isLoading: false })
    }
  }, [changeState])

  const submitStoreStudyType = async (data) => {
    setFormModal({ ...formModal, isLoading: true })
    const result = await storeStudyType(data)
    if (result.success === true) {
      setFormModal({ ...formModal, isLoading: false })
      closeModal()
      setChangeState(!changeState)
    } else {
      setFormModal({ ...formModal, isLoading: false, error: result.error.message })
    }
  }
  const submitUpdateStudyType = async (data) => {
    setFormModal({ ...formModal, isLoading: true })
    const result = await updateStudyTypeByStudyTypeId(data)
    if (result.success === true) {
      setFormModal({ ...formModal, isLoading: false })
      closeModal()
      setChangeState(!changeState)
    } else {
      setFormModal({ ...formModal, isLoading: false, error: result.error.message })
    }
  }
  const submitDeleteStudyType = async (id) => {
    setDeleteModal({ ...deleteModal, isLoading: true })
    const result = await deleteStudyTypeByStudyTypeId(id)
    if (result.success === true) {
      setDeleteModal({ ...deleteModal, isOpen: false, isLoading: false })
      setChangeState(!changeState)
    } else {
      setDeleteModal({ ...deleteModal, error: "Jenis pelajaran gagal dihapus", isLoading: false })
    }
  }
  const closeModal = () => {
    setValue("id", "")
    setValue("name", "")
    setFormModal({ ...formModal, isOpen: false, error: null })
  }

  const openModal = async (studyTypeId = "", type = MODAL_TYPE.STORE) => {
    setFormModal({ ...formModal, type, isOpen: true })
    if (type === MODAL_TYPE.UPDATE) {
      setStudyType({ ...studyType, isLoading: true })
      const result = await getStudyTypeByStudyTypeId(studyTypeId)
      if (result.success) {
        const {
          studyType: { name, id },
        } = result.data
        setValue("id", id)
        setValue("name", name)
        setStudyType({ ...studyType, isLoading: false })
      }
    }
  }
  const openDeleteModal = (studyTypeId) => {
    setDeleteModal({ ...deleteModal, studyTypeId, isOpen: true })
  }

  return (
    <Flex direction="column" minH="70vh">
      <Flex py="8" justify="space-between" spacing={16} direction={["column", "row"]}>
        <FormControl width={["full", "xs"]}>
          <InputGroup>
            <Input type="text" placeholder="Search" />
            <InputRightElement>
              <Button variant="ghost" _focus={{ boxShadow: "none" }}>
                <Icon as={SearchIcon} color="gray.550" h="4" w="4" />
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <Button onClick={() => openModal()} mt={["2", "0"]}>
          Tambah Jenis Pelajaran
          <Icon as={PlusIcon} h="5" w="5" ml="2" />
        </Button>
        <Modal isCentered size="sm" blockScrollOnMount={true} isOpen={formModal.isOpen} onClose={closeModal}>
          <ModalOverlay />
          <ModalContent p="2">
            <ModalHeader>
              <Text color="gray.750">{formModal.type === MODAL_TYPE.STORE ? "Tambah" : "Edit"} Jenis Pelajaran</Text>
            </ModalHeader>
            <ModalCloseButton _focus={{ boxShadow: "none" }} />
            <Box px={6}>
              {formModal.error && (
                <Alert size="sm" status="error">
                  <AlertIcon />
                  Jenis pelajaran gagal di{formModal.type === MODAL_TYPE.STORE ? "tambahkan" : "edit"}
                </Alert>
              )}
            </Box>
            <ModalBody my="3">
              <form
                onSubmit={handleSubmit(
                  formModal.type === MODAL_TYPE.STORE ? submitStoreStudyType : submitUpdateStudyType
                )}
              >
                <Stack spacing="3">
                  {studyType.isLoading && (
                    <Stack>
                      <Skeleton startColor="gray.100" endColor="gray.200" height="40px" />
                    </Stack>
                  )}
                  {!studyType.isLoading && (
                    <FormControl>
                      <FormLabel fontSize="sm" color="gray.550">
                        Nama
                      </FormLabel>
                      <Input type="text" {...register("name")} isInvalid={errors.name} />
                      <Text fontSize="sm" color="red.500">
                        {errors.name?.message}
                      </Text>
                    </FormControl>
                  )}
                </Stack>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button mr={3} onClick={closeModal} variant="outline" isDisabled={formModal.isLoading}>
                Batal
              </Button>
              <Button
                onClick={handleSubmit(
                  formModal.type === MODAL_TYPE.STORE ? submitStoreStudyType : submitUpdateStudyType
                )}
                isLoading={formModal.isLoading}
                type="submit"
              >
                Simpan
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
      <Flex overflowX="auto" overflowY="hidden" direction="column">
        {studyTypes.isLoading ? (
          <Stack spacing={6}>
            {[...Array(6)].map((e, i) => (
              <Stack key={i}>
                <Skeleton startColor="gray.100" endColor="gray.200" height="30px" />
              </Stack>
            ))}
          </Stack>
        ) : (
          <Table variant="simple" size="md" mb={4}>
            <Thead>
              <Tr color="gray.100" borderTop="1px">
                <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                  NAMA
                </Th>
                <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                  DIBUAT OLEH
                </Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {studyTypes.data.map((studyType) => (
                <Tr key={studyType.id} fontSize="sm" color="gray.550">
                  <Td fontWeight="semibold">{studyType.name}</Td>
                  <Td>Super Admin</Td>
                  <Td>
                    <Menu>
                      <MenuButton
                        px={4}
                        py={2}
                        transition="all 0.2s"
                        borderRadius="md"
                        borderWidth="1px"
                        _focus={{ boxShadow: "none" }}
                        fontWeight="semibold"
                      >
                        Atur
                        <Icon ml="2" as={ChevronDownIcon} h="5" w="5" />
                      </MenuButton>
                      <MenuList>
                        <MenuItem onClick={() => openModal(studyType.id, MODAL_TYPE.UPDATE)}>
                          <HStack w="full">
                            <Icon as={PencilIcon} h="4" w="4" />
                            <Text>Edit</Text>
                          </HStack>
                        </MenuItem>
                        <MenuItem onClick={() => openDeleteModal(studyType.id)}>
                          <HStack w="full">
                            <Icon as={TrashIcon} h="4" w="4" />
                            <Text>Hapus</Text>
                          </HStack>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              ))}
            </Tbody>
            <Tfoot></Tfoot>
          </Table>
        )}
      </Flex>
      <DeleteAlert
        isOpen={deleteModal.isOpen}
        title="Jenis Pelajaran"
        isLoading={deleteModal.isLoading}
        action={() => submitDeleteStudyType(deleteModal.studyTypeId)}
        setOpen={setDeleteModal}
      />
    </Flex>
  )
}
