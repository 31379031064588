import { Image, Flex } from "@chakra-ui/react"
import MobileNavbar from "./MobileNavbar"
import bidikkampusLogo from "../assets/bidikkampus.png"

export default function Navbar() {
  return (
    <Flex pos="fixed" as="nav" align="center" h="52px" w="full" px={[4, 6, 8]} bg={"white"} boxShadow="base">
      <Flex w="full" align="center" justify="space-between">
        <Flex align="center">
          <Image src={bidikkampusLogo} w="150px" />
        </Flex>
        <Flex>
          <MobileNavbar />
        </Flex>
      </Flex>
    </Flex>
  )
}
