import Axios from "../helpers/axios"

export const getPayments = async (query = {}) => {
  try {
    const { status = "", show = 10, page = 0, search = "", sortBy = "DESC", verificationMethod = "" } = query
    const { data: response } = await Axios.get(
      `/payment?status=${status}&verificationMethod=${verificationMethod}&show=${show}&page=${page}&email=${search}&sortBy=${sortBy}`
    )
    return response
  } catch (err) {
    return err.response.data
  }
}

export const getPaymentByPaymentId = async (id) => {
  try {
    const { data: response } = await Axios.get(`/payment/${id}`)
    return response
  } catch (err) {
    return err.response.data
  }
}

export const getPaymentConfirmationByPaymentConfirmationId = async (id) => {
  try {
    const { data: response } = await Axios.get(`/payment-confirm/${id}`)
    return response
  } catch (err) {
    return err.response.data
  }
}

export const updateStatusPaymentByPaymentId = async (data) => {
  try {
    const { id, status } = data
    const { data: response } = await Axios.put(`/payment/${id}`, { status })
    return response
  } catch (err) {
    return err.response.data
  }
}
