import Axios from "../helpers/axios"

/**
 * getImages
 * @typedef {{show:number, page:number, folder:string}} Query
 * @param {Query} query
 * @returns {object}
 */
export const getImages = async (query = {}) => {
  try {
    const { show = 10, page = 0, folder = "", sortBy = "DESC" } = query
    const { data: response } = await Axios.get(`/image?folder=${folder}&show=${show}&page=${page}&sortBy=${sortBy}`)
    return response
  } catch (err) {
    return err.response.data
  }
}

/**
 * storeImage
 * @param {{image:string}} data
 * @returns {object}
 */
export const storeImage = async (data) => {
  try {
    const { data: response } = await Axios.post("/image", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    return response
  } catch (err) {
    return err.response.data
  }
}

/**
 * deleteImage
 * @param {string} id
 * @returns {object}
 */
export const deleteImage = async (id) => {
  try {
    const { data: response } = await Axios.delete(`/image/${id}`)
    return response
  } catch (err) {
    return err.response.data
  }
}
