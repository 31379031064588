import { BrowserRouter, Routes, Route } from "react-router-dom"
import { USER_ROLE } from "./constants/user"
import { DashboardLayout } from "./layouts"
import { Authentication, Authorization } from "./helpers/auth"
import { ListMember, DetailMember } from "./pages/member"
import { Login, Logout } from "./pages/auth"
import { Forbidden, NotFound } from "./pages/error"
import Dashboard from "./pages/dashboard/Dashboard"
import { ListClass, CreateClass, UpdateClass } from "./pages/class"
import { ListContent, CreateContent, UpdateContent, MaterialContent } from "./pages/content"
import { ListStudy, ListQuestion, CreateQuestion, UpdateQuestion } from "./pages/question"
import { AutomaticPayments, ManualPayments, FreePayments } from "./pages/transaction"
import { ListFinance, ListFinanceByContent } from "./pages/finance"
import { ListFolder, ListImage } from "./pages/media"
import { Feature, User } from "./pages/settings"
const { SUPERADMIN, ADMIN } = USER_ROLE

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" index element={<Login />} />
        <Route path="logout" element={<Logout />} />
        <Route path="forbidden" element={<Forbidden />} />
        <Route element={<DashboardLayout />}>
          <Route element={<Authentication />}>
            <Route path="dashboard" element={<Dashboard />} />

            <Route element={<Authorization roles={[SUPERADMIN, ADMIN]} />}>
              <Route path="member" element={<ListMember />} />
              <Route path="member/:userId" element={<DetailMember />} />
            </Route>

            <Route element={<Authorization roles={[SUPERADMIN, ADMIN]} />}>
              <Route path="class">
                <Route path="" index element={<ListClass />} />
                <Route path="create" element={<CreateClass />} />
                <Route path="update/:classId" element={<UpdateClass />} />
                <Route path=":classId/create-content" element={<CreateContent />} />
              </Route>
            </Route>

            <Route path="content">
              <Route path="" index element={<ListContent />} />
              <Route path=":contentId/material/:materialId" element={<MaterialContent />} />
              <Route element={<Authorization roles={[SUPERADMIN, ADMIN]} />}>
                <Route path="update/:contentId" element={<UpdateContent />} />
              </Route>
            </Route>

            <Route path="question">
              <Route path="statistics" element={<ListStudy />} />
              <Route path="" index element={<ListQuestion />} />
              <Route path="create" element={<CreateQuestion />} />
              <Route path="update/:questionId" element={<UpdateQuestion />} />
            </Route>

            <Route element={<Authorization roles={[SUPERADMIN, ADMIN]} />}>
              <Route path="transaction">
                <Route path="manual-payment" element={<ManualPayments />} />
                <Route path="automatic-payment" element={<AutomaticPayments />} />
                <Route path="free-payment" element={<FreePayments />} />
              </Route>
            </Route>

            <Route element={<Authorization roles={[SUPERADMIN, ADMIN]} />}>
              <Route path="finance">
                <Route path="" index element={<ListFinance />} />
                <Route path=":contentId" element={<ListFinanceByContent />} />
              </Route>
            </Route>

            <Route path="media">
              <Route path="" index element={<ListFolder />} />
              <Route path=":folder" element={<ListImage />} />
            </Route>

            <Route element={<Authorization roles={[SUPERADMIN, ADMIN]} />}>
              <Route path="setting">
                <Route path="feature" element={<Feature />} />
                <Route path="user" element={<User />} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
