import Axios from "../helpers/axios"

export const getFinances = async (query = {}) => {
  try {
    const { show = 10, page = 0, search = "" } = query
    const { data: response } = await Axios.get(`/finance?show=${show}&page=${page}&search=${search}`)
    return response
  } catch (err) {
    return err.response.data
  }
}

export const getFinanceStatistics = async () => {
  try {
    const { data: response } = await Axios.get("/finance/statistics")
    return response
  } catch (err) {
    return err.response.data
  }
}

export const getFinancesByContentId = async (contentId, query = {}) => {
  try {
    const { show = 10, page = 0, search = "" } = query
    const { data: response } = await Axios.get(`/finance/${contentId}?show=${show}&page=${page}&search=${search}`)
    return response
  } catch (err) {
    return err.response.data
  }
}
