import { useContext } from "react"
import { Navigate, Outlet } from "react-router-dom"
import { StateContext } from "../store"

export function Authentication() {
  const [state] = useContext(StateContext)
  if (!state.isAuthenticated) {
    return <></>
  }
  return <Outlet />
}

export function Authorization({ roles = [] }) {
  const [state] = useContext(StateContext)
  if (state.isAuthenticated && !roles.includes(state.currentUser.role) && roles.length !== 0) {
    return <Navigate to={{ pathname: "/forbidden" }} />
  }
  return <Outlet />
}
