import Axios from "../helpers/axios"

export const getMaterials = async (query = {}) => {
  try {
    const { show = 10, page = 0 } = query
    const { data: response } = await Axios.get(`/material?show=${show}&page=${page}`)
    return response
  } catch (err) {
    return err.response.data
  }
}

export const storeMaterial = async (data) => {
  try {
    const { name } = data
    const { data: response } = await Axios.post("/material", { name })
    return response
  } catch (err) {
    return err.response.data
  }
}

export const getMaterialByMaterialId = async (id) => {
  try {
    const { data: response } = await Axios.get(`/material/${id}`)
    return response
  } catch (err) {
    return err.response.data
  }
}

export const updateMaterialByMaterialId = async (data) => {
  try {
    const { id, name } = data
    const { data: response } = await Axios.put(`/material/${id}`, { name })
    return response
  } catch (err) {
    return err.response.data
  }
}

export const deleteMaterialByMaterialId = async (id) => {
  try {
    const { data: response } = await Axios.delete(`/material/${id}`)
    return response
  } catch (err) {
    return err.response.data
  }
}

export const getStudiesByMaterialId = async (materialId, contentId) => {
  try {
    const { data: response } = await Axios.get(`/material/${materialId}/study?contentId=${contentId}&question=true`)
    return response
  } catch (err) {
    return err.response.data
  }
}

export const addStudyToMaterial = async (data) => {
  try {
    console.log(data)
    const { materialId, studyTypeId, studyId, time } = data
    const { data: response } = await Axios.post(`/material/${materialId}/add-study`, { studyTypeId, studyId, time })
    return response
  } catch (err) {
    return err.response.data
  }
}
