import Axios from "../helpers/axios"

export const getBanks = async (query = {}) => {
  try {
    const { show = 10, page = 0 } = query
    const { data: response } = await Axios.get(`/bank?show=${show}&page=${page}`)
    return response
  } catch (err) {
    return err.response.data
  }
}

export const storeBank = async (data) => {
  try {
    const { bankName, accountName, accountNumber } = data
    const { data: response } = await Axios.post("/bank", { bankName, accountName, accountNumber })
    return response
  } catch (err) {
    return err.response.data
  }
}

export const getBankByBankId = async (id) => {
  try {
    const { data: response } = await Axios.get(`/bank/${id}`)
    return response
  } catch (err) {
    return err.response.data
  }
}

export const updateBankByBankId = async (data) => {
  try {
    const { id, bankName, accountName, accountNumber } = data
    const { data: response } = await Axios.put(`/bank/${id}`, { bankName, accountName, accountNumber })
    return response
  } catch (err) {
    return err.response.data
  }
}

export const deleteBankByBankId = async (id) => {
  try {
    const { data: response } = await Axios.delete(`/bank/${id}`)
    return response
  } catch (err) {
    return err.response.data
  }
}
