import { Flex, Text, Stack, Skeleton } from "@chakra-ui/react"
import { utcToLocal } from "../../../helpers/date"

function Account(props) {
  const { user } = props

  return (
    <Flex direction="column">
      <Text fontSize="md" color="gray.750" fontWeight="semibold">
        Detail Akun
      </Text>
      <Stack spacing="4" direction="column" mt="4">
        <Stack direction="row" w="full">
          {user.isLoading && <Skeleton startColor="gray.100" endColor="gray.200" height="24px" w="full" />}
          {!user.isLoading && (
            <Stack direction={["column", "row"]} spacing="0" fontSize="sm" w="full">
              <Flex w={["full", "40%", "30%"]}>
                <Text color="gray.550">Nama</Text>
              </Flex>
              <Flex w={["full", "60%", "70%"]}>
                <Text color="gray.600" fontWeight="medium">
                  {user.data.name}
                </Text>
              </Flex>
            </Stack>
          )}
        </Stack>
        <Stack direction="row" w="full">
          {user.isLoading && <Skeleton startColor="gray.100" endColor="gray.200" height="24px" w="full" />}
          {!user.isLoading && (
            <Stack direction={["column", "row"]} spacing="0" fontSize="sm" w="full">
              <Flex w={["full", "40%", "30%"]}>
                <Text color="gray.550">Email</Text>
              </Flex>
              <Flex w={["full", "60%", "70%"]}>
                <Text color="gray.600" fontWeight="medium">
                  {user.data.email}
                </Text>
              </Flex>
            </Stack>
          )}
        </Stack>
        <Stack direction="row" w="full">
          {user.isLoading && <Skeleton startColor="gray.100" endColor="gray.200" height="24px" w="full" />}
          {!user.isLoading && (
            <Stack direction={["column", "row"]} spacing="0" fontSize="sm" w="full">
              <Flex w={["full", "40%", "30%"]}>
                <Text color="gray.550">Nomor Handphone</Text>
              </Flex>
              <Flex w={["full", "60%", "70%"]}>
                <Text color="gray.600" fontWeight="medium">
                  {user.data.phoneNumber}
                </Text>
              </Flex>
            </Stack>
          )}
        </Stack>
        <Stack direction="row" w="full">
          {user.isLoading && <Skeleton startColor="gray.100" endColor="gray.200" height="24px" w="full" />}
          {!user.isLoading && (
            <Stack direction={["column", "row"]} spacing="0" fontSize="sm" w="full">
              <Flex w={["full", "40%", "30%"]}>
                <Text color="gray.550">Jenis Kelamin</Text>
              </Flex>
              <Flex w={["full", "60%", "70%"]}>
                <Text color="gray.600" fontWeight="medium">
                  {user.data.gender !== null && (user.data.gender === "male" ? "laki-laki" : "perempuan")}
                </Text>
              </Flex>
            </Stack>
          )}
        </Stack>
        <Stack direction="row" w="full">
          {user.isLoading && <Skeleton startColor="gray.100" endColor="gray.200" height="24px" w="full" />}
          {!user.isLoading && (
            <Stack direction={["column", "row"]} spacing="0" fontSize="sm" w="full">
              <Flex w={["full", "40%", "30%"]}>
                <Text color="gray.550">Tanggal Lahir</Text>
              </Flex>
              <Flex w={["full", "60%", "70%"]}>
                <Text color="gray.600" fontWeight="medium">
                  {user.data.dateOfBirth !== null ? utcToLocal(user.data.dateOfBirth, "DD MMMM YYYY") : ""}
                </Text>
              </Flex>
            </Stack>
          )}
        </Stack>
        <Stack direction="row" w="full">
          {user.isLoading && <Skeleton startColor="gray.100" endColor="gray.200" height="24px" w="full" />}
          {!user.isLoading && (
            <Stack direction={["column", "row"]} spacing="0" fontSize="sm" w="full">
              <Flex w={["full", "40%", "30%"]}>
                <Text color="gray.550">Provinsi</Text>
              </Flex>
              <Flex w={["full", "60%", "70%"]}>
                <Text color="gray.600" fontWeight="medium">
                  {user.data.province}
                </Text>
              </Flex>
            </Stack>
          )}
        </Stack>
        <Stack direction="row" w="full">
          {user.isLoading && <Skeleton startColor="gray.100" endColor="gray.200" height="24px" w="full" />}
          {!user.isLoading && (
            <Stack direction={["column", "row"]} spacing="0" fontSize="sm" w="full">
              <Flex w={["full", "40%", "30%"]}>
                <Text color="gray.550">Sekolah</Text>
              </Flex>
              <Flex w={["full", "60%", "70%"]}>
                <Text color="gray.600" fontWeight="medium">
                  {user.data.nameOfInstitution}
                </Text>
              </Flex>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Flex>
  )
}

export default Account
