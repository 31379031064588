import { Box, Stack, Text } from "@chakra-ui/react"

function Toast(props) {
  const { message, color, bgColor } = props
  return (
    <Box bg={bgColor} mt="20" py="2" borderRadius="lg">
      <Stack direction="row" justify="center">
        <Text color={color} fontSize="sm" fontWeight="normal">
          {message}
        </Text>
      </Stack>
    </Box>
  )
}

export default Toast
