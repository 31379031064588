import { Flex, Text, Center, Spinner, Stack } from "@chakra-ui/react"
import { FormQuestion } from "./components"
import { useForm } from "react-hook-form"
import { joiResolver } from "@hookform/resolvers/joi"
import Joi from "joi"
import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect } from "react"
import { getQuestionByQuestionId, updateQuestionByQuestion } from "../../api/question"
import { getUsers } from "../../api/user"
import { getStudies } from "../../api/study"

function Update() {
  const navigate = useNavigate()
  const { questionId } = useParams()
  const schema = Joi.object({
    id: Joi.string().required(),
    studyId: Joi.string().required(),
    level: Joi.string().required(),
    question: Joi.string().required(),
    userId: Joi.string().required(),
    choice: Joi.number().required(),
    choices: Joi.array().required(),
    correctionId: Joi.string().required(),
    discussion: Joi.string().required(),
    originalChoices: Joi.array().required(),
  })
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    control,
  } = useForm({
    resolver: joiResolver(schema),
  })
  const [question, setQuestion] = useState({ isLoading: false, error: null })
  const [updateQuestion, setUpdateQuestion] = useState({ isLoading: false, error: null })
  const [users, setUsers] = useState({ data: [], isLoading: false, error: null })
  const [studies, setStudies] = useState({ data: [], isLoading: false, error: null })

  const GetStudies = async () => {
    setStudies({ ...studies, isLoading: true })
    const result = await getStudies({ show: 50 })
    if (result.success) {
      setStudies({ ...studies, data: result.data.studies, isLoading: false })
    }
  }
  const GetUsers = async () => {
    setUsers({ ...users, isLoading: true })
    const result = await getUsers({ show: 20, role: "author" })
    if (result.success) {
      setUsers({ ...users, data: result.data.users, isLoading: false })
    }
  }

  const GetQuestion = async () => {
    setQuestion({ ...question, isLoading: true })
    const result = await getQuestionByQuestionId(questionId)
    if (result.success) {
      const { id, user, study, level, question: questionData, correction, choices } = result.data
      const { id: userId } = user
      const { id: studyId } = study
      const { id: correctionId, discussion } = correction
      let choice = ""
      const infoChoices = choices.map((item, index) => {
        if (item.isCorrect) {
          choice = index.toString()
        }
        return item.info
      })
      setValue("id", id)
      setValue("userId", userId)
      setValue("studyId", studyId)
      setValue("level", level)
      setValue("question", questionData)
      setValue("correctionId", correctionId)
      setValue("discussion", discussion)
      setValue("choices", infoChoices)
      setValue("choice", choice)
      setValue("originalChoices", choices)
      setQuestion({ ...question, isLoading: false })
    }
  }

  useEffect(() => {
    GetStudies()
    GetUsers()
    return () => {
      setUsers({ data: [], isLoading: false, error: null })
      setStudies({ data: [], isLoading: false, error: null })
    }
  }, [])

  useEffect(() => {
    GetQuestion()
    return () => setQuestion({ isLoading: false, error: null })
  }, [questionId])

  const onSubmit = async (data) => {
    setUpdateQuestion({ ...updateQuestion, isLoading: true })
    const originalChoices = getValues("originalChoices")
    const choices = getValues("choices")
    const customChoices = originalChoices.map((choice, index) => ({
      id: choice.id,
      isCorrect: index === parseInt(data.choice) ? true : false,
      info: choices[index],
    }))
    const customData = {
      ...data,
      correction: { id: getValues("correctionId"), discussion: data.discussion },
      choices: customChoices,
    }

    const result = await updateQuestionByQuestion(customData)
    if (result.success) {
      setUpdateQuestion({ ...updateQuestion, isLoading: false })
      const study = studies.data.find((study) => study.id === getValues("studyId"))
      navigate(`/question?studyId=${study.id}&study=${study.name.replaceAll(" ", "-")}`)
    }
  }

  return (
    <Flex direction="column">
      <Text color="gray.750" fontSize="2xl" fontWeight="medium">
        Edit Soal
      </Text>
      {(question.isLoading || question.isLoading || users.isLoading) && (
        <Stack direction="column" mt="4" spacing="4">
          <Flex direction="column">
            <Center minH="40vh" bg="white">
              <Spinner thickness="4px" speed="1s" emptyColor="gray.200" color="purple.450" size="lg" />
            </Center>
          </Flex>
          <Flex direction="column">
            <Center minH="50vh" bg="white">
              <Spinner thickness="4px" speed="1s" emptyColor="gray.200" color="purple.450" size="lg" />
            </Center>
          </Flex>
          <Flex direction="column">
            <Center minH="40vh" bg="white">
              <Spinner thickness="4px" speed="1s" emptyColor="gray.200" color="purple.450" size="lg" />
            </Center>
          </Flex>
        </Stack>
      )}
      {!question.isLoading && !users.isLoading && !studies.isLoading && (
        <FormQuestion
          type="update"
          setValue={setValue}
          getValues={getValues}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          control={control}
          errors={errors}
          register={register}
          users={users}
          studies={studies}
          question={updateQuestion}
        />
      )}
    </Flex>
  )
}

export default Update
