import { Link, useLocation } from "react-router-dom"
import {
  Flex,
  Text,
  Box,
  Tabs,
  TabList,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Button,
  Icon,
  FormControl,
  InputGroup,
  Input,
  InputRightElement,
  Select,
  Stack,
  Skeleton,
  InputLeftElement,
  Modal,
  ModalOverlay,
  ModalContent,
  Image,
  ModalCloseButton,
  Spinner,
  Center,
} from "@chakra-ui/react"
import { SearchIcon, CalendarIcon } from "@heroicons/react/outline"
import { useEffect, useState } from "react"
import { FREE_PAYMENT_STATUS } from "../../constants/payment"
import { utcToLocal } from "../../helpers/date"
import { PaymentBadge } from "../../components/Badge"
import {
  getFreePaymentByFreePaymentId,
  getFreePayments,
  updateStatusFreePaymentByFreePaymentId,
} from "../../api/freePayment"

const useQuery = () => new URLSearchParams(useLocation().search)

function FreePaymentConfirmation(props) {
  const initFreePaymentConfirmation = {
    id: "",
    status: "approved",
    createdAt: "",
    userContent: {
      id: "",
      user: {
        id: "",
        email: "",
        name: "",
        phoneNumber: "",
      },
      content: {
        type: "",
        classBK: {
          id: "",
          name: "",
        },
      },
    },
    followImg: { url: "" },
    commentImg: { url: "" },
    shareGroupImg: { url: "" },
    shareStoryImg: { url: "" },
  }
  const { freePaymentConfirmationModal, setFreePaymentConfirmationModal } = props
  const [freePaymentConfirmation, setFreePaymentConfirmation] = useState({
    data: { ...initFreePaymentConfirmation },
    isLoading: false,
    error: null,
  })
  useEffect(() => {
    const GetPaymentConfirm = async () => {
      setFreePaymentConfirmation({ ...freePaymentConfirmation, isLoading: true })
      const result = await getFreePaymentByFreePaymentId(freePaymentConfirmationModal.freePaymentId)
      if (result.success) {
        setFreePaymentConfirmation({ ...freePaymentConfirmation, data: result.data.task, isLoading: false })
      }

      return () => {
        setFreePaymentConfirmation({
          data: { ...initFreePaymentConfirmation },
          isLoading: false,
          error: null,
        })
      }
    }
    if (freePaymentConfirmationModal.freePaymentId !== "") {
      GetPaymentConfirm()
    }
  }, [freePaymentConfirmationModal.freePaymentId])
  const onClose = () => {
    setFreePaymentConfirmationModal({ ...freePaymentConfirmationModal, freePaymentId: "", isOpen: false })
  }
  const updateStatusFreePayment = async (id, status) => {
    setFreePaymentConfirmationModal({ ...freePaymentConfirmationModal, isLoading: true })
    const result = await updateStatusFreePaymentByFreePaymentId({ id, status })
    if (result.success) {
      freePaymentConfirmation.data.status = status
      setFreePaymentConfirmationModal({ ...freePaymentConfirmationModal, isLoading: false })
    }
  }

  return (
    <Modal isOpen={freePaymentConfirmationModal.isOpen} onClose={onClose} size="lg" isCentered="true">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton color={["white", "gray.700"]} />
        {freePaymentConfirmation.isLoading && (
          <Center height="92vh">
            <Spinner thickness="4px" speed="1s" emptyColor="gray.200" color="purple.450" size="lg" />
          </Center>
        )}
        {!freePaymentConfirmation.isLoading && (
          <Stack direction={["column", "row"]}>
            <Flex direction="column" w="full" px="6" py="8">
              <Stack direction={["column", "row"]} alignItems="center">
                <Flex w="full">
                  <Text fontSize="xl" fontWeight="bold" color="gray.700">
                    Detail Paket Gratis
                  </Text>
                </Flex>
                <Flex w="full">
                  <PaymentBadge
                    w="full"
                    display={FREE_PAYMENT_STATUS[freePaymentConfirmation.data.status.toLocaleUpperCase()].display}
                    bgColor={FREE_PAYMENT_STATUS[freePaymentConfirmation.data.status.toLocaleUpperCase()].bgColor}
                    textColor={FREE_PAYMENT_STATUS[freePaymentConfirmation.data.status.toLocaleUpperCase()].textColor}
                  />
                </Flex>
              </Stack>
              <Flex direction="column" w="full" mt="4" height="70vh">
                <Stack spacing="3" overflowY="auto">
                  <Stack direction="column" spacing="1">
                    <Text fontWeight="medium" color="gray.700">
                      Kelas
                    </Text>
                    <Text color="gray.600">{freePaymentConfirmation.data.userContent.content.classBK.name}</Text>
                  </Stack>
                  <Stack direction="column" spacing="1">
                    <Text fontWeight="medium" color="gray.700">
                      Kelompok
                    </Text>
                    <Text color="gray.600" textTransform="capitalize">
                      {freePaymentConfirmation.data.userContent.content.type}
                    </Text>
                  </Stack>
                  <Stack direction="column" spacing="1">
                    <Text fontWeight="medium" color="gray.700">
                      Nama
                    </Text>
                    <Text color="gray.600">{freePaymentConfirmation.data.userContent.user.name}</Text>
                  </Stack>
                  <Stack direction="column" spacing="1">
                    <Text fontWeight="medium" color="gray.700">
                      No. Handphone
                    </Text>
                    <Text color="gray.600">{freePaymentConfirmation.data.userContent.user.phoneNumber}</Text>
                  </Stack>
                  <Stack direction="column" spacing="1">
                    <Text fontWeight="medium" color="gray.700">
                      Email
                    </Text>
                    <Text color="gray.600">{freePaymentConfirmation.data.userContent.user.email}</Text>
                  </Stack>
                  <Stack direction="column" spacing="1">
                    <Text fontWeight="medium" color="gray.700">
                      Share Story
                    </Text>
                    <Image src={freePaymentConfirmation.data.shareStoryImg.url} />
                  </Stack>
                </Stack>
              </Flex>
              <Stack direction="row" justify="flex-end" spacing="4" mt="6">
                {freePaymentConfirmation.data.status === FREE_PAYMENT_STATUS.PROCESSED.status && (
                  <Button
                    size="md"
                    px="10"
                    variant="outline"
                    onClick={() =>
                      updateStatusFreePayment(freePaymentConfirmation.data.id, FREE_PAYMENT_STATUS.UNAPPROVED.status)
                    }
                    isDisabled={freePaymentConfirmationModal.isLoading}
                  >
                    Tolak
                  </Button>
                )}
                {freePaymentConfirmation.data.status === FREE_PAYMENT_STATUS.PROCESSED.status && (
                  <Button
                    px="10"
                    onClick={() =>
                      updateStatusFreePayment(freePaymentConfirmation.data.id, FREE_PAYMENT_STATUS.APPROVED.status)
                    }
                    size="md"
                    isDisabled={freePaymentConfirmationModal.isLoading}
                  >
                    Terima
                  </Button>
                )}
              </Stack>
            </Flex>
          </Stack>
        )}
      </ModalContent>
    </Modal>
  )
}

function FreePayments() {
  const shows = [10, 20, 50]
  const [query, setQuery] = useState({
    page: 0,
    show: 10,
    tab: useQuery().get("tab") ? parseInt(useQuery().get("tab")) : 0,
    search: "",
  })
  const menuPayments = [{ display: "Semua", status: "" }, ...Object.values(FREE_PAYMENT_STATUS)]
  const [freePayments, setFreePayments] = useState({ data: [], error: null, isLoading: false })
  const [pagination, setPagination] = useState({ totalItems: 0, totalPages: 0, currentPage: 0 })
  const [freePaymentConfirmationModal, setFreePaymentConfirmationModal] = useState({
    isOpen: false,
    freePaymentId: "",
    loading: false,
  })

  useEffect(() => {
    const GetFreePayments = async () => {
      setFreePayments({ ...freePayments, isLoading: true })
      const result = await getFreePayments({ ...query, status: menuPayments[query.tab].status })
      if (result.success) {
        const { totalItems, totalPages, currentPage } = result.data
        setFreePayments({ ...freePayments, data: result.data.tasks, isLoading: false })
        setPagination({ totalItems, totalPages, currentPage })
      }
    }
    GetFreePayments()
    return () => {
      setFreePayments({ data: [], error: null, isLoading: false })
      setPagination({ totalItems: 0, totalPages: 0, currentPage: 0 })
    }
  }, [query])

  return (
    <Flex direction="column">
      <Text color="gray.750" fontSize="2xl" fontWeight="medium">
        Paket Gratis
      </Text>
      <Box bg="white" mt="4" px={[4, 5, 6]} py={8}>
        <Flex direction="column" minH="70vh">
          <Flex direction="column" mt={2}>
            <Tabs overflowX="auto">
              <TabList>
                {menuPayments.map((menu, index) => (
                  <Link to={`/transaction/free-payment?tab=${index}`} key={index}>
                    <Box
                      p={4}
                      borderBottom={
                        parseInt(useQuery().get("tab")) === index || (useQuery().get("tab") === null && index === 0)
                          ? "2px"
                          : "none"
                      }
                      borderBottomColor={
                        parseInt(useQuery().get("tab")) === index || (useQuery().get("tab") === null && index === 0)
                          ? "purple.450"
                          : ""
                      }
                      position="relative"
                      mb="-2px"
                      onClick={() => setQuery({ ...query, tab: index.toString() })}
                    >
                      <Text
                        color={useQuery().get("tab") === index ? "purple.450" : "gray.750"}
                        fontSize="sm"
                        fontWeight="medium"
                      >
                        {menu.display}
                      </Text>
                    </Box>
                  </Link>
                ))}
              </TabList>
            </Tabs>
            <Stack direction={["column", "row"]} justify="space-between" py={8}>
              <Stack direction="row" w={["full", "xs"]}>
                <FormControl>
                  <Select onChange={(e) => setQuery({ ...query, show: e.target.value })}>
                    {shows.map((show, index) => (
                      <option value={show} key={index}>
                        {show}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl w={["lg", "2xl"]}>
                  <InputGroup>
                    <Input type="date" />
                    <InputLeftElement>
                      <Icon as={CalendarIcon} color="gray.550" h="4" w="4" />
                    </InputLeftElement>
                  </InputGroup>
                </FormControl>
              </Stack>
              <FormControl width={["full", "xs"]}>
                <InputGroup>
                  <Input
                    type="text"
                    placeholder="Search"
                    onChange={(e) => {
                      if (e.target.value.length === 0 || e.target.value.length >= 3) {
                        return setQuery({ ...query, search: e.target.value })
                      }
                    }}
                  />
                  <InputRightElement>
                    <Button variant="ghost" _focus={{ boxShadow: "none" }}>
                      <Icon as={SearchIcon} color="gray.550" h="4" w="4" />
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
            </Stack>
            <Flex overflowX="auto" overflowY="hidden" direction="column">
              {freePayments.isLoading && (
                <Stack spacing={6}>
                  {[...Array(6)].map((e, i) => (
                    <Stack key={i}>
                      <Skeleton startColor="gray.100" endColor="gray.200" height="30px" />
                    </Stack>
                  ))}
                </Stack>
              )}
              {!freePayments.isLoading && (
                <Table variant="simple" size="md" mb={4}>
                  <Thead>
                    <Tr color="gray.100" borderTop="1px">
                      <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                        DATE
                      </Th>
                      <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                        NAMA
                      </Th>
                      <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                        EMAIl
                      </Th>
                      <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                        STATUS
                      </Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {freePayments.data.map((freePayment, index) => (
                      <Tr key={index} fontSize="sm" color="gray.550">
                        <Td>{utcToLocal(freePayment.createdAt, "DD MMMM YYYY HH:mm")}</Td>
                        <Td>{freePayment.userContent.user.name}</Td>
                        <Td>{freePayment.userContent.user.email}</Td>
                        <Td>
                          <PaymentBadge
                            display={FREE_PAYMENT_STATUS[freePayment.status.toUpperCase()].display}
                            bgColor={FREE_PAYMENT_STATUS[freePayment.status.toUpperCase()].bgColor}
                            textColor={FREE_PAYMENT_STATUS[freePayment.status.toUpperCase()].textColor}
                          />
                        </Td>
                        <Td>
                          {freePayment.status != "unpaid" && (
                            <Button
                              size="sm"
                              fontSize="xs"
                              variant="outline"
                              color="purple.450"
                              borderColor="purple.450"
                              px="4"
                              onClick={() =>
                                setFreePaymentConfirmationModal({
                                  isOpen: true,
                                  freePaymentId: freePayment.id,
                                })
                              }
                            >
                              Detail
                            </Button>
                          )}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                  <Tfoot></Tfoot>
                </Table>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Stack spacing="2" direction={["column", "row"]} justify="space-between" mt="4">
          <Text fontSize="sm" color="gray.550">
            Menampilkan 1 sampai {query.show} dari {pagination.totalItems} data
          </Text>
          {pagination.totalItems > query.show && (
            <Stack direction="row" spacing="1">
              {pagination.currentPage > 0 && (
                <Button variant="outline" onClick={() => setQuery({ ...query, page: query.page - 1 })}>
                  Sebelumnya
                </Button>
              )}
              {[...Array(pagination.totalPages)].map((e, i) => (
                <Button
                  variant={i === pagination.currentPage ? "solid" : "outline"}
                  onClick={() => setQuery({ ...query, page: i })}
                  key={i}
                >
                  {i + 1}
                </Button>
              ))}
              {pagination.currentPage < pagination.totalPages - 1 && (
                <Button variant="outline" onClick={() => setQuery({ ...query, page: query.page + 1 })}>
                  Berikutnya
                </Button>
              )}
            </Stack>
          )}
        </Stack>
      </Box>
      <FreePaymentConfirmation
        freePaymentConfirmationModal={freePaymentConfirmationModal}
        setFreePaymentConfirmationModal={setFreePaymentConfirmationModal}
      />
    </Flex>
  )
}

export default FreePayments
