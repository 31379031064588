import Axios from "../helpers/axios"

/**
 * login
 * @typedef {{email:string, password:string}} User
 * @param {User} data
 * @returns {object}
 */
export const login = async (data) => {
  try {
    const { data: response } = await Axios.post("/login/admin", { ...data })
    return response
  } catch (err) {
    return err.response.data
  }
}

/**
 * refreshToken
 * @param {{token:string}} data
 * @returns {object}
 */
export const refreshToken = async (data) => {
  try {
    const { data: response } = await Axios.post("/refresh-token", { ...data })
    return response
  } catch (err) {
    return err.response.data
  }
}

export const logout = async () => {
  try {
    const { data: response } = await Axios.get("/logout")
    return response
  } catch (err) {
    return err.response.data
  }
}
