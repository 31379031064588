import { Avatar, Box, Divider, Flex, HStack, Icon, Text, VStack, useDisclosure, Spacer, Stack } from "@chakra-ui/react"
import {
  ViewGridIcon,
  UserIcon,
  PhotographIcon,
  CogIcon,
  CubeIcon,
  LogoutIcon,
  PresentationChartLineIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CreditCardIcon,
  ClipboardListIcon,
  SwitchHorizontalIcon,
} from "@heroicons/react/outline"
import { useState } from "react"
import { useContext } from "react"
import { Link, useLocation } from "react-router-dom"
import { StateContext } from "../store"

const menus = [
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: ViewGridIcon,
    roles: ["superadmin", "admin"],
  },
  {
    name: "Member",
    path: "/member",
    icon: UserIcon,
    roles: ["superadmin", "admin"],
  },

  {
    name: "Kelas",
    path: "/class",
    icon: CubeIcon,
    roles: ["superadmin", "admin"],
  },
  {
    name: "Konten",
    path: "/content",
    icon: PresentationChartLineIcon,
    roles: ["superadmin", "admin", "author"],
  },
  {
    name: "Bank Soal",
    icon: ClipboardListIcon,
    roles: ["superadmin", "admin", "author"],
    submenus: [
      {
        name: "Statistik",
        path: "/question/statistics",
        defaultQuery: "",
      },
      {
        name: "Semua Soal",
        path: "/question",
        defaultQuery: "",
      },
    ],
  },
  {
    name: "Transaksi",
    icon: SwitchHorizontalIcon,
    roles: ["superadmin", "admin"],
    submenus: [
      {
        name: "Pembayaran Manual",
        path: "/transaction/manual-payment",
        defaultQuery: "",
      },
      {
        name: "Pembayaran Otomatis",
        path: "/transaction/automatic-payment",
        defaultQuery: "",
      },
      {
        name: "Paket Gratis",
        path: "/transaction/free-payment",
        defaultQuery: "",
      },
    ],
  },
  {
    name: "Keuangan",
    path: "/finance",
    icon: CreditCardIcon,
    roles: ["superadmin", "admin"],
  },
  {
    name: "Media",
    path: "/media",
    icon: PhotographIcon,
    roles: ["superadmin", "admin", "author"],
  },
  {
    name: "Pengaturan",
    icon: CogIcon,
    roles: ["superadmin", "admin"],
    submenus: [
      {
        name: "Fitur",
        path: "/setting/feature",
        defaultQuery: "?page=product",
      },
      {
        name: "User",
        path: "/setting/user",
        defaultQuery: "?page=admin",
      },
    ],
  },
  {
    name: "Logout",
    path: "/logout",
    roles: ["superadmin", "admin", "author", "menthor"],
    icon: LogoutIcon,
  },
]

function SidebarLink(props) {
  const { role } = props
  const { pathname } = useLocation()
  const [submenuState, setSubmenuState] = useState({ id: 0, isOpen: false })
  const onClose = () => {
    setSubmenuState({ id: 0, isOpen: false })
  }
  const onOpen = (id) => {
    onClose()
    setSubmenuState({ id, isOpen: true })
  }
  return (
    <Box w="full">
      <Stack direction="column" spacing="0.5">
        {menus.map(
          (menu, index) =>
            menu.roles.includes(role) && (
              <Flex direction="column" key={index}>
                {menu?.submenus ? (
                  <>
                    <HStack
                      px="4"
                      py="2"
                      fontWeight="medium"
                      _hover={{
                        background: "gray.50",
                      }}
                      onClick={() => onOpen(index)}
                      w="full"
                      bg="white"
                      cursor="pointer"
                      color="gray.750"
                      key={index}
                    >
                      <Icon as={menu.icon} mr="2" />
                      <Text fontSize="sm" fontWeight="medium">
                        {menu.name}
                      </Text>
                      <Spacer />
                      {submenuState.isOpen && submenuState.id === index ? (
                        <Icon as={ChevronUpIcon} />
                      ) : (
                        <Icon as={ChevronDownIcon} />
                      )}
                    </HStack>
                    {submenuState.isOpen && submenuState.id === index ? (
                      <Box>
                        {menu.submenus.map((submenu, index) => (
                          <Link to={`${submenu.path}${submenu.defaultQuery}`} key={index}>
                            <HStack
                              px="4"
                              py="2"
                              fontWeight="medium"
                              color={submenu.path === pathname ? "purple.450" : "gray.750"}
                              _hover={{
                                background: "gray.50",
                              }}
                            >
                              <Text ml="8" fontSize="sm" fontWeight="normal">
                                {submenu.name}
                              </Text>
                            </HStack>
                          </Link>
                        ))}
                      </Box>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <Link to={menu.path} key={index} onClick={onClose}>
                    <HStack
                      px="4"
                      py="2"
                      fontWeight="medium"
                      color={new RegExp(`${menu.path}*`).test(pathname) ? "purple.450" : "gray.750"}
                      _hover={{
                        background: "gray.50",
                      }}
                    >
                      <Icon as={menu.icon} mr="2" />
                      <Text fontSize="sm" fontWeight="medium">
                        {menu.name}
                      </Text>
                    </HStack>
                  </Link>
                )}
              </Flex>
            )
        )}
      </Stack>
    </Box>
  )
}

function Profile(props) {
  return (
    <Stack w="full" align="center" textAlign="center" px={4} py={2} mb="2" spacing={2}>
      <Avatar mt="6" name={props.name} size="lg" src={props.image} bgColor="gray.500" />
      <Text fontSize="sm" fontWeight="medium" textTransform="capitalize">
        {props.name}
      </Text>
      <Box w="full" bg="gray.50" py="1" borderRadius="md">
        <Text fontSize="xs" fontWeight="medium" color="gray.550">
          {props.role}
        </Text>
      </Box>
    </Stack>
  )
}

export default function Sidebar(props) {
  const [state] = useContext(StateContext)
  return (
    <Flex pos="fixed" as="aside" w="16%" h="full" bg={"white"} overflow="auto" {...props}>
      <VStack w="full">
        <Profile
          name={state.currentUser.name}
          role={state.currentUser.role}
          image={state.currentUser.image === null ? "" : state.currentUser.image?.url}
        />
        <Divider />
        <SidebarLink role={state.currentUser.role} />
      </VStack>
    </Flex>
  )
}
