import Axios from "../helpers/axios"

export const getContents = async (query = {}) => {
  try {
    const { admin = true, show = 10, page = 0, category = "", active = "", search = "", sortBy = "DESC" } = query
    const { data: response } = await Axios.get(
      `/content?admin=${admin}&show=${show}&page=${page}&category=${category}&active=${active}&sortBy=${sortBy}&search=${search}`
    )
    return response
  } catch (err) {
    return err.response.data
  }
}

export const storeContent = async (data) => {
  try {
    const { classId, materialId, type, time, start, end } = data
    const { data: response } = await Axios.post("/content", { classId, materialId, type, time, start, end })
    return response
  } catch (err) {
    return err.response.data
  }
}

export const getContentByContentId = async (id) => {
  try {
    const { data: response } = await Axios.get(`/content/${id}?admin=true`)
    return response
  } catch (err) {
    return err.response.data
  }
}

export const updateContentByContentId = async (data) => {
  try {
    const { id, time, start, end } = data
    const { data: response } = await Axios.put(`/content/${id}`, { time, start, end })
    return response
  } catch (err) {
    return err.response.data
  }
}

export const deleteContentByContentId = async (id) => {
  try {
    const { data: response } = await Axios.delete(`/content/${id}`)
    return response
  } catch (err) {
    return err.response.data
  }
}

export const storeContentQuestion = async (data) => {
  try {
    const { id, questions } = data
    const { data: response } = await Axios.post(`content/${id}/content-question`, { questions })
    return response
  } catch (err) {
    return err.response.data
  }
}
