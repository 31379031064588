import Axios from "../helpers/axios"

export const getFacilities = async (query = {}) => {
  try {
    const { show = 10, page = 0 } = query
    const { data: response } = await Axios.get(`/facility?show=${show}&page=${page}`)
    return response
  } catch (err) {
    return err.response.data
  }
}

export const storeFacility = async (data) => {
  try {
    const { name } = data
    const { data: response } = await Axios.post("/facility", {
      name,
    })
    return response
  } catch (err) {
    return err.response.data
  }
}

export const getFacilityByFacilityId = async (id) => {
  try {
    const { data: response } = await Axios.get(`/facility/${id}`)
    return response
  } catch (err) {
    return err.response.data
  }
}

export const updateFacilityByFacilityId = async (data) => {
  try {
    const { id, name } = data
    const { data: response } = await Axios.put(`/facility/${id}`, { name })
    return response
  } catch (err) {
    return err.response.data
  }
}

export const deleteFacilityByFacilityId = async (id) => {
  try {
    const { data: response } = await Axios.delete(`/facility/${id}`)
    return response
  } catch (err) {
    return err.response.data
  }
}
