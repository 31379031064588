import Axios from "../helpers/axios"

export const getProvinces = async () => {
  try {
    const { data: response } = await Axios.get("/region/province")
    return response
  } catch (err) {
    return err.response.data
  }
}
