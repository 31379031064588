import { Flex, Text, Box, Tabs, TabList } from "@chakra-ui/react"
import { Link, useLocation } from "react-router-dom"
import { Product, Category, SubCategory, Facility, Package, Material, Study, StudyType, Bank } from "./components"

const featureMenus = [
  {
    menu: "Produk",
    page: "product",
    component: <Product />,
  },
  {
    menu: "Kategori",
    page: "category",
    component: <Category />,
  },
  {
    menu: "Sub Kategori",
    page: "sub-category",
    component: <SubCategory />,
  },
  {
    menu: "Fasilitas",
    page: "facility",
    component: <Facility />,
  },
  {
    menu: "Paket",
    page: "package",
    component: <Package />,
  },
  {
    menu: "Materi",
    page: "material",
    component: <Material />,
  },
  {
    menu: "Pelajaran",
    page: "study",
    component: <Study />,
  },
  {
    menu: "Jenis Pelajaran",
    page: "study-type",
    component: <StudyType />,
  },
  {
    menu: "Bank",
    page: "bank",
    component: <Bank />,
  },
]

const useQuery = () => new URLSearchParams(useLocation().search)

export default function Feature() {
  return (
    <Flex direction="column">
      <Text color="gray.750" fontSize="2xl" fontWeight="medium">
        Feature
      </Text>
      <Box bg="white" mt="4" px={[4, 5, 6]} py={8}>
        <Flex direction="column">
          <Tabs overflowX="auto">
            <TabList>
              {featureMenus.map((featureMenu, index) => (
                <Box
                  p={4}
                  borderBottom={useQuery().get("page") === featureMenu.page ? "2px" : "none"}
                  borderBottomColor={useQuery().get("page") === featureMenu.page ? "purple.450" : ""}
                  key={index}
                  position="relative"
                  mb="-2px"
                >
                  <Link to={`/setting/feature?page=${featureMenu.page}`}>
                    <Text
                      color={useQuery().get("page") === featureMenu.page ? "purple.450" : "gray.750"}
                      fontSize="sm"
                      fontWeight="medium"
                    >
                      {featureMenu.menu}
                    </Text>
                  </Link>
                </Box>
              ))}
            </TabList>
          </Tabs>
          {featureMenus.map((featureMenu, index) => (
            <Box key={index}>{featureMenu.page === useQuery().get("page") && featureMenu.component}</Box>
          ))}
        </Flex>
      </Box>
    </Flex>
  )
}
