import Axios from "../helpers/axios"

export const getFreePayments = async (query = {}) => {
  try {
    const { status = "", show = 10, page = 0, search = "", sortBy = "DESC" } = query
    const { data: response } = await Axios.get(
      `/free-payment?status=${status}&show=${show}&page=${page}&email=${search}&sortBy=${sortBy}`
    )
    return response
  } catch (err) {
    return err.response.data
  }
}

export const getFreePaymentByFreePaymentId = async (id) => {
  try {
    const { data: response } = await Axios.get(`/free-payment/${id}`)
    return response
  } catch (err) {
    return err.response.data
  }
}

export const updateStatusFreePaymentByFreePaymentId = async (data) => {
  try {
    const { id, status } = data
    const { data: response } = await Axios.put(`/free-payment/${id}/status`, { status })
    return response
  } catch (err) {
    return err.response.data
  }
}
