import { extendTheme } from "@chakra-ui/react"

const Button = {
  variants: {
    solid: {
      fontSize: "sm",
      bg: "purple.450",
      color: "white",
      _hover: { bg: "purple.450", _disabled: { bg: "purple.450" } },
      _focus: { boxShadow: "none", bg: "purple.450" },
      _active: { bg: "purple.450" },
    },
    outline: {
      fontSize: "sm",
      bg: "white",
      color: "gray.550",
      _focus: { boxShadow: "none" },
    },
  },
  defaultProps: {
    variant: "solid",
  },
}

const Input = {
  parts: ["field"],
  variants: {
    outline: {
      field: {
        fontSize: "sm",
        color: "gray.550",
        _focus: {
          borderColor: "purple.450",
          boxShadow: "0 0 0 0.5px",
        },
        _invalid: {
          borderColor: "red.500",
          boxShadow: "0 0 0 0.5px",
        },
      },
    },
  },
  defaultProps: {
    variant: "outline",
  },
}

const Switch = {
  parts: ["track"],
  baseStyle: {
    track: {
      _checked: {
        bg: "purple.450",
      },
      _focus: {
        boxShadow: "none",
      },
    },
  },
}

const Select = {
  parts: ["field"],
  variants: {
    outline: {
      field: {
        fontSize: "sm",
        color: "gray.550",
        _focus: {
          borderColor: "purple.450",
          boxShadow: "0 0 0 0.5px",
        },
        _invalid: {
          borderColor: "red.500",
          boxShadow: "0 0 0 0.5px",
        },
      },
    },
  },
  defaultProps: {
    variant: "outline",
  },
}

const Color = {
  colors: {
    purple: {
      450: "#93328E",
    },
    orange: {
      550: "#FE6845",
    },
    gray: {
      50: "#F3F4F5",
      250: "#DADCE0",
      550: "#5F6368",
      750: "#202124",
    },
  },
}

export default extendTheme({
  components: {
    Button,
    Input,
    Select,
    Switch,
  },
  ...Color,
})
