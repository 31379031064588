import Axios from "../helpers/axios"
import { parseToSlug } from "../helpers/string"

export const getProducts = async (query = {}) => {
  try {
    const { admin = true, show = 10, page = 0 } = query
    const { data: response } = await Axios.get(`/product?admin=${admin}&show=${show}&page=${page}`)
    return response
  } catch (err) {
    return err.response.data
  }
}

export const storeProduct = async (data) => {
  try {
    const { name } = data
    const { data: response } = await Axios.post("/product", { name, slug: parseToSlug(name) })
    return response
  } catch (err) {
    return err.response.data
  }
}

export const getProductByProductId = async (id) => {
  try {
    const { data: response } = await Axios.get(`/product/${id}`)
    return response
  } catch (err) {
    return err.response.data
  }
}

export const updateProductByProductId = async (data) => {
  try {
    const { id, name } = data
    const { data: response } = await Axios.put(`/product/${id}`, { name })
    return response
  } catch (err) {
    return err.response.data
  }
}

export const deleteProductByProductId = async (id) => {
  try {
    const { data: response } = await Axios.delete(`/product/${id}`)
    return response
  } catch (err) {
    return err.response.data
  }
}
