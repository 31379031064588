import { useRef } from "react"
import { IconButton, Drawer, DrawerBody, DrawerContent, DrawerOverlay, useDisclosure, Icon } from "@chakra-ui/react"
import { Sidebar } from "./"
import { MenuIcon } from "@heroicons/react/outline"

export default function MobileNavbar() {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const btnRef = useRef()
  return (
    <>
      <IconButton
        aria-label="Navigation Menu"
        variant="ghost"
        display={["flex", null, "none"]}
        onClick={onToggle}
        ref={btnRef}
        _focus={{ boxShadow: "none" }}
      >
        <Icon as={MenuIcon} h="6" w="6" color="gray.600" />
      </IconButton>
      <Drawer size="xs" isOpen={isOpen} onClose={onClose} finalFocusRef={btnRef} placement="left">
        <DrawerOverlay zIndex="overlay" />
        <DrawerContent zIndex="drawer">
          <DrawerBody p={0}>
            <Sidebar w="full" />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}
