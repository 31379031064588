export const PAYMENT_STATUS = {
  UNPAID: {
    display: "Belum Dibayar",
    status: "unpaid",
    textColor: "gray.500",
    bgColor: "gray.100",
  },
  PROCESSED: {
    display: "Menunggu",
    status: "processed",
    textColor: "orange.500",
    bgColor: "orange.100",
  },
  SUCCESS: {
    display: "Sukses",
    status: "success",
    textColor: "green.500",
    bgColor: "green.100",
  },
  REFUND: {
    display: "Refund",
    status: "refund",
    textColor: "purple.500",
    bgColor: "purple.100",
  },
  EXPIRED: {
    display: "Kadaluarsa",
    status: "expired",
    textColor: "purple.500",
    bgColor: "purple.100",
  },
  FAILED: {
    display: "Gagal",
    status: "failed",
    textColor: "red.500",
    bgColor: "red.100",
  },
}

export const FREE_PAYMENT_STATUS = {
  PROCESSED: {
    display: "Menunggu",
    status: "processed",
    textColor: "orange.500",
    bgColor: "orange.100",
  },
  APPROVED: {
    display: "Terkonfirmasi",
    status: "approved",
    textColor: "green.500",
    bgColor: "green.100",
  },
  UNAPPROVED: {
    display: "Gagal",
    status: "unapproved",
    textColor: "red.500",
    bgColor: "red.100",
  },
}

export const PAYMENT_VERIFICATION_METHOD = {
  MANUAL: "manual",
  AUTOMATIC: "automatic",
}
