import Axios from "../helpers/axios"

export const getPackages = async (query = {}) => {
  try {
    const { show = 10, page = 0 } = query
    const { data: response } = await Axios.get(`/package?show=${show}&page=${page}`)
    return response
  } catch (err) {
    return err.response.data
  }
}

export const storePackage = async (data) => {
  try {
    const { name, isFree } = data
    const { data: response } = await Axios.post("/package", { name, isFree })
    return response
  } catch (err) {
    return err.response.data
  }
}

export const storeFaciltiesByPackageId = async (data) => {
  try {
    const { id, facilities } = data
    const { data: response } = await Axios.post(`/package/${id}/add-facility`, { facilities })
    return response
  } catch (err) {
    return err.response.data
  }
}

export const getPackageByPackageId = async (id) => {
  try {
    const { data: response } = await Axios.get(`/package/${id}`)
    return response
  } catch (err) {
    return err.response.data
  }
}

export const updatePackageByPackageId = async (data) => {
  try {
    const { id, name, isFree } = data
    const { data: response } = await Axios.put(`/package/${id}`, { name, isFree })
    return response
  } catch (err) {
    return err.response.data
  }
}

export const deletePackageByPackageId = async (id) => {
  try {
    const { data: response } = await Axios.delete(`/package/${id}`)
    return response
  } catch (err) {
    return err.response.data
  }
}
