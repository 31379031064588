import Axios from "../helpers/axios"

export const getUsers = async (query = {}) => {
  try {
    const { show = 10, page = 0, role = "", sortBy = "DESC", search = "", startDate = "", endDate = "" } = query
    const { data: response } = await Axios.get(
      `/user?show=${show}&page=${page}&role=${role}&search=${search}&startDate=${startDate}&endDate=${endDate}&sortBy=${sortBy}`
    )
    return response
  } catch (err) {
    return err.response.data
  }
}

export const getUsersWithoutPagination = async (query = {}) => {
  try {
    const { role = "", sortBy = "DESC", search = "", startDate = "", endDate = "" } = query
    const { data: response } = await Axios.get(
      `/user/export?search=${search}&startDate=${startDate}&endDate=${endDate}&role=${role}&sortBy=${sortBy}`
    )
    return response
  } catch (err) {
    return err.response.data
  }
}

export const storeUser = async (data) => {
  try {
    const { name, email, phoneNumber, password, role, status } = data
    const { data: response } = await Axios.post("/user", { name, email, phoneNumber, password, role, status })
    return response
  } catch (err) {
    return err.response.data
  }
}

export const getUserByUserId = async (id) => {
  try {
    const { data: response } = await Axios.get(`/user/${id}`)
    return response
  } catch (err) {
    return err.response.data
  }
}
export const getUserProfileByUserId = async (id) => {
  try {
    const { data: response } = await Axios.get(`/user/${id}/profile`)
    return response
  } catch (err) {
    return err.response.data
  }
}

export const updateUserByUserId = async (data) => {
  try {
    const { id, name, email, phoneNumber, role, status } = data
    const { data: response } = await Axios.put(`/user/${id}`, { name, email, phoneNumber, role, status })
    return response
  } catch (err) {
    return err.response.data
  }
}

export const deleteUserByUserId = async (id) => {
  try {
    const { data: response } = await Axios.delete(`/user/${id}`)
    return response
  } catch (err) {
    return err.response.data
  }
}
