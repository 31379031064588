export function convertToRupiah(number) {
  if (number) {
    let rupiah = ""
    const numberReverse = number.toString().split("").reverse().join("")
    for (let i = 0; i < numberReverse.length; i++) {
      if (i % 3 === 0) {
        rupiah += numberReverse.substr(i, 3) + "."
      }
    }
    return (
      "Rp. " +
      rupiah
        .split("", rupiah.length - 1)
        .reverse()
        .join("")
    )
  }
  return "Rp. 0"
}

export function convertToNumber(rupiah) {
  return parseInt(rupiah.replace(/,.*|[^0-9]/g, ""), 10)
}
