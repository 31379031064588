import Axios from "../helpers/axios"
import { parseToSlug } from "../helpers/string"

/**
 * getCategories
 * @typedef {{show:number, page:number, admin:string}} Query
 * @param {Query} query
 * @returns {object}
 */
export const getCategories = async (query = {}) => {
  try {
    const { admin = true, show = 10, page = 0 } = query
    const { data: response } = await Axios.get(`/category?admin=${admin}&show=${show}&page=${page}`)
    return response
  } catch (err) {
    return err.response.data
  }
}

export const storeCategory = async (data) => {
  try {
    const { productId, name } = data
    const { data: response } = await Axios.post("/category", {
      productId,
      name,
      slug: parseToSlug(name),
    })
    return response
  } catch (err) {
    return err.response.data
  }
}

export const getCategoryByCategoryId = async (id) => {
  try {
    const { data: response } = await Axios.get(`/category/${id}`)
    return response
  } catch (err) {
    return err.response.data
  }
}

export const updateCategoryByCategoryId = async (data) => {
  try {
    const { id, name } = data
    const { data: response } = await Axios.put(`/category/${id}`, { name })
    return response
  } catch (err) {
    return err.response.data
  }
}

export const deleteCategoryByCategoryId = async (id) => {
  try {
    const { data: response } = await Axios.delete(`/category/${id}`)
    return response
  } catch (err) {
    return err.response.data
  }
}
