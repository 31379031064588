import { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { joiResolver } from "@hookform/resolvers/joi"
import Joi from "joi"
import {
  Flex,
  Button,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Text,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Icon,
  HStack,
  Skeleton,
  InputGroup,
  InputRightElement,
  Alert,
  AlertIcon,
  Box,
  Select,
} from "@chakra-ui/react"
import { PlusIcon, ChevronDownIcon, PlusCircleIcon } from "@heroicons/react/solid"
import { PencilIcon, TrashIcon, SearchIcon, DesktopComputerIcon } from "@heroicons/react/outline"
import {
  deleteMaterialByMaterialId,
  getMaterials,
  getMaterialByMaterialId,
  storeMaterial,
  updateMaterialByMaterialId,
  addStudyToMaterial,
} from "../../../../api/material"
import { MODAL_TYPE } from "../../../../constants/modal"
import { DeleteAlert } from "../../../../components/Alert"
import { deleteStudyTimeByStudyTimeId, getStudies, updateStudyTimeByStudyTimeId } from "../../../../api/study"
import { getStudyTypes } from "../../../../api/studyType"

function FormStudyModal(props) {
  const { formStudyModal, setFormStudyModal, changeState, setChangeState } = props
  const [studyTypes, setStudyTypes] = useState({ data: [], isLoading: false, error: null })
  const schema = Joi.object({
    studyTypeId: Joi.string().required(),
    time: Joi.number().required(),
  })
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ resolver: joiResolver(schema) })

  useState(() => {
    const GetStudyTypes = async () => {
      setStudyTypes({ ...studyTypes, isLoading: true })
      const result = await getStudyTypes()
      if (result.success) {
        setStudyTypes({ ...studyTypes, data: result.data.studyTypes, isLoading: false })
      }
    }
    GetStudyTypes()
    if (formStudyModal.type === MODAL_TYPE.UPDATE) {
      setValue("time", formStudyModal.study.time)
    }
    return () => {
      setStudyTypes({ data: [], isLoading: false, error: null })
    }
  }, [])

  const closeModal = () => {
    setFormStudyModal({ ...formStudyModal, isOpen: false, isLoading: false })
    setChangeState(!changeState)
  }

  const submitStoreStudy = async (data) => {
    setFormStudyModal({ ...formStudyModal, isLoading: true })
    const result = await addStudyToMaterial({
      ...data,
      materialId: formStudyModal.materialId,
      studyId: formStudyModal.study.id,
    })
    if (result.success) {
      setFormStudyModal({ ...formStudyModal, isLoading: false })
      closeModal()
    }
  }
  const submitUpdateStudy = async (data) => {
    console.log("As")
    setFormStudyModal({ ...formStudyModal, isLoading: true })
    const result = await updateStudyTimeByStudyTimeId({ ...data, studyTimeId: formStudyModal.studyTimeId })
    if (result.success) {
      setFormStudyModal({ ...formStudyModal, isLoading: false })
      closeModal()
    }
  }

  return (
    <Modal isOpen={formStudyModal.isOpen} onClose={closeModal} size="sm">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <Flex direction="column" p={["4", "6"]}>
          <Stack direction="row">
            <Text color="gray.700" fontSize="lg" fontWeight="semibold">
              {formStudyModal.type === MODAL_TYPE.STORE ? "Tambah Materi Pelajaran" : "Edit Materi Pelajaran"}
            </Text>
          </Stack>
          <form
            onSubmit={handleSubmit(formStudyModal.type === MODAL_TYPE.STORE ? submitStoreStudy : submitUpdateStudy)}
          >
            <Stack spacing="3" mt="4">
              <Stack>
                <FormControl>
                  <FormLabel fontSize="sm" color="gray.550">
                    Pelajaran
                  </FormLabel>
                  <Input type="text" isDisabled={true} value={formStudyModal.study.name} />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="sm" color="gray.550">
                    Jenis Pelajaran
                  </FormLabel>
                  {studyTypes.isLoading && (
                    <Stack>
                      <Skeleton startColor="gray.100" endColor="gray.200" height="38px" />
                    </Stack>
                  )}
                  {!studyTypes.isLoading && (
                    <Select
                      placeholder="Pilih Jenis Pelajaran"
                      {...register("studyTypeId")}
                      isInvalid={errors.studyTypeId}
                      defaultValue={formStudyModal.type === MODAL_TYPE.UPDATE ? formStudyModal.studyTipeId : ""}
                    >
                      {studyTypes.data.map((studyType) => (
                        <option key={studyType.id} value={studyType.id}>
                          {studyType.name}
                        </option>
                      ))}
                    </Select>
                  )}
                  {errors.studyTypeId && (
                    <Text fontSize="sm" color="red.500">
                      {errors.studyTypeId.message}
                    </Text>
                  )}
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="sm" color="gray.550">
                    Waktu (menit)
                  </FormLabel>
                  <Input type="text" w="40%" {...register("time")} isInvalid={errors.time} />
                  {errors.time && (
                    <Text fontSize="sm" color="red.500">
                      {errors.time.message}
                    </Text>
                  )}
                </FormControl>
              </Stack>
            </Stack>
          </form>
          <Flex justify="flex-end" mt="6">
            <Button variant="outline" mr="3" onClick={closeModal}>
              Close
            </Button>
            <Button
              isLoading={formStudyModal.isLoading}
              isDisabled={formStudyModal.isLoading}
              onClick={handleSubmit(formStudyModal.type === MODAL_TYPE.STORE ? submitStoreStudy : submitUpdateStudy)}
            >
              Submit
            </Button>
          </Flex>
        </Flex>
      </ModalContent>
    </Modal>
  )
}

function StudyModal(props) {
  const { studyModal, setStudyModal } = props
  const closeModal = () => {
    setStudyModal({ ...studyModal, isOpen: false, materialId: "" })
  }
  const initMaterial = {
    id: "",
    name: "",
    studyTypes: [],
  }

  const [formStudyModal, setFormStudyModal] = useState({
    isOpen: false,
    type: MODAL_TYPE.STORE,
    materialId: studyModal.materialId,
    studyTimeId: "",
    study: {
      id: "",
      name: "",
    },
    isLoading: false,
    error: null,
  })
  const [deleteModal, setDeleteModal] = useState({ isOpen: false, studyTimeId: "", error: null, isLoading: false })
  const [changeState, setChangeState] = useState(false)
  const [material, setMaterial] = useState({ data: { ...initMaterial }, isLoading: false, erro: null })
  const [studies, setStudies] = useState({ data: [], isLoading: false, erro: null })

  const submitDeleteStudy = async (id) => {
    setDeleteModal({ ...deleteModal, isLoading: true })
    const result = await deleteStudyTimeByStudyTimeId(id)
    if (result.success) {
      setDeleteModal({ ...deleteModal, isLoading: false, isOpen: false })
      setChangeState(!changeState)
    }
  }

  useEffect(() => {
    const GetStudies = async () => {
      setStudies({ ...studies, data: [], isLoading: true })
      const result = await getStudies({ show: 50 })
      if (result.success && !material.isLoading) {
        setStudies({
          ...studies,
          data: result.data.studies,
          isLoading: false,
        })
      }
    }

    const GetMaterial = async () => {
      setMaterial({ ...material, isLoading: true })
      const result = await getMaterialByMaterialId(studyModal.materialId)
      if (result.success) {
        const { id, name, studyTimes } = result.data
        const customMaterial = {
          id,
          name,
          studyTypes: studyTimes.reduce((acc, currentValue) => {
            const indexOfStudy = acc.findIndex((item) => item.name === currentValue.studyType.name)
            const study = {
              id: currentValue.study.id,
              name: currentValue.study.name,
              time: currentValue.time,
              studyTimeId: currentValue.id,
            }
            if (indexOfStudy < 0) {
              acc = [
                ...acc,
                {
                  id: currentValue.studyType.id,
                  name: currentValue.studyType.name,
                  studies: [study],
                },
              ]
            } else {
              acc[indexOfStudy].studies.push(study)
            }
            return acc
          }, []),
        }
        setMaterial({ ...material, data: customMaterial, isLoading: false })
      }
    }
    GetMaterial()
    GetStudies()
  }, [changeState])

  return (
    <Flex>
      <Modal isOpen={studyModal.isOpen} onClose={closeModal} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <Flex direction="column" p={["4", "6"]} minH="80vh">
            <Stack direction="row">
              <Text color="gray.700" fontSize="lg" fontWeight="semibold">
                Atur Pelajaran
              </Text>
            </Stack>
            <Stack mt="6" direction={["column-reverse", "column-reverse", "row"]} spacing="6" w="full">
              <Box w={["full", "full", "50%"]} boxShadow="md" p="4">
                <Flex direction="column" w="full">
                  <Text color="gray.600" fontSize="md" fontWeight="semibold">
                    Daftar Pelajaran {!material.isLoading && material.data.name}
                  </Text>
                  <Stack direction="column" mt="4" spacing="4" minH="50vh">
                    {material.isLoading &&
                      [...Array(2)].map((e, i) => (
                        <Stack spacing="2" key={i}>
                          <Skeleton w="8rem" startColor="gray.100" endColor="gray.200" height="20px" />
                          <Stack spacing="2">
                            {[...Array(3)].map((e, i) => (
                              <Stack key={i}>
                                <Skeleton startColor="gray.100" endColor="gray.200" height="35px" />
                              </Stack>
                            ))}
                          </Stack>
                        </Stack>
                      ))}
                    {!material.isLoading &&
                      material.data.studyTypes.map((studyType) => (
                        <Stack key={studyType.id} direction="column" spacing="1">
                          <Text fontSize="xs" fontWeight="semibold" color="gray.600">
                            {studyType.name}
                          </Text>
                          <Stack direction="column" spacing="2">
                            {studyType.studies.map((study) => (
                              <Box key={study.id} borderRadius="md" borderWidth="1px" py="1" px="4" w="full">
                                <Stack direction="row" w="full" justify="space-between" align="center">
                                  <Stack direction={["column", "row"]} spacing="0" justify="space-between" w={"70%"}>
                                    <Text fontSize="md" color="gray.600">
                                      {study.name}
                                    </Text>
                                    <Text fontSize="sm" color="gray.600">
                                      {study.time} Menit
                                    </Text>
                                  </Stack>
                                  <Stack direction="row" align="center" spacing="2">
                                    <Button
                                      variant="unstyled"
                                      size="sm"
                                      _focus={{ boxShadow: "none" }}
                                      onClick={() =>
                                        setFormStudyModal({
                                          ...formStudyModal,
                                          type: MODAL_TYPE.UPDATE,
                                          isOpen: true,
                                          studyTimeId: study.studyTimeId,
                                          studyTipeId: studyType.id,
                                          study: {
                                            id: study.id,
                                            name: study.name,
                                            time: study.time,
                                          },
                                        })
                                      }
                                    >
                                      <Icon as={PencilIcon} w="4" h="4" color="gray.600" />
                                    </Button>
                                    <Button
                                      variant="unstyled"
                                      size="sm"
                                      _focus={{ boxShadow: "none" }}
                                      onClick={() =>
                                        setDeleteModal({ ...deleteModal, studyTimeId: study.studyTimeId, isOpen: true })
                                      }
                                    >
                                      <Icon as={TrashIcon} w="4" h="4" color="gray.600" />
                                    </Button>
                                  </Stack>
                                </Stack>
                              </Box>
                            ))}
                          </Stack>
                        </Stack>
                      ))}
                  </Stack>
                </Flex>
              </Box>
              <Box w={["full", "full", "50%"]} boxShadow="md" p="4">
                <Flex direction="column" w="full">
                  <Text color="gray.600" fontSize="md" fontWeight="semibold">
                    Daftar Pelajaran
                  </Text>
                  <Stack direction="column" mt="4" spacing="2" height="55vh" overflowY="auto">
                    {studies.isLoading &&
                      [...Array(7)].map((e, i) => (
                        <Stack key={i}>
                          <Skeleton startColor="gray.100" endColor="gray.200" height="35px" />
                        </Stack>
                      ))}
                    {!studies.isLoading &&
                      studies.data.map((study) => (
                        <Box key={study.id} borderRadius="md" borderWidth="1px" py="1" px="4" w="full">
                          <Stack direction="row" w="full" justify="space-between">
                            <Stack direction="row" justify="space-between" w="full" align="center">
                              <Text fontSize="md" color="gray.600">
                                {study.name}
                              </Text>
                              <Button
                                variant="unstyled"
                                size="sm"
                                _focus={{ boxShadow: "none" }}
                                onClick={() =>
                                  setFormStudyModal({
                                    ...formStudyModal,
                                    type: MODAL_TYPE.STORE,
                                    isOpen: true,
                                    study: {
                                      id: study.id,
                                      name: study.name,
                                    },
                                  })
                                }
                              >
                                <Icon as={PlusCircleIcon} w="6" h="6" color="green.500" />
                              </Button>
                            </Stack>
                          </Stack>
                        </Box>
                      ))}
                  </Stack>
                </Flex>
              </Box>
            </Stack>
            <Flex justify="flex-end" mt="6">
              <Button variant="outline" onClick={closeModal}>
                Close
              </Button>
            </Flex>
          </Flex>
        </ModalContent>
      </Modal>
      {formStudyModal.isOpen && (
        <FormStudyModal
          formStudyModal={formStudyModal}
          setFormStudyModal={setFormStudyModal}
          setChangeState={setChangeState}
          changeState={changeState}
        />
      )}
      {deleteModal.isOpen && (
        <DeleteAlert
          isOpen={deleteModal.isOpen}
          title="Pelajaran"
          isLoading={deleteModal.isLoading}
          action={() => submitDeleteStudy(deleteModal.studyTimeId)}
          setOpen={setDeleteModal}
        />
      )}
    </Flex>
  )
}

export default function Material() {
  const [changeState, setChangeState] = useState(false)
  const [materials, setMaterials] = useState({ data: [], error: null, isLoading: false })
  const [material, setMaterial] = useState({
    isLoading: false,
    error: null,
  })
  const [formModal, setFormModal] = useState({
    type: MODAL_TYPE.STORE,
    isOpen: false,
    isLoading: false,
    error: null,
  })
  const [studyModal, setStudyModal] = useState({
    isOpen: false,
    error: null,
    isLoading: false,
    materialId: "",
  })
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    materialId: "",
    error: null,
    isLoading: false,
  })
  const schema = Joi.object({
    id: Joi.string().allow(""),
    name: Joi.string().required(),
  })
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ resolver: joiResolver(schema) })

  useEffect(() => {
    const GetMaterials = async () => {
      setMaterials({ ...materials, isLoading: true })
      const result = await getMaterials()
      if (result.success === true) {
        setMaterials({ ...materials, data: result.data.materials, isLoading: false })
      } else {
        setMaterials({ ...materials, error: result.error.message, isLoading: false })
      }
    }
    GetMaterials()
    return () => {
      setMaterials({ data: [], error: null, isLoading: false })
    }
  }, [changeState])

  const submitStoreMaterial = async (data) => {
    setFormModal({ ...formModal, isLoading: true })
    const result = await storeMaterial(data)
    if (result.success === true) {
      setFormModal({ ...formModal, isLoading: false })
      closeModal()
      setChangeState(!changeState)
    } else {
      setFormModal({ ...formModal, isLoading: false, error: result.error.message })
    }
  }
  const submitUpdateMaterial = async (data) => {
    setFormModal({ ...formModal, isLoading: true })
    const result = await updateMaterialByMaterialId(data)
    if (result.success === true) {
      setFormModal({ ...formModal, isLoading: false })
      closeModal()
      setChangeState(!changeState)
    } else {
      setFormModal({ ...formModal, isLoading: false, error: result.error.message })
    }
  }
  const submitDeleteMaterial = async (id) => {
    setDeleteModal({ ...deleteModal, isLoading: true })
    const result = await deleteMaterialByMaterialId(id)
    if (result.success === true) {
      setDeleteModal({ ...deleteModal, isOpen: false, isLoading: false })
      setChangeState(!changeState)
    } else {
      setDeleteModal({ ...deleteModal, error: "Kategori gagal dihapus", isLoading: false })
    }
  }
  const closeModal = () => {
    setValue("id", "")
    setValue("name", "")
    setFormModal({ ...formModal, isOpen: false, error: null })
  }

  const openModal = async (materialId = "", type = MODAL_TYPE.STORE) => {
    setFormModal({ ...formModal, type, isOpen: true })
    if (type === MODAL_TYPE.UPDATE) {
      setMaterial({ ...material, isLoading: true })
      const result = await getMaterialByMaterialId(materialId)
      if (result.success) {
        const { id, name } = result.data
        setValue("id", id)
        setValue("name", name)
        setMaterial({ ...material, isLoading: false })
      }
    }
  }
  const openDeleteModal = (materialId) => {
    setDeleteModal({ ...deleteModal, materialId, isOpen: true })
  }

  return (
    <Flex direction="column" minH="70vh">
      <Flex py="8" justify="space-between" spacing={16} direction={["column", "row"]}>
        <FormControl width={["full", "xs"]}>
          <InputGroup>
            <Input type="text" placeholder="Search" />
            <InputRightElement>
              <Button variant="ghost" _focus={{ boxShadow: "none" }}>
                <Icon as={SearchIcon} color="gray.550" h="4" w="4" />
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <Button onClick={() => openModal()} mt={["2", "0"]}>
          Tambah Materi
          <Icon as={PlusIcon} h="5" w="5" ml="2" />
        </Button>
        <Modal isCentered size="sm" blockScrollOnMount={true} isOpen={formModal.isOpen} onClose={closeModal}>
          <ModalOverlay />
          <ModalContent p="2">
            <ModalHeader>
              <Text color="gray.750">{formModal.type === MODAL_TYPE.STORE ? "Tambah" : "Edit"} Materi</Text>
            </ModalHeader>
            <ModalCloseButton _focus={{ boxShadow: "none" }} />
            <Box px={6}>
              {formModal.error && (
                <Alert size="sm" status="error">
                  <AlertIcon />
                  Materi gagal di{formModal.type === MODAL_TYPE.STORE ? "tambahkan" : "edit"}
                </Alert>
              )}
            </Box>
            <ModalBody my="3">
              <form
                onSubmit={handleSubmit(
                  formModal.type === MODAL_TYPE.STORE ? submitStoreMaterial : submitUpdateMaterial
                )}
              >
                <Stack spacing="3">
                  {material.isLoading && (
                    <Stack>
                      <Skeleton startColor="gray.100" endColor="gray.200" height="40px" />
                    </Stack>
                  )}
                  {!material.isLoading && (
                    <FormControl>
                      <FormLabel fontSize="sm" color="gray.550">
                        Nama
                      </FormLabel>
                      <Input type="text" {...register("name")} isInvalid={errors.name} />
                      <Text fontSize="sm" color="red.500">
                        {errors.name?.message}
                      </Text>
                    </FormControl>
                  )}
                </Stack>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button mr={3} onClick={closeModal} variant="outline" isDisabled={formModal.isLoading}>
                Batal
              </Button>
              <Button
                onClick={handleSubmit(formModal.type === MODAL_TYPE.STORE ? submitStoreMaterial : submitUpdateMaterial)}
                isLoading={formModal.isLoading}
                type="submit"
              >
                Simpan
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
      <Flex overflowX="auto" overflowY="hidden" direction="column">
        {materials.isLoading ? (
          <Stack spacing={6}>
            {[...Array(6)].map((e, i) => (
              <Stack key={i}>
                <Skeleton startColor="gray.100" endColor="gray.200" height="30px" />
              </Stack>
            ))}
          </Stack>
        ) : (
          <Table variant="simple" size="md" mb={4}>
            <Thead>
              <Tr color="gray.100" borderTop="1px">
                <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                  NAMA
                </Th>
                <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                  DIBUAT OLEH
                </Th>
                <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                  PELAJARAN
                </Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {materials.data.map((material) => (
                <Tr key={material.id} fontSize="sm" color="gray.550">
                  <Td fontWeight="semibold">{material.name}</Td>
                  <Td>Super Admin</Td>
                  <Td>{material.totalStudy}</Td>
                  <Td>
                    <Menu>
                      <MenuButton
                        px={4}
                        py={2}
                        transition="all 0.2s"
                        borderRadius="md"
                        borderWidth="1px"
                        _focus={{ boxShadow: "none" }}
                        fontWeight="semibold"
                      >
                        Atur
                        <Icon ml="2" as={ChevronDownIcon} h="5" w="5" />
                      </MenuButton>
                      <MenuList>
                        <MenuItem
                          onClick={() => setStudyModal({ ...studyModal, isOpen: true, materialId: material.id })}
                        >
                          <HStack w="full">
                            <Icon as={DesktopComputerIcon} h="4" w="4" />
                            <Text>Atur Pelajaran</Text>
                          </HStack>
                        </MenuItem>
                        <MenuItem onClick={() => openModal(material.id, MODAL_TYPE.UPDATE)}>
                          <HStack w="full">
                            <Icon as={PencilIcon} h="4" w="4" />
                            <Text>Edit</Text>
                          </HStack>
                        </MenuItem>
                        <MenuItem onClick={() => openDeleteModal(material.id)}>
                          <HStack w="full">
                            <Icon as={TrashIcon} h="4" w="4" />
                            <Text>Hapus</Text>
                          </HStack>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              ))}
            </Tbody>
            <Tfoot></Tfoot>
          </Table>
        )}
      </Flex>
      {deleteModal.isOpen && (
        <DeleteAlert
          isOpen={deleteModal.isOpen}
          title="Materi"
          isLoading={deleteModal.isLoading}
          action={() => submitDeleteMaterial(deleteModal.materialId)}
          setOpen={setDeleteModal}
        />
      )}
      {studyModal.isOpen && <StudyModal studyModal={studyModal} setStudyModal={setStudyModal} />}
    </Flex>
  )
}
