export const CONTENT_TYPE = {
  saintek: {
    display: "Saintek",
  },
  soshum: {
    display: "Soshum",
  },
  general: {
    display: "Umum",
  },
}
