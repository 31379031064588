import {
  Flex,
  Text,
  Box,
  Stack,
  Button,
  Image,
  Wrap,
  WrapItem,
  Center,
  Spinner,
  FormControl,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Alert,
  AlertIcon,
  FormLabel,
  Input,
  InputGroup,
  useClipboard,
  Badge,
} from "@chakra-ui/react"
import { getImages, storeImage } from "../../api/image"
import { useParams } from "react-router-dom"
import { useEffect } from "react"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { joiResolver } from "@hookform/resolvers/joi"
import Joi from "joi"

function DetailImage(props) {
  const { detailModal, setDetailModal } = props
  const { hasCopied, onCopy } = useClipboard(detailModal.imageUrl)
  const closeModal = () => {
    setDetailModal({ ...detailModal, isOpen: false })
  }
  return (
    <Modal isCentered size="lg" blockScrollOnMount={true} isOpen={detailModal.isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent p="2">
        <ModalHeader>
          <Text color="gray.750">Detail Image</Text>
        </ModalHeader>
        <ModalCloseButton _focus={{ boxShadow: "none" }} />
        <ModalBody my="3">
          <Stack direction="column" spacing="8">
            <Image src={detailModal.imageUrl} />
            <Box position="relative" borderWidth="1px" borderRadius="lg" p="4">
              <Button
                position="absolute"
                right="2"
                top="2"
                onClick={onCopy}
                size="xs"
                _focus={{ boxShadow: "none" }}
                variant="unstyled"
              >
                <Badge px="3" borderRadius="lg" py="1" bgColor="purple.450" color="white">
                  {hasCopied ? "Copied" : "Copy"}
                </Badge>
              </Button>
              <Text fontSize="sm" color="gray.600">
                {detailModal.imageUrl}
              </Text>
            </Box>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={closeModal} variant="outline">
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

function FormModal(props) {
  const { formModal, setFormModal, folderImage, changeState, setChangeState } = props
  const [image, setImage] = useState({ isLoading: false, error: null })
  const schema = Joi.object({
    image: Joi.required(),
    folder: Joi.string().required(),
  })
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ resolver: joiResolver(schema), defaultValues: { folder: folderImage } })
  const onSubmit = async (data) => {
    setImage({ ...image, isLoading: true })
    const fileImage = data.image[0]
    console.log(fileImage)
    let formData = new FormData()
    formData.append("image", fileImage)
    formData.append("folder", data.folder)
    const result = await storeImage(formData)
    if (result.success) {
      closeModal()
      setChangeState(!changeState)
      setImage({ ...image, isLoading: false })
    } else {
      setImage({ error: "Gambar gagal diuplaod", isLoading: false })
    }
  }
  const closeModal = () => {
    setValue("image", null)
    setFormModal({ isOpen: !formModal.isOpen })
  }

  return (
    <Modal isCentered size="sm" blockScrollOnMount={true} isOpen={formModal.isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent p="2">
        <ModalHeader>
          <Text color="gray.750">Upload Image</Text>
        </ModalHeader>
        <ModalCloseButton _focus={{ boxShadow: "none" }} />
        <Box px={6}>
          {image.error && (
            <Alert size="sm" status="error">
              <AlertIcon />
              {image.error}
            </Alert>
          )}
        </Box>
        <ModalBody my="3">
          <form onSubmit={handleSubmit(onSubmit)}>
            {!image.isLoading && (
              <Stack spacing="3">
                <FormControl>
                  <FormLabel fontSize="sm" color="gray.550">
                    Pilih Gambar
                  </FormLabel>
                  <InputGroup>
                    <Input
                      type="file"
                      accept="image/*"
                      variant="unstyled"
                      size="sm"
                      color="gray.600"
                      {...register("image")}
                      isInvalid={errors.image}
                    />
                  </InputGroup>
                  <Text fontSize="sm" color="red.500">
                    {errors.image?.message}
                  </Text>
                </FormControl>
              </Stack>
            )}
          </form>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={closeModal} variant="outline" isDisabled={image.isLoading}>
            Batal
          </Button>
          <Button isLoading={image.isLoading} type="submit" onClick={handleSubmit(onSubmit)}>
            Simpan
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

function ListImage() {
  const { folder } = useParams()
  const shows = [18, 30, 50]
  const [query, setQuery] = useState({
    page: 0,
    show: 18,
  })
  const [changeState, setChangeState] = useState(false)
  const [images, setImages] = useState({ data: [], isLoading: false, error: null })
  const [pagination, setPagination] = useState({ totalItems: 0, totalPages: 0, currentPage: 0 })
  const [formModal, setFormModal] = useState({ isOpen: false, stateChanged: false })
  const [detailModal, setDetailModal] = useState({ isOpen: false, imageId: "", imageUrl: "" })

  useEffect(() => {
    const GetImages = async () => {
      setImages({ ...images, isLoading: true })
      const result = await getImages({ folder, ...query })
      if (result.success) {
        const { totalItems, totalPages, currentPage } = result.data
        setImages({ ...images, data: result.data.images, isLoading: false })
        setPagination({ totalItems, totalPages, currentPage })
      }
    }
    GetImages()
    return () => {
      setImages({ data: [], isLoading: false, error: null })
      setPagination({ totalItems: 0, totalPages: 0, currentPage: 0 })
    }
  }, [folder, query, changeState])

  return (
    <Flex direction="column">
      <Text color="gray.750" fontSize="2xl" fontWeight="medium">
        Media
      </Text>

      <Box bg="white" mt="4" px={[4, 5, 6]} py={8}>
        <Flex direction="column" minH="70vh">
          <Stack direction="row" justify="space-between">
            <Stack direction="row" spacing="4">
              <Text fontSize="xl" color="gray.750" textTransform="capitalize" fontWeight="medium">
                {folder}
              </Text>
              <FormControl>
                <Select onChange={(e) => setQuery({ ...query, show: e.target.value })}>
                  {shows.map((show, index) => (
                    <option value={show} key={index}>
                      {show}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Stack>

            <Button onClick={() => setFormModal({ isOpen: true })}>Upload</Button>
          </Stack>
          {images.isLoading && (
            <Center minH="60vh">
              <Spinner thickness="4px" speed="1s" emptyColor="gray.200" color="purple.450" size="lg" />
            </Center>
          )}
          {!images.isLoading && (
            <Wrap spacing={["2", "8", "12"]} mt="10">
              {images.data.map((image, index) => (
                <WrapItem key={index} alg="center">
                  <Box
                    w={["24", "36"]}
                    h={["24", "36"]}
                    as="button"
                    onClick={() => setDetailModal({ isOpen: true, imageId: image.id, imageUrl: image.url })}
                  >
                    <Image src={image.url} name={image.key} />
                  </Box>
                </WrapItem>
              ))}
            </Wrap>
          )}
        </Flex>
        <Stack spacing="2" direction={["column", "row"]} justify="space-between" mt="4">
          <Text fontSize="sm" color="gray.550">
            Menampilkan 1 sampai {query.show} dari {pagination.totalItems} data
          </Text>
          {pagination.totalItems > query.show && (
            <Stack direction="row" spacing="1">
              {pagination.currentPage > 0 && (
                <Button variant="outline" onClick={() => setQuery({ ...query, page: query.page - 1 })}>
                  Sebelumnya
                </Button>
              )}
              {[...Array(pagination.totalPages)].map((e, i) => (
                <Button
                  variant={i === pagination.currentPage ? "solid" : "outline"}
                  onClick={() => setQuery({ ...query, page: i })}
                  key={i}
                >
                  {i + 1}
                </Button>
              ))}
              {pagination.currentPage < pagination.totalPages - 1 && (
                <Button variant="outline" onClick={() => setQuery({ ...query, page: query.page + 1 })}>
                  Berikutnya
                </Button>
              )}
            </Stack>
          )}
        </Stack>
      </Box>
      <FormModal
        formModal={formModal}
        setFormModal={setFormModal}
        folderImage={folder}
        changeState={changeState}
        setChangeState={setChangeState}
      />
      <DetailImage detailModal={detailModal} setDetailModal={setDetailModal} />
    </Flex>
  )
}

export default ListImage
