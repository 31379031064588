import { Box, Flex, Text, Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react"

function RankCategory() {
  const data = [
    {
      class: "UTBK",
      totalTransaction: "120",
    },
    {
      class: "Simak UI",
      totalTransaction: "12",
    },
    {
      class: "UTUL UGM",
      totalTransaction: "12",
    },
    {
      class: "UM Undip",
      totalTransaction: "12",
    },
    {
      class: "STAN",
      totalTransaction: "12",
    },
  ]
  return (
    <Box w="full" bg="white" borderRadius="md" p={["4", "6"]} boxShadow="md">
      <Flex direction="column">
        <Text color="gray.600" fontWeight="medium">
          Peringkat Kategori
        </Text>
        <Flex overflowX="auto" overflowY="auto" direction="column" mt="4">
          <Table variant="simple" size="md" mb={4}>
            <Thead>
              <Tr color="gray.100" borderTop="1px">
                <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                  No
                </Th>
                <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                  Kategori
                </Th>
                <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                  Transaksi
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map((item, index) => (
                <Tr key={index} fontSize="sm" color="gray.550">
                  <Td>{index + 1}</Td>
                  <Td fontWeight="medium">{item.class}</Td>
                  <Td>{item.totalTransaction}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Flex>
      </Flex>
    </Box>
  )
}

export default RankCategory
