import { useNavigate, useParams } from "react-router-dom"
import { Flex, Text, Box } from "@chakra-ui/react"
import { Form } from "./components"
import { useForm } from "react-hook-form"
import { joiResolver } from "@hookform/resolvers/joi"
import Joi from "joi"
import { updateClassByClassId, getClassByClassId } from "../../api/classes"
import { useEffect, useState } from "react"

function Update() {
  const navigate = useNavigate()
  const { classId } = useParams()
  const schema = Joi.object({
    id: Joi.string().allow(""),
    name: Joi.string().required(),
    categoryId: Joi.string().required(),
    subCategoryId: Joi.string().allow("").allow(null),
    price: Joi.number().required(),
    priceId: Joi.string().required(),
    type: Joi.string().required(),
    isActive: Joi.boolean().required(),
  })
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    getValues,
  } = useForm({ resolver: joiResolver(schema) })
  const [dataClass, setDataClass] = useState({ isLoading: false, isFormLoading: false, error: null })

  useEffect(() => {
    const GetClass = async () => {
      setDataClass({ ...dataClass, isFormLoading: true })
      const { success, data } = await getClassByClassId(classId)
      if (success) {
        setValue("id", data.id)
        setValue("name", data.name)
        setValue("categoryId", data.categoryId)
        setValue("subCategoryId", data.subCategoryId)
        setValue("price", data.price.price)
        setValue("priceId", data.price.id)
        setValue("type", data.type)
        setValue("isActive", data.isActive)
        setDataClass({ ...dataClass, isFormLoading: false })
      }
    }
    GetClass()
    return () => {
      setEmpty()
    }
  }, [classId])

  const onSubmit = async (data) => {
    setDataClass({ ...dataClass, isLoading: true })
    if (data.subCategoryId === "") {
      data.subCategoryId = null
    }
    const result = await updateClassByClassId(data)
    if (result.success) {
      setDataClass({ ...dataClass, isLoading: false })
      setEmpty()
      navigate("/class")
    } else {
      setDataClass({ ...dataClass, isLoading: false, error: "Kelas gagal diupdate" })
    }
  }

  const setEmpty = () => {
    setValue("id", "")
    setValue("name", "")
    setValue("categoryId", "")
    setValue("subCategoryId", null)
    setValue("priceId", "")
    setValue("type", "")
    setValue("price", 0)
    setValue("isActive", false)
  }

  return (
    <Flex direction="column">
      <Text color="gray.750" fontSize="2xl" fontWeight="medium">
        Kelas
      </Text>
      <Box bg="white" mt="4" px={[4, 6, 8]} py={8}>
        <Form
          register={register}
          errors={errors}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          watch={watch}
          isLoading={dataClass.isLoading}
          isFormLoading={dataClass.isFormLoading}
          type="update"
          setValue={setValue}
          getValues={getValues}
        />
      </Box>
    </Flex>
  )
}

export default Update
