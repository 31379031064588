import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Text,
} from "@chakra-ui/react"

export default function DeleteAlert(props) {
  const onClose = () => {
    props.setOpen({ isOpen: !props.isOpen })
  }
  return (
    <>
      <AlertDialog isCentered isOpen={props.isOpen} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Hapus {props.title}
            </AlertDialogHeader>
            <AlertDialogBody color="gray.550">
              <Text fontWeight="semibold" fontSize="md">
                Apakah anda yakin ?
              </Text>
              <Text fontSize="sm">Data yang sudah dihapus tidak dapat kembali.</Text>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={onClose} isDisabled={props.isLoading} variant="outline" _focus={{ boxShadow: "none" }}>
                Batal
              </Button>
              <Button onClick={props.action} isLoading={props.isLoading} ml={3}>
                Hapus
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
