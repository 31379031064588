import { Flex, Text, Box, Stack, Button, Icon, Wrap, WrapItem } from "@chakra-ui/react"
import { FolderIcon } from "@heroicons/react/solid"
import { useNavigate } from "react-router-dom"
import { FOLDER } from "../../constants/folder"

function Folder() {
  const folders = Object.values(FOLDER).filter((folder) => folder != "profile")
  const navigate = useNavigate()
  return (
    <Flex direction="column">
      <Text color="gray.750" fontSize="2xl" fontWeight="medium">
        Media
      </Text>
      <Box bg="white" mt="4" px={[4, 5, 6]} py={8}>
        <Flex direction="column" minH="70vh">
          <Wrap spacing={["2", "4"]}>
            {folders.map((folder, index) => (
              <WrapItem key={index}>
                <Box
                  variant="unstyled"
                  _focus={{ boxShadow: "none" }}
                  onClick={() => navigate(`/media/${folder}`)}
                  as="button"
                >
                  <Stack spacing="-2">
                    <Icon as={FolderIcon} h="24" w="24" color="orange.300" />
                    <Text fontSize="sm" fontWeight="semibold" color="gray.550">
                      {folder}
                    </Text>
                  </Stack>
                </Box>
              </WrapItem>
            ))}
          </Wrap>
        </Flex>
      </Box>
    </Flex>
  )
}

export default Folder
