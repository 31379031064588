import { Box, Text } from "@chakra-ui/react"

function TodayInformation() {
  return (
    <Box w="full" boxShadow="md" bg="white" borderRadius="md" py="5" textAlign="center">
      <Text color="gray.600" fontSize="md" fontWeight="semibold">
        Menunggu Konfirmasi
      </Text>
      <Text color="purple.450" fontSize="2xl" fontWeight="bold" mt="1">
        100
      </Text>
    </Box>
  )
}

export default TodayInformation
