import {
  Flex,
  Text,
  Select,
  FormControl,
  FormLabel,
  Stack,
  Input,
  Button,
  Switch,
  Center,
  Spinner,
  RadioGroup,
  Radio,
} from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getCategories } from "../../../api/category"
import { getSubCategories } from "../../../api/subCategory"
import { CLASS_TYPE } from "../../../constants/class"

function Form(props) {
  const { register, errors, handleSubmit, onSubmit, watch, isLoading, type, setValue, getValues, isFormLoading } = props
  const navigate = useNavigate()
  const [categories, setCategories] = useState({
    data: [],
    isLoading: false,
    error: null,
  })
  const [subCategories, setSubCategories] = useState({
    data: [],
    isLoading: false,
    error: null,
  })
  const classTypes = Object.values(CLASS_TYPE)
  useEffect(() => {
    const GetCategories = async () => {
      setCategories({ ...categories, isLoading: true })
      const { success, data } = await getCategories()
      if (success) {
        setCategories({ ...categories, data: data.categories, isLoading: false })
      }
    }
    GetCategories()
    return () => {
      setCategories({ data: [], isLoading: false, error: null })
    }
  }, [])

  useEffect(() => {
    const GetSubCategories = async () => {
      setSubCategories({ ...subCategories, isLoading: true })
      const category = categories.data.find((category) => category.id === watch("categoryId"))
      const { success, data } = await getSubCategories({ category: category?.slug })
      if (success) {
        setSubCategories({ ...subCategories, data: data.subCategories, isLoading: false })
      }
    }
    GetSubCategories()
    return () => {
      setSubCategories({ data: [], isLoading: false, error: null })
    }
  }, [watch("categoryId")])

  const redirectToClass = () => {
    navigate("/class")
  }

  return (
    <Flex direction="column">
      {(categories.isLoading || isFormLoading) && (
        <Flex direction="column">
          <Center minH="50vh">
            <Spinner thickness="4px" speed="1s" emptyColor="gray.200" color="purple.450" size="lg" />
          </Center>
        </Flex>
      )}
      {!categories.isLoading && !isFormLoading && (
        <Flex direction="column">
          <Flex direction="row" justify="space-between">
            <Text color="gray.750" fontSize="xl" fontWeight="medium">
              {`${type === "create" ? "Tambah" : "Edit"} Kelas`}
            </Text>
          </Flex>
          <form>
            <Stack mt="8" spacing="4">
              <FormControl>
                <Flex direction={["column", "row"]} alignItems="center">
                  <Flex w={["full", 1 / 3, 1 / 4]}>
                    <FormLabel w={["full", 1 / 3, 1 / 4]} fontSize="sm" color="gray.550">
                      Nama
                    </FormLabel>
                  </Flex>
                  <Flex w={["full", 2 / 3, 3 / 4]} direction="column">
                    <Input type="text" {...register("name")} isInvalid={errors.name} />
                    <Text fontSize="sm" color="red.500">
                      {errors.name?.message}
                    </Text>
                  </Flex>
                </Flex>
              </FormControl>
              <FormControl>
                <Flex direction={["column", "row"]} alignItems="center">
                  <Flex w={["full", 1 / 3, 1 / 4]}>
                    <FormLabel fontSize="sm" color="gray.550">
                      Kategori
                    </FormLabel>
                  </Flex>
                  <Flex w={["full", 1 / 3, 1 / 3]} direction="column">
                    <Select placeholder="Pilih Kategori" {...register("categoryId")} isInvalid={errors.categoryId}>
                      {categories.data.map((category) => (
                        <option value={category.id} key={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </Select>
                    <Text fontSize="sm" color="red.500">
                      {errors.categoryId?.message}
                    </Text>
                  </Flex>
                </Flex>
              </FormControl>
              <FormControl>
                <Flex direction={["column", "row"]} alignItems="center">
                  <Flex w={["full", 1 / 3, 1 / 4]}>
                    <FormLabel fontSize="sm" color="gray.550">
                      Sub Kategori
                    </FormLabel>
                  </Flex>
                  <Flex w={["full", 1 / 3, 1 / 3]} direction="column">
                    <Select
                      placeholder="Pilih Sub Kategori"
                      {...register("subCategoryId")}
                      isInvalid={errors.subCategoryId}
                    >
                      {subCategories.data.map((subCategory) => (
                        <option value={subCategory.id} key={subCategory.id}>
                          {subCategory.name}
                        </option>
                      ))}
                    </Select>
                    <Text fontSize="sm" color="red.500">
                      {errors.subCategoryId?.message}
                    </Text>
                  </Flex>
                </Flex>
              </FormControl>
              <FormControl>
                <Flex direction={["column", "row"]} alignItems="center">
                  <Flex w={["full", 1 / 3, 1 / 4]}>
                    <FormLabel fontSize="sm" color="gray.550">
                      Tipe
                    </FormLabel>
                  </Flex>
                  <Flex w={["full", 1 / 3, 1 / 3]} direction="column">
                    <RadioGroup onChange={(e) => setValue("type", e)} defaultValue={getValues("type")}>
                      <Stack direction="row" spacing="6">
                        {classTypes.map((item, index) => (
                          <Radio colorScheme="purple" value={item} key={index} cursor="pointer">
                            <Text color="gray.600" textTransform="capitalize" fontSize="sm" cursor="pointer">
                              {item}
                            </Text>
                          </Radio>
                        ))}
                      </Stack>
                    </RadioGroup>
                    <Text fontSize="sm" color="red.500">
                      {errors.type?.message}
                    </Text>
                  </Flex>
                </Flex>
              </FormControl>
              <FormControl>
                <Flex direction={["column", "row"]} alignItems="center">
                  <Flex w={["full", 1 / 3, 1 / 4]}>
                    <FormLabel w={["full", 1 / 3, 1 / 4]} fontSize="sm" color="gray.550">
                      Harga
                    </FormLabel>
                  </Flex>
                  <Flex w={["full", 2 / 3, 1 / 4]} direction="column">
                    <Input type="text" {...register("price")} isInvalid={errors.price} />
                    <Text fontSize="sm" color="red.500">
                      {errors.price?.message}
                    </Text>
                  </Flex>
                </Flex>
              </FormControl>
              {type === "update" && (
                <FormControl>
                  <Flex direction={["column", "row"]} alignItems="center">
                    <Flex w={["full", 1 / 3, 1 / 4]}>
                      <FormLabel w={["full", 1 / 3, 1 / 4]} fontSize="sm" color="gray.550">
                        Aktif
                      </FormLabel>
                    </Flex>
                    <Flex w={["full", 2 / 3, 1 / 4]} direction="column">
                      <Switch
                        size="lg"
                        onChange={(e) => setValue("isActive", e.target.value)}
                        {...register("isActive")}
                        isChecked={getValues("isActive")}
                      />
                      <Text fontSize="sm" color="red.500">
                        {errors.isActive?.message}
                      </Text>
                    </Flex>
                  </Flex>
                </FormControl>
              )}
            </Stack>
            <Stack direction="row" mt="12" justify="flex-end" spacing="4" w="full">
              <Button variant="outline" w={["full", 1 / 4, 1 / 8]} onClick={redirectToClass}>
                Batal
              </Button>
              <Button w={["full", 1 / 4, 1 / 8]} onClick={handleSubmit(onSubmit)} isLoading={isLoading}>
                Submit
              </Button>
            </Stack>
          </form>
        </Flex>
      )}
    </Flex>
  )
}

export default Form
