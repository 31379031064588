import { useEffect, useState } from "react"
import {
  Alert,
  AlertIcon,
  Box,
  Flex,
  Input,
  Button,
  FormControl,
  FormLabel,
  Image,
  Stack,
  Text,
  InputGroup,
  InputRightElement,
  Icon,
} from "@chakra-ui/react"
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline"
import Joi from "joi"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { joiResolver } from "@hookform/resolvers/joi"
import { login } from "../../api/auth"
import { setToken, getToken } from "../../helpers/token"
import bidikkampusLogo from "../../assets/bidikkampus.png"

const schema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  password: Joi.string().required(),
})

export default function Login() {
  const navigate = useNavigate()
  const [error, setError] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: joiResolver(schema),
  })

  useEffect(() => {
    if (getToken() !== null) {
      navigate("/dashboard")
      return <></>
    }
  }, [])

  const onSubmit = async (data) => {
    setError(null)
    setLoading(true)
    const result = await login(data)
    if (result.success === true) {
      setLoading(false)
      setToken(result.data.refreshToken)
      navigate("/dashboard")
    } else {
      setLoading(false)
      setError("Email atau Password salah")
      return
    }
  }

  return (
    <Flex minHeight="100vh" align="center" justifyContent="center" px={3} bg="gray.50">
      <Box px={4} width="full" maxWidth="370px" borderRadius={4} shadow="base" bg="white">
        <Box p={5}>
          <Box align="center" mt="3" mb="8">
            <Image src={bidikkampusLogo} w="200px" />
          </Box>
          {error !== null ? (
            <Alert size="sm" status="error">
              <AlertIcon />
              {error}
            </Alert>
          ) : (
            <></>
          )}
          <Box my={5} mt="5">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing="3">
                <FormControl>
                  <FormLabel fontSize="sm">Email</FormLabel>
                  <Input type="email" {...register("email")} isInvalid={errors.email} />
                  <Text fontSize="sm" color="red.500">
                    {errors.email?.message}
                  </Text>
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="sm">Password</FormLabel>
                  <InputGroup>
                    <Input
                      type={showPassword ? "text" : "password"}
                      {...register("password")}
                      isInvalid={errors.password}
                    />
                    <InputRightElement cursor="pointer" onClick={() => setShowPassword(!showPassword)}>
                      <Icon as={showPassword ? EyeOffIcon : EyeIcon} color="gray.500" />
                    </InputRightElement>
                  </InputGroup>
                  <Text fontSize="sm" color="red.500">
                    {errors.password?.message}
                  </Text>
                </FormControl>
              </Stack>
              <Flex direction="column" mt={8}>
                <Button onClick={handleSubmit(onSubmit)} isLoading={isLoading} type="submit">
                  Masuk
                </Button>
              </Flex>
            </form>
          </Box>
        </Box>
      </Box>
    </Flex>
  )
}
