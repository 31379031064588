import { Box, Flex, Text, Table, Thead, Tbody, Tr, Th, Td, Tfoot } from "@chakra-ui/react"
import { convertToRupiah } from "../../../helpers/rupiah"

function Region() {
  const data = [
    {
      province: "Jawa Tengah",
      totalUser: "22",
      totalProduct: "12",
      TotalIncome: "20000",
    },
    {
      province: "Jawa Tengah",
      totalUser: "22",
      totalProduct: "12",
      TotalIncome: "20000",
    },
    {
      province: "Jawa Tengah",
      totalUser: "22",
      totalProduct: "12",
      TotalIncome: "20000",
    },
    {
      province: "Jawa Tengah",
      totalUser: "22",
      totalProduct: "12",
      TotalIncome: "20000",
    },
  ]
  return (
    <Box w="full" bg="white" borderRadius="md" p={["4", "6"]} boxShadow="md">
      <Flex direction="column">
        <Text color="gray.600" fontWeight="medium">
          Asal Provinsi
        </Text>
        <Flex overflowX="auto" overflowY="auto" direction="column" mt="4" maxH="lg">
          <Table variant="simple" size="md" mb={4}>
            <Thead>
              <Tr color="gray.100" borderTop="1px">
                <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                  Provinsi
                </Th>
                <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                  Jumlah Pembeli
                </Th>
                <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                  Produk
                </Th>
                <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                  Pendapatan
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map((item, index) => (
                <Tr key={index} fontSize="sm" color="gray.550">
                  <Td fontWeight="medium"> {item.province}</Td>
                  <Td>{item.totalUser}</Td>
                  <Td>{item.totalProduct}</Td>
                  <Td>{convertToRupiah(item.TotalIncome)}</Td>
                </Tr>
              ))}
            </Tbody>
            <Tfoot></Tfoot>
          </Table>
        </Flex>
      </Flex>
    </Box>
  )
}

export default Region
