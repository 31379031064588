import Axios from "../helpers/axios"
import { parseToSlug } from "../helpers/string"

export const getSubCategories = async (query = {}) => {
  try {
    const { admin = true, show = 10, page = 0, category = "" } = query
    const { data: response } = await Axios.get(
      `/subcategory?admin=${admin}&show=${show}&page=${page}&category=${category}`
    )
    return response
  } catch (err) {
    return err.response.data
  }
}

export const storeSubCategory = async (data) => {
  try {
    const { categoryId, name } = data
    const { data: response } = await Axios.post("/subcategory", {
      categoryId,
      name,
      slug: parseToSlug(name),
    })
    return response
  } catch (err) {
    return err.response.data
  }
}

export const getSubCategoryBySubCategoryId = async (id) => {
  try {
    const { data: response } = await Axios.get(`/subcategory/${id}`)
    return response
  } catch (err) {
    return err.response.data
  }
}

export const updateSubCategoryBySubCategoryId = async (data) => {
  try {
    const { id, name } = data
    const { data: response } = await Axios.put(`/subcategory/${id}`, { name })
    return response
  } catch (err) {
    console.log(err.response)
    return err.response.data
  }
}

export const deleteSubCategoryBySubCategoryId = async (id) => {
  try {
    const { data: response } = await Axios.delete(`/subcategory/${id}`)
    return response
  } catch (err) {
    return err.response.data
  }
}
