import Axios from "../helpers/axios"

export const getClasses = async (query = {}) => {
  try {
    const { admin = true, show = 10, page = 0, category = "", active = "", search = "", sortBy = "DESC" } = query
    const { data: response } = await Axios.get(
      `/class?admin=${admin}&show=${show}&page=${page}&category=${category}&active=${active}&search=${search}&sortBy=${sortBy}`
    )
    return response
  } catch (err) {
    return err.response.data
  }
}

export const storeClass = async (data) => {
  try {
    const { data: response } = await Axios.post("/class", data)
    return response
  } catch (err) {
    return err.response.data
  }
}

export const getClassByClassId = async (id) => {
  try {
    const { data: response } = await Axios.get(`/class/${id}?admin=true`)
    return response
  } catch (err) {
    return err.response.data
  }
}

export const updateClassByClassId = async (data) => {
  try {
    const { id, name, categoryId, subCategoryId, price, priceId, isActive, type } = data
    const { data: response } = await Axios.put(`/class/${id}`, {
      name,
      categoryId,
      subCategoryId,
      price,
      priceId,
      isActive,
      type,
    })
    return response
  } catch (err) {
    return err.response.data
  }
}

export const deleteClassByClassId = async (id) => {
  try {
    const { data: response } = await Axios.delete(`/class/${id}`)
    return response
  } catch (err) {
    return err.response.data
  }
}

export const changeStatusClassByClassId = async (id) => {
  try {
    const { data: response } = await Axios.patch(`/class/${id}/status`)
    return response
  } catch (err) {
    return err.response.data
  }
}
