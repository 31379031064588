import { Box, Flex, Stack, Text } from "@chakra-ui/react"
import { Doughnut } from "react-chartjs-2"

function Gender() {
  const data = {
    datasets: [
      {
        data: [1000, 700, 200],
        backgroundColor: ["#a06cd5", "#d2b7e5", "#ECEFF1"],
        borderWidth: 0,
      },
    ],
  }
  return (
    <Box w="full" bg="white" borderRadius="md" p={["4", "6"]} boxShadow="md">
      <Flex direction="column">
        <Text color="gray.600" fontWeight="medium">
          Jenis Kelamin
        </Text>
        <Stack mt="6" direction={["column", "row"]} spacing={["4", "8", "10"]}>
          <Flex w={["full", "50%"]}>
            <Doughnut data={data} />
          </Flex>
          <Stack w={["full", "50%"]} direction="column" spacing="4">
            <Stack direction="row">
              <Stack>
                <Box p="2" borderRadius="full" bg="#a06cd5" mt="1"></Box>
              </Stack>
              <Stack direction="column" spacing="0" fontSize="md">
                <Text color="gray.500">Laki - Laki</Text>
                <Text color="gray.600" fontWeight="semibold">
                  1200
                </Text>
              </Stack>
            </Stack>
            <Stack direction="row">
              <Stack>
                <Box p="2" borderRadius="full" bg="#d2b7e5" mt="1"></Box>
              </Stack>
              <Stack direction="column" spacing="0" fontSize="md">
                <Text color="gray.500">Perempuan</Text>
                <Text color="gray.600" fontWeight="semibold">
                  1200
                </Text>
              </Stack>
            </Stack>
            <Stack direction="row">
              <Stack>
                <Box p="2" borderRadius="full" bg="#ECEFF1" mt="1"></Box>
              </Stack>
              <Stack direction="column" spacing="0" fontSize="md">
                <Text color="gray.500">Tidak Diketahui</Text>
                <Text color="gray.600" fontWeight="semibold">
                  1200
                </Text>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Flex>
    </Box>
  )
}

export default Gender
