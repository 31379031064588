import { Box, Flex } from "@chakra-ui/layout"
import { Line } from "react-chartjs-2"

function Statistics() {
  const data = {
    labels: ["1", "2", "3", "4", "5", "6"],
    datasets: [
      {
        data: [12, 19, 3, 5, 2, 10],
        backgroundColor: "#a06cd5",
        borderColor: "#d2b7e5",
        tension: 0.2,
      },
    ],
  }

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    plugins: {
      legend: false,
    },
  }
  return (
    <Box w="full" bg="white" borderRadius="md" p={["4", "6"]} boxShadow="md">
      <Flex direction="column">
        <Line height={100} data={data} options={options} />
      </Flex>
    </Box>
  )
}

export default Statistics
