import * as React from "react"
import ReactPaginate from "react-paginate"
import "./pagination.css"

function Pagination(props) {
  const { handleChange, totalPages } = props
  return (
    <ReactPaginate
      breakLabel="..."
      breakLinkClassName="page-link"
      nextClassName="page-item-prevnext"
      nextLinkClassName="page-link"
      pageRangeDisplayed={3}
      pageCount={totalPages}
      pageClassName="page-item"
      pageLinkClassName="page-link"
      previousClassName="page-item-prevnext"
      previousLinkClassName="page-link"
      renderOnZeroPageCount={null}
      containerClassName="paginate"
      activeClassName="active"
      onPageChange={handleChange}
    />
  )
}

export default React.memo(Pagination)
