import { Box, Text } from "@chakra-ui/react"

function Payment(props) {
  const { display, bgColor, textColor } = props
  return (
    <Box bg={bgColor} p="1" rounded="md" alignSelf="center">
      <Text color={textColor} fontSize="xs" textAlign="center" fontWeight="bold">
        {display}
      </Text>
    </Box>
  )
}

export default Payment
