import {
  Flex,
  Box,
  Text,
  Icon,
  FormControl,
  Select,
  InputGroup,
  Input,
  InputRightElement,
  Button,
  Stack,
  Skeleton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tfoot,
} from "@chakra-ui/react"
import { SearchIcon } from "@heroicons/react/outline"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { getStudies } from "../../api/study"

function ListStudy() {
  const shows = [10, 20, 50]
  const [studies, setStudies] = useState({ data: [], isLoading: false, error: null })
  const [pagination, setPagination] = useState({ totalItems: 0, totalPages: 0, currentPage: 0 })
  const [query, setQuery] = useState({
    page: 0,
    show: 10,
    search: "",
  })

  useEffect(() => {
    const GetStudies = async () => {
      setStudies({ ...studies, isLoading: true })
      const result = await getStudies(query)
      if (result.success) {
        const { totalItems, totalPages, currentPage } = result.data
        setStudies({ ...studies, data: result.data.studies, isLoading: false })
        setPagination({ totalItems, totalPages, currentPage })
      }
    }
    GetStudies()
    return () => {
      setStudies({ data: [], isLoading: false, error: null })
      setPagination({ totalItems: 0, totalPages: 0, currentPage: 0 })
    }
  }, [query])
  return (
    <Flex direction="column">
      <Text color="gray.750" fontSize="2xl" fontWeight="medium">
        Statistik Bank Soal
      </Text>
      <Box bg="white" mt="4" px={[4, 5, 6]} py={8}>
        <Flex direction="column" minH="70vh">
          <Text color="gray.750" fontSize="xl" mb="2" fontWeight="medium">
            Daftar Pelajaran
          </Text>
          <Stack direction="row" justify="space-between" py="4">
            <Flex w={["30%", "10%"]}>
              <FormControl>
                <Select onChange={(e) => setQuery({ ...query, show: e.target.value })}>
                  {shows.map((show, index) => (
                    <option value={show} key={index}>
                      {show}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Flex>
            <FormControl width={["70%", "xs"]}>
              <InputGroup>
                <Input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => {
                    if (e.target.value.length === 0 || e.target.value.length >= 5) {
                      return setQuery({ ...query, search: e.target.value })
                    }
                  }}
                />
                <InputRightElement>
                  <Button variant="ghost" _focus={{ boxShadow: "none" }}>
                    <Icon as={SearchIcon} color="gray.550" h="4" w="4" />
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
          </Stack>
          <Flex overflowX="auto" overflowY="hidden" direction="column" mt="4">
            {studies.isLoading && (
              <Stack spacing={6}>
                {[...Array(8)].map((e, i) => (
                  <Stack key={i}>
                    <Skeleton startColor="gray.100" endColor="gray.200" height="30px" />
                  </Stack>
                ))}
              </Stack>
            )}
            {!studies.isLoading && (
              <Table variant="simple" size="md" mb={4}>
                <Thead>
                  <Tr color="gray.100" borderTop="1px">
                    <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                      PELAJARAN
                    </Th>
                    <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                      JUMLAH SOAL
                    </Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {studies.data.map((study, index) => (
                    <Tr key={index} fontSize="sm" color="gray.550">
                      <Td>{study.name}</Td>
                      <Td>{study.totalQuest}</Td>
                      <Td>
                        <Link to={`/question?studyId=${study.id}&study=${study.name.replaceAll(" ", "-")}`}>
                          <Button
                            size="sm"
                            fontSize="xs"
                            variant="outline"
                            color="purple.450"
                            borderColor="purple.450"
                            px="4"
                          >
                            Detail
                          </Button>
                        </Link>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
                <Tfoot></Tfoot>
              </Table>
            )}
          </Flex>
        </Flex>
        <Stack spacing="2" direction={["column", "row"]} justify="space-between" mt="4">
          <Text fontSize="sm" color="gray.550">
            Menampilkan 1 sampai {query.show} dari {pagination.totalItems} data
          </Text>
          {pagination.totalItems > query.show && (
            <Stack direction="row" spacing="1">
              {pagination.currentPage > 0 && (
                <Button variant="outline" onClick={() => setQuery({ ...query, page: query.page - 1 })}>
                  Sebelumnya
                </Button>
              )}
              {[...Array(pagination.totalPages)].map((e, i) => (
                <Button
                  variant={i === pagination.currentPage ? "solid" : "outline"}
                  onClick={() => setQuery({ ...query, page: i })}
                  key={i}
                >
                  {i + 1}
                </Button>
              ))}
              {pagination.currentPage < pagination.totalPages - 1 && (
                <Button variant="outline" onClick={() => setQuery({ ...query, page: query.page + 1 })}>
                  Berikutnya
                </Button>
              )}
            </Stack>
          )}
        </Stack>
      </Box>
    </Flex>
  )
}

export default ListStudy
