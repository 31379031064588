import { Flex, Text, Box } from "@chakra-ui/react"
import { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { updateContentByContentId, getContentByContentId } from "../../api/content"
import { getMaterials } from "../../api/material"
import { Form } from "./components"
import { useForm } from "react-hook-form"
import { joiResolver } from "@hookform/resolvers/joi"
import dayjs from "dayjs"
import Joi from "joi"

function Update() {
  const navigate = useNavigate()
  const { contentId } = useParams()
  const initClass = {
    id: "",
    name: "",
    category: {
      name: "",
    },
    subCategory: {
      name: "",
    },
  }
  const schema = Joi.object({
    id: Joi.string().required(),
    materialId: Joi.string(),
    type: Joi.string(),
    time: Joi.number().required(),
    startDate: Joi.date().required(),
    startTime: Joi.string().required(),
    endDate: Joi.date().required(),
    endTime: Joi.string().required(),
  })
  const [classBK, setClassBK] = useState({ data: { ...initClass }, isLoading: false, error: null })
  const [content, setContent] = useState({ isLoading: false, error: null })
  const [materials, setMaterials] = useState({ data: [], isLoading: false, error: null })
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
  } = useForm({ resolver: joiResolver(schema) })

  useEffect(() => {
    const GetMaterials = async () => {
      setMaterials({ ...materials, isLoading: true })
      const result = await getMaterials()
      if (result.success) {
        setMaterials({ ...materials, data: result.data.materials, isLoading: false })
      }
    }
    GetMaterials()
    return () => {
      setMaterials({ data: [], isLoading: false, error: null })
    }
  }, [])

  useEffect(() => {
    const GetContent = async () => {
      setClassBK({ ...classBK, isLoading: true })
      const result = await getContentByContentId(contentId)
      if (result.success) {
        const { id, classBK, materialId, type, time, start, end } = result.data.content
        const startDate = dayjs(start)
        const endDate = dayjs(end)
        setValue("id", id)
        setValue("materialId", materialId)
        setValue("type", type)
        setValue("time", time)
        setValue("startDate", startDate.format("YYYY-MM-DD"))
        setValue("startTime", startDate.format("HH:mm"))
        setValue("endDate", endDate.format("YYYY-MM-DD"))
        setValue("endTime", endDate.format("HH:mm"))
        setClassBK({ ...classBK, data: classBK, isLoading: false })
      }
    }
    GetContent()
  }, [contentId])

  const onSubmit = async (data) => {
    setContent({ ...content, isLoading: true })
    const startTimes = data.startTime.split(":")
    const start = dayjs(data.startDate).hour(startTimes[0]).minute(startTimes[1])
    const endTimes = data.endTime.split(":")
    const end = dayjs(data.endDate).hour(endTimes[0]).minute(endTimes[1])
    const result = await updateContentByContentId({ ...data, start, end })
    if (result.success) {
      setContent({ ...content, isLoading: false })
      navigate("/content")
    }
  }
  return (
    <Flex direction="column">
      <Text color="gray.750" fontSize="2xl" fontWeight="medium">
        Konten
      </Text>
      <Box bg="white" mt="4" px={[4, 6, 8]} py={8}>
        <Flex direction="row" justify="space-between">
          <Text color="gray.750" fontSize="xl" fontWeight="medium">
            Edit Konten
          </Text>
        </Flex>
        <Form
          type="update"
          classBK={classBK}
          register={register}
          errors={errors}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          content={content}
          materials={materials}
          setValue={setValue}
          getValues={getValues}
        />
      </Box>
    </Flex>
  )
}

export default Update
