import { SimpleGrid, Box, Text } from "@chakra-ui/react"

function Summary() {
  return (
    <SimpleGrid w="full" spacing="5" columns={[1, 2, 2, 4]} direction="row">
      <Box w="full" boxShadow="md" bg="white" borderRadius="md" py="5" textAlign="center">
        <Text color="gray.600" fontSize="md" fontWeight="semibold">
          Total Member
        </Text>
        <Text color="purple.450" fontSize="2xl" fontWeight="bold" mt="1">
          80
        </Text>
      </Box>
      <Box w="full" boxShadow="md" bg="white" borderRadius="md" py="5" textAlign="center">
        <Text color="gray.600" fontSize="md" fontWeight="semibold">
          Total Transaksi
        </Text>
        <Text color="purple.450" fontSize="2xl" fontWeight="bold" mt="1">
          180
        </Text>
      </Box>
      <Box w="full" boxShadow="md" bg="white" borderRadius="md" py="5" textAlign="center">
        <Text color="gray.600" fontSize="md" fontWeight="semibold">
          Total Pendapatan
        </Text>
        <Text color="purple.450" fontSize="2xl" fontWeight="bold" mt="1">
          Rp. 200.000.000
        </Text>
      </Box>
      <Box w="full" boxShadow="md" bg="white" borderRadius="md" py="5" textAlign="center">
        <Text color="gray.600" fontSize="md" fontWeight="semibold">
          Total Produk
        </Text>
        <Text color="purple.450" fontSize="2xl" fontWeight="bold" mt="1">
          20
        </Text>
      </Box>
    </SimpleGrid>
  )
}

export default Summary
