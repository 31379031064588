import { Flex, Text, Stack, Center, Spinner } from "@chakra-ui/react"
import { FormQuestion } from "./components"
import { useForm } from "react-hook-form"
import { joiResolver } from "@hookform/resolvers/joi"
import Joi from "joi"
import { useState, useEffect } from "react"
import { storeQuestion } from "../../api/question"
import { getUsers } from "../../api/user"
import { getStudies } from "../../api/study"
import { useNavigate } from "react-router-dom"

function Create() {
  const navigate = useNavigate()
  const schema = Joi.object({
    studyId: Joi.string().required(),
    level: Joi.string().required(),
    question: Joi.string().required(),
    userId: Joi.string().required(),
    choice: Joi.number().required(),
    choices: Joi.array().required(),
    discussion: Joi.string().required(),
  })
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    control,
  } = useForm({
    resolver: joiResolver(schema),
  })
  const [question, setQuestion] = useState({ isLoading: false, error: null })
  const [users, setUsers] = useState({ data: [], isLoading: false, error: null })
  const [studies, setStudies] = useState({ data: [], isLoading: false, error: null })

  useEffect(() => {
    const GetStudies = async () => {
      setStudies({ ...studies, isLoading: false })
      const result = await getStudies({ show: 20 })
      if (result.success) {
        setStudies({ ...studies, data: result.data.studies, isLoading: false })
      }
    }
    const GetUsers = async () => {
      setUsers({ ...users, isLoading: true })
      const result = await getUsers({ show: 20, role: "author" })
      if (result.success) {
        setUsers({ ...users, data: result.data.users, isLoading: false })
      }
    }
    GetStudies()
    GetUsers()
    return () => {
      setUsers({ data: [], isLoading: false, error: null })
      setStudies({ data: [], isLoading: false, error: null })
    }
  }, [])

  const onSubmit = async (data) => {
    setQuestion({ ...question, isLoading: true })
    const choices = data.choices.map((choice, index) => ({ info: choice, isCorrect: data.choice === index }))
    const result = await storeQuestion({ ...data, choices })
    if (result.success) {
      setQuestion({ ...question, isLoading: false })
      const study = studies.data.find((study) => study.id === getValues("studyId"))
      navigate(`/question?studyId=${study.id}&study=${study.name.replaceAll(" ", "-")}`)
    }
  }

  return (
    <Flex direction="column">
      <Text color="gray.750" fontSize="2xl" fontWeight="medium">
        Tambah Soal
      </Text>
      {(users.isLoading || studies.isLoading) && (
        <Stack direction="column" mt="4" spacing="4">
          <Flex direction="column">
            <Center minH="40vh" bg="white">
              <Spinner thickness="4px" speed="1s" emptyColor="gray.200" color="purple.450" size="lg" />
            </Center>
          </Flex>
          <Flex direction="column">
            <Center minH="50vh" bg="white">
              <Spinner thickness="4px" speed="1s" emptyColor="gray.200" color="purple.450" size="lg" />
            </Center>
          </Flex>
          <Flex direction="column">
            <Center minH="40vh" bg="white">
              <Spinner thickness="4px" speed="1s" emptyColor="gray.200" color="purple.450" size="lg" />
            </Center>
          </Flex>
        </Stack>
      )}
      {!users.isLoading && !studies.isLoading && (
        <FormQuestion
          type="create"
          setValue={setValue}
          getValues={getValues}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          control={control}
          errors={errors}
          register={register}
          users={users}
          studies={studies}
          question={question}
        />
      )}
    </Flex>
  )
}

export default Create
