import {
  Box,
  Flex,
  Stack,
  FormControl,
  FormLabel,
  Text,
  Select,
  useRadio,
  useRadioGroup,
  VStack,
  Button,
} from "@chakra-ui/react"
import { useContext } from "react"
import { StateContext } from "../../../store"
import TextEditor from "../../../components/TextEditor"
import { useNavigate, useLocation } from "react-router-dom"
import { Controller } from "react-hook-form"
import { QUESTION_LEVEL } from "../../../constants/question"
import { FOLDER } from "../../../constants/folder"

const useQuery = () => new URLSearchParams(useLocation().search)
function RadioCard(props) {
  const { getInputProps, getCheckboxProps } = useRadio(props)
  const input = getInputProps()
  const checkbox = getCheckboxProps()
  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        _checked={{
          bg: "purple.450",
          color: "white",
          borderColor: "purple.450",
        }}
        _focus={{
          boxShadow: "none",
        }}
        px={[3, 4]}
        py={[1, 2]}
      >
        {props.children}
      </Box>
    </Box>
  )
}

function Form(props) {
  const navigate = useNavigate()
  const {
    register,
    setValue,
    getValues,
    type,
    control,
    onSubmit,
    handleSubmit,
    errors,
    users,
    studies,
    question,
  } = props
  const [state] = useContext(StateContext)
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "Choice",
    defaultValue: getValues("choice"),
    onChange: (e) => setValue("choice", parseInt(e)),
  })
  const group = getRootProps()
  const questionLevels = Object.values(QUESTION_LEVEL)

  if (type === "create") {
    const studyId = useQuery().get("studyId") ? useQuery().get("studyId") : getValues("studyId")
    setValue("userId", state.currentUser.id)
    setValue("studyId", studyId)
  }

  return (
    <Flex direction="column">
      <Box bg="white" mt="4" px={[4, 6, 8]} py={6}>
        <Flex direction="column" justify="space-between">
          <Text color="gray.750" fontSize="md" fontWeight="medium">
            Informasi Soal
          </Text>
          <Stack mt="6" spacing="6" w={["full", "80%", "50%"]} alignItems={["none", "center"]}>
            <FormControl>
              <Flex direction={["column", "row"]}>
                <FormLabel w={["full", 1 / 3]} fontSize="sm" color="gray.550">
                  Pelajaran
                </FormLabel>
                <Flex w={["full", 2 / 3]} direction="column">
                  <Select placeholder="Pilih Pelajaran" name="studyId" {...register("studyId")}>
                    {studies.data.map((study) => (
                      <option key={study.id} value={study.id}>
                        {study.name}
                      </option>
                    ))}
                  </Select>
                  <Text fontSize="sm" color="red.500">
                    {errors.studyId?.message}
                  </Text>
                </Flex>
              </Flex>
            </FormControl>
            <FormControl>
              <Flex direction={["column", "row"]}>
                <FormLabel w={["full", 1 / 3]} fontSize="sm" color="gray.550">
                  Level
                </FormLabel>
                <Flex w={["full", 2 / 3]} direction="column">
                  <Select placeholder="Pilih Level" defaultValue="medium" {...register("level")}>
                    {questionLevels.map((item, index) => (
                      <option key={index} value={item.name}>
                        {item.display}
                      </option>
                    ))}
                  </Select>
                  <Text fontSize="sm" color="red.500">
                    {errors.level?.message}
                  </Text>
                </Flex>
              </Flex>
            </FormControl>
            <FormControl>
              <Flex direction={["column", "row"]}>
                <FormLabel w={["full", 1 / 3]} fontSize="sm" color="gray.550">
                  Author
                </FormLabel>
                <Flex w={["full", 2 / 3]} direction="column">
                  <Select placeholder="Pilih Author" {...register("userId")} defaultValue={getValues("userId")}>
                    {users.data.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.name}
                      </option>
                    ))}
                  </Select>
                  <Text fontSize="sm" color="red.500">
                    {errors.userId?.message}
                  </Text>
                </Flex>
              </Flex>
            </FormControl>
          </Stack>
        </Flex>
      </Box>
      <Box bg="white" mt="4" px={[4, 6, 8]} py={6}>
        <Flex direction="column" justify="space-between">
          <Text color="gray.750" fontSize="md" fontWeight="medium">
            Pertanyaan
          </Text>
          <Stack mt="4">
            <Controller
              name="question"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextEditor height="500" onChange={onChange} value={value} folder={FOLDER.QUESTION} />
              )}
            />
            <Text fontSize="sm" color="red.500">
              {errors.question?.message}
            </Text>
          </Stack>
        </Flex>
      </Box>
      <Box bg="white" mt="4" px={[4, 6, 8]} py={6}>
        <Flex direction="column" justify="space-between">
          <Text color="gray.750" fontSize="md" fontWeight="medium">
            Pilihan Jawaban
          </Text>
          {errors.choice && (
            <Text fontSize="sm" mt="3" color="red.500">
              {errors.choice.message}
            </Text>
          )}
          <VStack {...group} direction="column" mt="4">
            {[...Array(5)].map((e, index) => (
              <Stack key={index} direction="row" spacing={["4", "8"]} w="full">
                <Stack mt="2">
                  <RadioCard {...getRadioProps({ value: index.toString() })}>
                    <Text textTransform="capitalize" fontSize="sm">
                      {String.fromCharCode(97 + index)}
                    </Text>
                  </RadioCard>
                </Stack>
                <Stack w="full" overflowX="auto">
                  <Controller
                    name={`choices[${index}]`}
                    control={control}
                    defaultValue={type === "update" ? getValues(`choices[${index}]`) : ""}
                    render={({ field: { onChange, value } }) => (
                      <TextEditor onChange={onChange} value={value} folder={FOLDER.CHOICE} />
                    )}
                  />
                  <Text fontSize="sm" color="red.500">
                    {errors.choices?.message}
                  </Text>
                </Stack>
              </Stack>
            ))}
          </VStack>
        </Flex>
      </Box>
      <Box bg="white" mt="4" px={[4, 6, 8]} py={6}>
        <Flex direction="column" justify="space-between">
          <Text color="gray.750" fontSize="md" fontWeight="medium">
            Pembahasan
          </Text>
          <Stack mt="4">
            <Controller
              name="discussion"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextEditor height="500" onChange={onChange} value={value} folder={FOLDER.CORRECTION} />
              )}
            />
            <Text fontSize="sm" color="red.500">
              {errors.discussion?.message}
            </Text>
          </Stack>
        </Flex>
      </Box>
      <Flex mt="6" mb="8" justify="flex-end">
        <Stack w="sm" direction="row" spacing="4">
          <Button w="full" variant="outline" onClick={() => navigate("/question")}>
            Batal
          </Button>
          <Button
            w="full"
            onClick={handleSubmit(onSubmit)}
            isLoading={question.isLoading}
            isDisabled={question.isLoading}
          >
            Submit
          </Button>
        </Stack>
      </Flex>
    </Flex>
  )
}

export default Form
