import { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { joiResolver } from "@hookform/resolvers/joi"
import Joi from "joi"
import {
  Flex,
  Button,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Text,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Icon,
  HStack,
  Skeleton,
  InputGroup,
  InputRightElement,
  Alert,
  AlertIcon,
  Box,
  Switch,
  Checkbox,
} from "@chakra-ui/react"
import { PlusIcon, ChevronDownIcon } from "@heroicons/react/solid"
import { PencilIcon, TrashIcon, SearchIcon, DesktopComputerIcon } from "@heroicons/react/outline"
import {
  deletePackageByPackageId,
  getPackages,
  getPackageByPackageId,
  storePackage,
  updatePackageByPackageId,
  storeFaciltiesByPackageId,
} from "../../../../api/package"
import { getFacilities } from "../../../../api/facility"
import { MODAL_TYPE } from "../../../../constants/modal"
import { DeleteAlert } from "../../../../components/Alert"

const schema = Joi.object({
  id: Joi.string().allow(""),
  name: Joi.string().required(),
  isFree: Joi.boolean().required(),
})

export default function Facility() {
  const [changeState, setChangeState] = useState(false)
  const [packages, setPackages] = useState({ data: [], error: null, isLoading: false })
  const [facilities, setFacilities] = useState({ data: [], error: null, isLoading: false })
  const [packageTo, setPackage] = useState({
    isLoading: false,
    error: null,
  })
  const [formModal, setFormModal] = useState({
    type: MODAL_TYPE.STORE,
    isOpen: false,
    isLoading: false,
    error: null,
  })
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    packageId: "",
    error: null,
    isLoading: false,
  })
  const [facilityModal, setFacilityModal] = useState({
    isOpen: false,
    packageId: "",
    error: null,
    isLoading: false,
  })
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
  } = useForm({ resolver: joiResolver(schema), defaultValues: { isFree: false } })

  useEffect(() => {
    const GetPackages = async () => {
      setPackages({ ...packages, isLoading: true })
      const result = await getPackages()
      if (result.success === true) {
        setPackages({ ...packages, data: result.data.packages, isLoading: false })
      } else {
        setPackages({ ...packages, error: result.error.message, isLoading: false })
      }
    }
    GetPackages()
    return () => {
      setPackages({ data: [], error: null, isLoading: false })
    }
  }, [changeState])

  const submitStorePackage = async (data) => {
    setFormModal({ ...formModal, isLoading: true })
    const result = await storePackage(data)
    if (result.success === true) {
      setFormModal({ ...formModal, isLoading: false })
      closeModal()
      setChangeState(!changeState)
    } else {
      setFormModal({ ...formModal, isLoading: false, error: result.error.message })
    }
  }
  const submitUpdatePackage = async (data) => {
    setFormModal({ ...formModal, isLoading: true })
    const result = await updatePackageByPackageId(data)
    if (result.success === true) {
      setFormModal({ ...formModal, isLoading: false })
      closeModal()
      setChangeState(!changeState)
    } else {
      setFormModal({ ...formModal, isLoading: false, error: result.error.message })
    }
  }
  const submitDeletePackage = async (id) => {
    setDeleteModal({ ...deleteModal, isLoading: true })
    const result = await deletePackageByPackageId(id)
    if (result.success === true) {
      setDeleteModal({ ...deleteModal, isOpen: false, isLoading: false })
      setChangeState(!changeState)
    } else {
      setDeleteModal({ ...deleteModal, error: "Paket gagal dihapus", isLoading: false })
    }
  }
  const closeModal = () => {
    setValue("id", "")
    setValue("name", "")
    setValue("isFree", false)
    setFormModal({ ...formModal, isOpen: false, error: null })
  }

  const openModal = async (packageId = "", type = MODAL_TYPE.STORE) => {
    setFormModal({ ...formModal, type, isOpen: true })
    if (type === MODAL_TYPE.UPDATE) {
      setPackage({ ...packageTo, isLoading: true })
    }
    if (type === MODAL_TYPE.UPDATE) {
      const result = await getPackageByPackageId(packageId)
      if (result.success) {
        const { id, name, isFree } = result.data
        setValue("id", id)
        setValue("name", name)
        setValue("isFree", isFree)
        setPackage({ ...packageTo, isLoading: false })
      }
    }
  }
  const openDeleteModal = (packageId) => {
    setDeleteModal({ ...deleteModal, packageId, isOpen: true })
  }
  const openFacilityModal = async (packageId = "") => {
    setFacilityModal({ ...facilityModal, isOpen: true, packageId })
    setFacilities({ ...facilities, isLoading: true })
    const resultFacilities = await getFacilities()
    if (resultFacilities.success) {
      let newFacilities = resultFacilities.data.facilities.map((facility) => ({
        id: facility.id,
        name: facility.name,
        isChecked: false,
      }))
      const resultPackage = await getPackageByPackageId(packageId)
      if (resultPackage.success) {
        let currentFacilities = newFacilities
        resultPackage.data.facilities.forEach((facility) => {
          currentFacilities.forEach((newFacility) => {
            if (facility.id === newFacility.id) {
              newFacility.isChecked = true
            }
          })
        })
        setFacilities({ ...facilities, data: currentFacilities, isLoading: false })
      }
    }
  }

  const storeFacility = async () => {
    const selectedFacilities = facilities.data.filter((facility) => facility.isChecked).map((facility) => facility.id)
    setFacilityModal({ ...facilityModal, isLoading: true })
    const result = await storeFaciltiesByPackageId({
      id: facilityModal.packageId,
      facilities: selectedFacilities,
    })
    if (result.success) {
      setFacilityModal({ isOpen: false, isLoading: false })
    } else {
      setFacilityModal({ ...facilityModal, error: result.error.message, isLoading: false })
    }
  }

  const closeFacilityModal = () => {
    setFacilityModal({ ...facilityModal, packageId: "", error: "", isOpen: !facilityModal.isOpen })
  }

  return (
    <Flex direction="column" minH="70vh">
      <Flex py="8" justify="space-between" spacing={16} direction={["column", "row"]}>
        <FormControl width={["full", "xs"]}>
          <InputGroup>
            <Input type="text" placeholder="Search" />
            <InputRightElement>
              <Button variant="ghost" _focus={{ boxShadow: "none" }}>
                <Icon as={SearchIcon} color="gray.550" h="4" w="4" />
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <Button onClick={() => openModal()} mt={["2", "0"]}>
          Tambah Paket
          <Icon as={PlusIcon} h="5" w="5" ml="2" />
        </Button>
        <Modal isCentered size="sm" blockScrollOnMount={true} isOpen={formModal.isOpen} onClose={closeModal}>
          <ModalOverlay />
          <ModalContent p="2">
            <ModalHeader>
              <Text color="gray.750">{formModal.type === MODAL_TYPE.STORE ? "Tambah" : "Edit"} Paket</Text>
            </ModalHeader>
            <ModalCloseButton _focus={{ boxShadow: "none" }} />
            <Box px={6}>
              {formModal.error && (
                <Alert size="sm" status="error">
                  <AlertIcon />
                  Paket gagal di{formModal.type === MODAL_TYPE.STORE ? "tambahkan" : "edit"}
                </Alert>
              )}
            </Box>
            <ModalBody my="3">
              <form
                onSubmit={handleSubmit(formModal.type === MODAL_TYPE.STORE ? submitStorePackage : submitUpdatePackage)}
              >
                {packageTo.isLoading && (
                  <Stack spacing="4">
                    <Skeleton startColor="gray.100" endColor="gray.200" height="40px" />
                    <Skeleton startColor="gray.100" endColor="gray.200" height="40px" />
                  </Stack>
                )}
                {!packageTo.isLoading && (
                  <Stack spacing="3">
                    <FormControl>
                      <FormLabel fontSize="sm" color="gray.550">
                        Nama
                      </FormLabel>
                      <Input type="text" {...register("name")} isInvalid={errors.name} />
                      <Text fontSize="sm" color="red.500">
                        {errors.name?.message}
                      </Text>
                    </FormControl>
                    <FormControl>
                      <FormLabel fontSize="sm" color="gray.550">
                        Gratis
                      </FormLabel>
                      <Switch
                        size="lg"
                        onChange={(e) => setValue("isFree", e.target.value)}
                        {...register("isFree")}
                        defaultChecked={getValues("isFree")}
                      />
                      <Text fontSize="sm" color="red.500">
                        {errors.isFree?.message}
                      </Text>
                    </FormControl>
                  </Stack>
                )}
              </form>
            </ModalBody>
            <ModalFooter>
              <Button mr={3} onClick={closeModal} variant="outline" isDisabled={formModal.isLoading}>
                Batal
              </Button>
              <Button
                onClick={handleSubmit(formModal.type === MODAL_TYPE.STORE ? submitStorePackage : submitUpdatePackage)}
                isLoading={formModal.isLoading}
                type="submit"
              >
                Simpan
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
      <Flex overflowX="auto" overflowY="hidden" direction="column">
        {packages.isLoading ? (
          <Stack spacing={6}>
            {[...Array(6)].map((e, i) => (
              <Stack key={i}>
                <Skeleton startColor="gray.100" endColor="gray.200" height="30px" />
              </Stack>
            ))}
          </Stack>
        ) : (
          <Table variant="simple" size="md" mb={4}>
            <Thead>
              <Tr color="gray.100" borderTop="1px">
                <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                  NAMA
                </Th>
                <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                  DIBUAT OLEH
                </Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {packages.data.map((packageTo) => (
                <Tr key={packageTo.id} fontSize="sm" color="gray.550">
                  <Td fontWeight="semibold">{packageTo.name}</Td>
                  <Td>Super Admin</Td>
                  <Td>
                    <Menu>
                      <MenuButton
                        px={4}
                        py={2}
                        transition="all 0.2s"
                        borderRadius="md"
                        borderWidth="1px"
                        _focus={{ boxShadow: "none" }}
                        fontWeight="semibold"
                      >
                        Atur
                        <Icon ml="2" as={ChevronDownIcon} h="5" w="5" />
                      </MenuButton>
                      <MenuList>
                        <MenuItem onClick={() => openFacilityModal(packageTo.id)}>
                          <HStack w="full">
                            <Icon as={DesktopComputerIcon} h="4" w="4" />
                            <Text>Atur Fasilitas</Text>
                          </HStack>
                        </MenuItem>
                        <MenuItem onClick={() => openModal(packageTo.id, MODAL_TYPE.UPDATE)}>
                          <HStack w="full">
                            <Icon as={PencilIcon} h="4" w="4" />
                            <Text>Edit</Text>
                          </HStack>
                        </MenuItem>
                        <MenuItem onClick={() => openDeleteModal(packageTo.id)}>
                          <HStack w="full">
                            <Icon as={TrashIcon} h="4" w="4" />
                            <Text>Hapus</Text>
                          </HStack>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              ))}
            </Tbody>
            <Tfoot></Tfoot>
          </Table>
        )}
      </Flex>
      <DeleteAlert
        isOpen={deleteModal.isOpen}
        title="Paket "
        isLoading={deleteModal.isLoading}
        action={() => submitDeletePackage(deleteModal.packageId)}
        setOpen={setDeleteModal}
      />
      <Modal isCentered size="sm" blockScrollOnMount={true} isOpen={facilityModal.isOpen} onClose={closeFacilityModal}>
        <ModalOverlay />
        <ModalContent p="2">
          <ModalHeader>
            <Text color="gray.750">Atur Fasilitas</Text>
          </ModalHeader>
          <ModalCloseButton _focus={{ boxShadow: "none" }} />
          <Box px={6}>
            {facilityModal.error && (
              <Alert size="sm" status="error">
                <AlertIcon />
                Fasilitas gagal di tambahkan
              </Alert>
            )}
          </Box>
          <ModalBody my="3">
            <form>
              {!facilities.isLoading && (
                <Stack spacing="4">
                  <FormControl>
                    <FormLabel fontSize="sm" color="gray.550">
                      Fasilitas
                    </FormLabel>
                    <Stack spacing="2">
                      {facilities.data.map((facility, index) => (
                        <Checkbox
                          key={index}
                          defaultChecked={facility.isChecked}
                          onChange={(e) =>
                            (facilities.data[index] = {
                              ...facilities.data[index],
                              isChecked: !facilities.data[index].isChecked,
                            })
                          }
                          colorScheme="purple"
                        >
                          <Text fontSize="sm" color="gray.550">
                            {facility.name}
                          </Text>
                        </Checkbox>
                      ))}
                    </Stack>
                  </FormControl>
                </Stack>
              )}
            </form>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={closeFacilityModal} variant="outline" isDisabled={formModal.isLoading}>
              Batal
            </Button>
            <Button isLoading={facilityModal.isLoading} onClick={() => storeFacility()} type="submit">
              Simpan
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  )
}
