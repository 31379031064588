import { Flex, Heading, Text } from "@chakra-ui/react"

const NotFound = () => {
  return (
    <Flex direction="column" justify="center" align="center" minH="90vh">
      <Heading as="h1" size="4xl" color="gray.600">
        404
      </Heading>
      <Text mt="2" fontSize="xl" fontWeight="medium" color="gray.500">
        Page Not Found
      </Text>
    </Flex>
  )
}

export default NotFound
