import { Editor } from "@tinymce/tinymce-react"
import { storeImage } from "../api/image"
const { REACT_APP_TINYMCE_API_KEY } = process.env

function TextEditor(props) {
  const { onChange, value, height = 300, folder = "" } = props
  const handleEditorChange = (editor) => onChange(editor)
  const uploadImageHandler = async (blobInfo, success, failure) => {
    let formData = new FormData()
    formData.append("image", blobInfo.blob(), blobInfo.filename())
    formData.append("folder", folder)
    const result = await storeImage(formData)
    if (result.success) {
      success(result.data.imageUrl)
    } else {
      failure("Failed to upload image")
    }
  }
  return (
    <>
      <Editor
        apiKey={REACT_APP_TINYMCE_API_KEY}
        init={{
          height,
          selector: "textarea",
          plugins: [
            "advlist autolink lists link image charmap preview",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste wordcount",
          ],
          images_upload_handler: uploadImageHandler,
          external_plugins: {
            tiny_mce_wiris: "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
          },
          toolbar:
            "undo redo styleselect bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry",
          htmlAllowedTags: [".*"],
          htmlAllowedAttrs: [".*"],
          draggable_modal: true,
        }}
        value={value}
        onEditorChange={handleEditorChange}
      />
    </>
  )
}

export default TextEditor
