import Axios from "../helpers/axios"

export const getStudyTypes = async () => {
  try {
    const { data: response } = await Axios.get("/study-type")
    return response
  } catch (err) {
    return err.response.data
  }
}

export const storeStudyType = async (data) => {
  try {
    const { name } = data
    const { data: response } = await Axios.post("/study-type", { name })
    return response
  } catch (err) {
    return err.response.data
  }
}

export const getStudyTypeByStudyTypeId = async (id) => {
  try {
    const { data: response } = await Axios.get(`/study-type/${id}`)
    return response
  } catch (err) {
    return err.response.data
  }
}

export const updateStudyTypeByStudyTypeId = async (data) => {
  try {
    const { id, name } = data
    const { data: response } = await Axios.put(`/study-type/${id}`, { name })
    return response
  } catch (err) {
    return err.response.data
  }
}

export const deleteStudyTypeByStudyTypeId = async (id) => {
  try {
    const { data: response } = await Axios.delete(`/study-type/${id}`)
    return response
  } catch (err) {
    return err.response.data
  }
}
