export const USER_ROLE = {
  SUPERADMIN: "superadmin",
  ADMIN: "admin",
  AUTHOR: "author",
  MENTOR: "mentor",
  STUDENT: "student",
}

export const USER_STATUS = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  UNVERIFIED: "unverified",
}
