import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { joiResolver } from "@hookform/resolvers/joi"
import Joi from "joi"
import {
  Flex,
  Button,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Text,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Icon,
  HStack,
  Skeleton,
  InputGroup,
  InputRightElement,
  Alert,
  AlertIcon,
  Box,
} from "@chakra-ui/react"
import { PlusIcon, ChevronDownIcon } from "@heroicons/react/solid"
import { PencilIcon, TrashIcon, SearchIcon } from "@heroicons/react/outline"
import { MODAL_TYPE } from "../../../../constants/modal"
import {
  deleteProductByProductId,
  getProducts,
  getProductByProductId,
  storeProduct,
  updateProductByProductId,
} from "../../../../api/product"
import { DeleteAlert } from "../../../../components/Alert"

const schema = Joi.object({
  id: Joi.string().allow(""),
  name: Joi.string().required(),
})

export default function Product() {
  const [changeState, setChangeState] = useState(false)
  const [stateDeleteModal, setStateDeleteModal] = useState({
    productId: null,
    isOpen: false,
    error: null,
    isLoading: false,
  })
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ resolver: joiResolver(schema) })
  const [state, setState] = useState({ products: [], error: null, isLoading: false })
  const [stateModal, setStateModal] = useState({ type: MODAL_TYPE.STORE, error: null, isLoading: false })

  useEffect(() => {
    const GetProducts = async () => {
      setState({ ...state, isLoading: true })
      const result = await getProducts()
      if (result.success === true) {
        setState({ ...state, products: result.data.products, isLoading: false })
      } else {
        setState({ ...state, error: result.error.message, isLoading: false })
      }
    }
    GetProducts()
    return () => {
      setState({ products: [], error: null, isLoading: false })
    }
  }, [changeState])

  const submitStoreProduct = async (data) => {
    setStateModal({ ...stateModal, isLoading: true })
    const result = await storeProduct(data)
    if (result.success === true) {
      setStateModal({ ...stateModal, isLoading: false })
      closeModal()
      setChangeState(!changeState)
    } else {
      setStateModal({ ...stateModal, isLoading: false, error: result.error.message })
    }
  }
  const submitUpdateProduct = async (data) => {
    setStateModal({ ...stateModal, isLoading: true })
    const result = await updateProductByProductId(data)
    if (result.success === true) {
      setStateModal({ ...stateModal, isLoading: false })
      closeModal()
      setChangeState(!changeState)
    } else {
      setStateModal({ ...stateModal, isLoading: false, error: result.error.message })
    }
  }
  const submitDeleteProduct = async (id) => {
    setStateDeleteModal({ ...stateDeleteModal, isLoading: true })
    const result = await deleteProductByProductId(id)
    if (result.success === true) {
      setStateDeleteModal({ ...stateDeleteModal, isOpen: false, isLoading: false })
      setChangeState(!changeState)
    } else {
      setStateDeleteModal({ ...stateDeleteModal, error: "Produk gagal dihapus", isLoading: false })
    }
  }
  const closeModal = () => {
    onClose()
    setValue("name", "")
    setStateModal({ ...stateModal, error: null })
  }
  const openModal = async (productId = "", type = MODAL_TYPE.STORE) => {
    setStateModal({ ...stateModal, type })
    onOpen()
    if (type === MODAL_TYPE.UPDATE) {
      const result = await getProductByProductId(productId)
      if (result.success === true) {
        const { id, name } = result.data
        setValue("id", id)
        setValue("name", name)
      }
    }
  }
  const openDeleteModal = (id) => {
    setStateDeleteModal({ ...stateDeleteModal, productId: id, isOpen: true })
  }

  return (
    <Flex direction="column" minH="70vh">
      <Flex py="8" justify="space-between" spacing={16} direction={["column", "row"]}>
        <FormControl width={["full", "xs"]}>
          <InputGroup>
            <Input type="text" placeholder="Search" />
            <InputRightElement>
              <Button variant="ghost" _focus={{ boxShadow: "none" }}>
                <Icon as={SearchIcon} color="gray.550" h="4" w="4" />
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <Button onClick={openModal} mt={["2", "0"]}>
          Tambah Produk
          <Icon as={PlusIcon} h="5" w="5" ml="2" />
        </Button>
        <Modal isCentered size="sm" blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent p="2">
            <ModalHeader>
              <Text color="gray.750">{stateModal.type === MODAL_TYPE.STORE ? "Tambah" : "Edit"} Produk</Text>
            </ModalHeader>
            <ModalCloseButton _focus={{ boxShadow: "none" }} />
            <Box px={6}>
              {stateModal.error && (
                <Alert size="sm" status="error">
                  <AlertIcon />
                  Produk gagal di{stateModal.type === MODAL_TYPE.STORE ? "tambahkan" : "edit"}
                </Alert>
              )}
            </Box>
            <ModalBody my="3">
              <form
                onSubmit={handleSubmit(stateModal.type === MODAL_TYPE.STORE ? submitStoreProduct : submitUpdateProduct)}
              >
                <Stack spacing="3">
                  <FormControl>
                    <FormLabel fontSize="sm" color="gray.550">
                      Nama
                    </FormLabel>
                    <Input type="text" {...register("name")} isInvalid={errors.name} />
                    <Text fontSize="sm" color="red.500">
                      {errors.name?.message}
                    </Text>
                  </FormControl>
                </Stack>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button mr={3} onClick={closeModal} variant="outline" isDisabled={stateModal.isLoading}>
                Batal
              </Button>
              <Button
                onClick={handleSubmit(stateModal.type === MODAL_TYPE.STORE ? submitStoreProduct : submitUpdateProduct)}
                isLoading={stateModal.isLoading}
                type="submit"
              >
                Simpan
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
      <Flex overflowX="auto" overflowY="hidden" direction="column">
        {state.isLoading ? (
          <Stack spacing={6}>
            {[...Array(6)].map((e, i) => (
              <Stack key={i}>
                <Skeleton startColor="gray.100" endColor="gray.200" height="30px" />
              </Stack>
            ))}
          </Stack>
        ) : (
          <Table variant="simple" size="md" mb={4}>
            <Thead>
              <Tr color="gray.100" borderTop="1px">
                <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                  NAMA
                </Th>
                <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                  DIBUAT OLEH
                </Th>
                <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                  KATEGORI
                </Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {state.products.map((product) => (
                <Tr key={product.id} fontSize="sm" color="gray.550">
                  <Td fontWeight="semibold">{product.name}</Td>
                  <Td>Super Admin</Td>
                  <Td>{product.totalCategory}</Td>
                  <Td>
                    <Menu>
                      <MenuButton
                        px={4}
                        py={2}
                        transition="all 0.2s"
                        borderRadius="md"
                        borderWidth="1px"
                        _focus={{ boxShadow: "none" }}
                        fontWeight="semibold"
                      >
                        Atur
                        <Icon ml="2" as={ChevronDownIcon} h="5" w="5" />
                      </MenuButton>
                      <MenuList>
                        <MenuItem>
                          <HStack onClick={() => openModal(product.id, MODAL_TYPE.UPDATE)} w="full">
                            <Icon as={PencilIcon} h="4" w="4" />
                            <Text>Edit</Text>
                          </HStack>
                        </MenuItem>
                        <MenuItem>
                          <HStack onClick={() => openDeleteModal(product.id)} w="full">
                            <Icon as={TrashIcon} h="4" w="4" />
                            <Text>Hapus</Text>
                          </HStack>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              ))}
            </Tbody>
            <Tfoot></Tfoot>
          </Table>
        )}
      </Flex>
      <DeleteAlert
        isOpen={stateDeleteModal.isOpen}
        title="Produk"
        isLoading={stateDeleteModal.isLoading}
        action={() => submitDeleteProduct(stateDeleteModal.productId)}
        setOpen={setStateDeleteModal}
      />
    </Flex>
  )
}
