import {
  Flex,
  Stack,
  Box,
  SimpleGrid,
  Text,
  Button,
  FormControl,
  Select,
  Input,
  InputGroup,
  InputRightElement,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tfoot,
  Icon,
  Skeleton,
} from "@chakra-ui/react"
import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { SearchIcon } from "@heroicons/react/outline"
import { convertToRupiah } from "../../helpers/rupiah"
import { getFinances, getFinanceStatistics } from "../../api/finance"

function Statistic(props) {
  const initStatistics = {
    totalPayments: 0,
    totalIncome: 0,
    weekIncome: 0,
    monthIncome: 0,
  }
  const [statistics, setStatistics] = useState({ data: { ...initStatistics }, isLoading: false, error: null })

  useState(() => {
    const GetStatistics = async () => {
      setStatistics({ ...statistics, isLoading: true })
      const result = await getFinanceStatistics()
      if (result.success) {
        setStatistics({ ...statistics, data: { ...result.data }, isLoading: false })
      }
    }
    GetStatistics()
    return () => {
      setStatistics({ data: { ...initStatistics }, isLoading: false, error: null })
    }
  }, [])

  return (
    <SimpleGrid columns={["1", "2", "2", "4"]} spacing={["4"]} {...props}>
      <Box w="full" minH="28" boxShadow="md" bg="white" p="5">
        <Stack direction="column" spacing="3">
          <Text fontSize="sm" color="gray.600" fontWeight="semibold">
            Semua Pendapatan
          </Text>
          {statistics.isLoading && (
            <Stack mt="">
              <Skeleton startColor="gray.100" endColor="gray.300" height="25px" />
            </Stack>
          )}
          {!statistics.isLoading && (
            <Text fontSize="xl" fontWeight="bold" color="purple.450" lineHeight="4">
              {convertToRupiah(statistics.data.totalIncome)}
            </Text>
          )}
        </Stack>
      </Box>
      <Box w="full" minH="28" boxShadow="md" bg="white" p="5">
        <Stack direction="column" spacing="3">
          <Text fontSize="sm" color="gray.600" fontWeight="semibold">
            Pendapatan Bulan Ini
          </Text>
          {statistics.isLoading && (
            <Stack mt="">
              <Skeleton startColor="gray.100" endColor="gray.300" height="25px" />
            </Stack>
          )}
          {!statistics.isLoading && (
            <Text fontSize="xl" fontWeight="bold" color="purple.450" lineHeight="4">
              {convertToRupiah(statistics.data.monthIncome)}
            </Text>
          )}
        </Stack>
      </Box>
      <Box w="full" minH="28" boxShadow="md" bg="white" p="5">
        <Stack direction="column" spacing="3">
          <Text fontSize="sm" color="gray.600" fontWeight="semibold">
            Pendapatan Minggu Ini
          </Text>
          {statistics.isLoading && (
            <Stack mt="">
              <Skeleton startColor="gray.100" endColor="gray.300" height="25px" />
            </Stack>
          )}
          {!statistics.isLoading && (
            <Text fontSize="xl" fontWeight="bold" color="purple.450" lineHeight="4">
              {convertToRupiah(statistics.data.weekIncome)}
            </Text>
          )}
        </Stack>
      </Box>
      <Box w="full" minH="28" boxShadow="md" bg="white" p="5">
        <Stack direction="column" spacing="3">
          <Text fontSize="sm" color="gray.600" fontWeight="semibold">
            Semua Transaksi
          </Text>
          {statistics.isLoading && (
            <Stack mt="">
              <Skeleton startColor="gray.100" endColor="gray.300" height="25px" />
            </Stack>
          )}
          {!statistics.isLoading && (
            <Text fontSize="xl" fontWeight="bold" color="purple.450" lineHeight="4">
              {statistics.data.totalPayments}
            </Text>
          )}
        </Stack>
      </Box>
    </SimpleGrid>
  )
}

function ListFinance(props) {
  const shows = [10, 20, 50]
  const [contents, setContents] = useState({ data: [], isLoading: false, error: null })
  const [pagination, setPagination] = useState({ totalItems: 0, totalPages: 0, currentPage: 0 })
  const [query, setQuery] = useState({
    page: 0,
    show: 10,
    search: "",
  })

  useEffect(() => {
    const GetContents = async () => {
      setContents({ ...contents, isLoading: true })
      const result = await getFinances(query)
      if (result.success) {
        const { totalItems, totalPages, currentPage } = result.data
        setContents({ ...contents, data: result.data.contents, isLoading: false })
        setPagination({ totalItems, totalPages, currentPage })
      }
    }
    GetContents()

    return () => {
      setContents({ data: [], isLoading: false, error: null })
    }
  }, [query])

  return (
    <Box bg="white" px={[4, 5, 6]} py={8} {...props}>
      <Flex direction="column" minH="60vh">
        <Stack direction="row" justify="space-between" py="4">
          <Flex w={["30%", "10%"]}>
            <FormControl>
              <Select onChange={(e) => setQuery({ ...query, show: e.target.value })}>
                {shows.map((show, index) => (
                  <option value={show} key={index}>
                    {show}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Flex>
          <FormControl width={["70%", "xs"]}>
            <InputGroup>
              <Input
                type="text"
                placeholder="Search"
                onChange={(e) => {
                  if (e.target.value.length === 0 || e.target.value.length >= 3) {
                    return setQuery({ ...query, search: e.target.value })
                  }
                }}
              />
              <InputRightElement>
                <Button variant="ghost" _focus={{ boxShadow: "none" }}>
                  <Icon as={SearchIcon} color="gray.550" h="4" w="4" />
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>
        </Stack>
        <Flex overflowX="auto" overflowY="hidden" direction="column" mt="4">
          {contents.isLoading && (
            <Stack spacing={6}>
              {[...Array(8)].map((e, i) => (
                <Stack key={i}>
                  <Skeleton startColor="gray.100" endColor="gray.300" height="30px" />
                </Stack>
              ))}
            </Stack>
          )}
          {!contents.isLoading && (
            <Table variant="simple" size="md" mb={4}>
              <Thead>
                <Tr color="gray.100" borderTop="1px">
                  <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                    KELAS
                  </Th>
                  <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                    KELOMPOK
                  </Th>
                  <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                    TRANSAKSI
                  </Th>
                  <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                    PENDAPATAN
                  </Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {contents.data.map((content, index) => (
                  <Tr key={index} fontSize="sm" color="gray.550">
                    <Td fontWeight="semibold">{content.name}</Td>
                    <Td textTransform="capitalize">{content.type}</Td>
                    <Td>{content.totalTransaction}</Td>
                    <Td>{convertToRupiah(content.totalIncome)}</Td>
                    <Td minW="36">
                      <Link
                        to={`/finance/${content.contentId}?class=${content.name
                          .replaceAll(" ", "-")
                          .replaceAll("#", "")}`}
                      >
                        <Button
                          size="sm"
                          fontSize="xs"
                          variant="outline"
                          color="purple.450"
                          borderColor="purple.450"
                          px="4"
                        >
                          Detail
                        </Button>
                      </Link>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
              <Tfoot></Tfoot>
            </Table>
          )}
        </Flex>
      </Flex>
      <Stack spacing="2" direction={["column", "row"]} justify="space-between" mt="4">
        <Text fontSize="sm" color="gray.550">
          Menampilkan 1 sampai {query.show} dari {pagination.totalItems} data
        </Text>
        {pagination.totalItems > query.show && (
          <Stack direction="row" spacing="1">
            {pagination.currentPage > 0 && (
              <Button variant="outline" onClick={() => setQuery({ ...query, page: query.page - 1 })}>
                Sebelumnya
              </Button>
            )}
            {[...Array(pagination.totalPages)].map((e, i) => (
              <Button
                variant={i === pagination.currentPage ? "solid" : "outline"}
                onClick={() => setQuery({ ...query, page: i })}
                key={i}
              >
                {i + 1}
              </Button>
            ))}
            {pagination.currentPage < pagination.totalPages - 1 && (
              <Button variant="outline" onClick={() => setQuery({ ...query, page: query.page + 1 })}>
                Berikutnya
              </Button>
            )}
          </Stack>
        )}
      </Stack>
    </Box>
  )
}

function Finance() {
  return (
    <Flex direction="column">
      <Text fontSize="2xl" fontWeight="medium" color="gray.700">
        Keuangan
      </Text>
      <Statistic mt="4" />
      <ListFinance mt="4" />
    </Flex>
  )
}

export default Finance
