import {
  Flex,
  Box,
  Text,
  Icon,
  FormControl,
  Select,
  InputGroup,
  Input,
  InputRightElement,
  Button,
  Stack,
  Skeleton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tfoot,
  HStack,
} from "@chakra-ui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"
import { SearchIcon, EyeIcon } from "@heroicons/react/outline"
import { useEffect, useState, useCallback, memo } from "react"
import { utcToLocal } from "../../helpers/date"
import { getUsers, getUsersWithoutPagination } from "../../api/user"
import { Link } from "react-router-dom"
import { jsonToSheet } from "../../helpers/parserFile"
import { Pagination } from "../../components"

function List() {
  const shows = [10, 20, 50]
  const [users, setUsers] = useState({ data: [], isLoading: false, error: null })
  const [exportUsers, setExportUsers] = useState({ isLoading: false, error: null })
  const [pagination, setPagination] = useState({ totalItems: 0, totalPages: 0, currentPage: 0 })
  const [query, setQuery] = useState({ page: 0, show: 10, search: "", role: "student" })

  useEffect(() => {
    const GetUsers = async () => {
      setUsers({ ...users, isLoading: true })
      const result = await getUsers(query)
      if (result.success) {
        const { users, totalItems, totalPages, currentPage } = result.data
        setUsers({ ...users, data: users, isLoading: false })
        setPagination({ totalItems, totalPages, currentPage })
      }
    }
    GetUsers()
    return () => {
      setUsers({ data: [], isLoading: false, error: null })
    }
  }, [query])

  const exportUsersToSheets = async () => {
    setExportUsers({ ...exportUsers, isLoading: true })
    const result = await getUsersWithoutPagination(query)
    if (result.success) {
      let { users: usersWithoutPagination } = result.data
      usersWithoutPagination = usersWithoutPagination.map((item, idx) => ({ no: idx + 1, ...item }))
      jsonToSheet(usersWithoutPagination, "member")
      setExportUsers({ ...exportUsers, isLoading: false })
    }
  }

  const handleChange = useCallback((e) => setQuery({ ...query, page: e.selected }), [])

  return (
    <Flex direction="column">
      <Text color="gray.750" fontSize="2xl" fontWeight="medium">
        Member
      </Text>
      <Box bg="white" mt="4" px={[4, 5, 6]} py={8}>
        <Flex direction="column" minH="70vh">
          <Flex direction={["column", "column", "row"]} justify="space-between" py="4">
            <Flex w={["full", "xs"]}>
              <FormControl w="30%">
                <Select onChange={(e) => setQuery({ ...query, show: e.target.value })}>
                  {shows.map((show, index) => (
                    <option value={show} key={index}>
                      {show}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Flex>
            <Flex w={["full", "sm"]} mt={[2, 2, 0]}>
              <FormControl w={["65%", "xs"]}>
                <InputGroup>
                  <Input
                    type="text"
                    placeholder="Search"
                    onChange={(e) => {
                      if (e.target.value.length === 0 || e.target.value.length >= 3) {
                        return setQuery({ ...query, search: e.target.value })
                      }
                    }}
                  />
                  <InputRightElement>
                    <Button variant="ghost" _focus={{ boxShadow: "none" }}>
                      <Icon as={SearchIcon} color="gray.550" h="4" w="4" />
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Button
                w="35%"
                ml="2"
                onClick={exportUsersToSheets}
                isLoading={exportUsers.isLoading}
                isDisabled={exportUsers.isLoading}
              >
                Unduh Data
              </Button>
            </Flex>
          </Flex>
          <Flex overflowX="auto" overflowY="hidden" direction="column" mt="4">
            {users.isLoading && (
              <Stack spacing={6}>
                {[...Array(8)].map((e, i) => (
                  <Stack key={i}>
                    <Skeleton startColor="gray.100" endColor="gray.200" height="30px" />
                  </Stack>
                ))}
              </Stack>
            )}
            {!users.isLoading && (
              <Table variant="simple" size="md" mb={4}>
                <Thead>
                  <Tr color="gray.100" borderTop="1px">
                    <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                      CREATED AT
                    </Th>
                    <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                      NAMA
                    </Th>
                    <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                      EMAIL
                    </Th>
                    <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                      TELEPHONE
                    </Th>
                    <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                      KELAS
                    </Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {users.data.map((user, index) => (
                    <Tr key={index} fontSize="sm" color="gray.550">
                      <Td>{utcToLocal(user.createdAt, "DD MMMM YYYY HH:MM")}</Td>
                      <Td>{user.name}</Td>
                      <Td>{user.email}</Td>
                      <Td>{user.phoneNumber}</Td>
                      <Td>{user.totalClass}</Td>
                      <Td>
                        <Menu>
                          <MenuButton
                            px={4}
                            py={2}
                            transition="all 0.2s"
                            borderRadius="md"
                            borderWidth="1px"
                            _focus={{ boxShadow: "none" }}
                            fontWeight="semibold"
                          >
                            Atur
                            <Icon ml="2" as={ChevronDownIcon} h="5" w="5" />
                          </MenuButton>
                          <MenuList>
                            <Link to={`/member/${user.id}?page=account`}>
                              <MenuItem>
                                <HStack w="full">
                                  <Icon as={EyeIcon} h="4" w="4" />
                                  <Text>Lihat</Text>
                                </HStack>
                              </MenuItem>
                            </Link>
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
                <Tfoot></Tfoot>
              </Table>
            )}
          </Flex>
        </Flex>
        <Stack spacing="4" direction={["column", "row"]} justify="space-between" mt="4">
          <Text fontSize="sm" color="gray.550">
            Menampilkan 1 sampai {query.show} dari {pagination.totalItems} data
          </Text>
          <Pagination handleChange={handleChange} totalPages={pagination.totalPages} />
        </Stack>
      </Box>
    </Flex>
  )
}

export default List
