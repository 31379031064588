import React from "react"
import { StateProvider } from "./store"
import Router from "./router"

function App() {
  return (
    <StateProvider>
      <Router />
    </StateProvider>
  )
}

export default App
