export const QUESTION_LEVEL = {
  EASY: {
    name: "easy",
    display: "Mudah",
  },
  MEDIUM: {
    name: "medium",
    display: "Sedang",
  },
  HARD: {
    name: "hard",
    display: "Sukar",
  },
  EXPERT: {
    name: "expert",
    display: "Sukar Sekali",
  },
}
