import { useState, useEffect } from "react"
import {
  Flex,
  Text,
  Box,
  Tabs,
  TabList,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Button,
  Icon,
  FormControl,
  InputGroup,
  Input,
  InputRightElement,
  Select,
  Stack,
  Skeleton,
  InputLeftElement,
} from "@chakra-ui/react"
import { useLocation, Link } from "react-router-dom"
import { PAYMENT_STATUS, PAYMENT_VERIFICATION_METHOD } from "../../constants/payment"
import { PaymentBadge } from "../../components/Badge"
import { getPayments } from "../../api/payment"
import { utcToLocal } from "../../helpers/date"
import { convertToRupiah } from "../../helpers/rupiah"
import { SearchIcon, CalendarIcon } from "@heroicons/react/outline"

const useQuery = () => new URLSearchParams(useLocation().search)

function AutomaticPayments() {
  const shows = [10, 20, 50]
  const [query, setQuery] = useState({
    page: 0,
    show: 10,
    tab: useQuery().get("tab") ? parseInt(useQuery().get("tab")) : 0,
    search: "",
  })
  const menuPayments = [
    { display: "Semua", status: "" },
    ...Object.values(PAYMENT_STATUS).filter(
      (item) => item.status !== "processed" && item.status !== "refund" && item.status !== "failed"
    ),
  ]
  const [payments, setPayments] = useState({ data: [], error: null, isLoading: false })
  const [pagination, setPagination] = useState({ totalItems: 0, totalPages: 0, currentPage: 0 })

  useEffect(() => {
    const GetPayments = async () => {
      setPayments({ ...payments, isLoading: true })
      const result = await getPayments({
        ...query,
        status: menuPayments[query.tab].status,
        verificationMethod: PAYMENT_VERIFICATION_METHOD.AUTOMATIC,
      })
      if (result.success) {
        const { totalItems, totalPages, currentPage } = result.data
        setPayments({ ...payments, data: result.data.payments, isLoading: false })
        setPagination({ totalItems, totalPages, currentPage })
      }
    }
    GetPayments()
    return () => {
      setPayments({ data: [], error: null, isLoading: false })
      setPagination({ totalItems: 0, totalPages: 0, currentPage: 0 })
    }
  }, [query])

  return (
    <Flex direction="column">
      <Text color="gray.750" fontSize="2xl" fontWeight="medium">
        Pembayaran Otomatis
      </Text>
      <Box bg="white" mt="4" px={[4, 5, 6]} py={8}>
        <Flex direction="column" minH="70vh">
          <Flex direction="column" mt={2}>
            <Tabs overflowX="auto">
              <TabList>
                {menuPayments.map((menu, index) => (
                  <Link to={`/transaction/automatic-payment?tab=${index}`} key={index}>
                    <Box
                      p={4}
                      borderBottom={
                        parseInt(useQuery().get("tab")) === index || (useQuery().get("tab") === null && index === 0)
                          ? "2px"
                          : "none"
                      }
                      borderBottomColor={
                        parseInt(useQuery().get("tab")) === index || (useQuery().get("tab") === null && index === 0)
                          ? "purple.450"
                          : ""
                      }
                      position="relative"
                      mb="-2px"
                      onClick={() => setQuery({ ...query, tab: index.toString() })}
                    >
                      <Text
                        color={useQuery().get("tab") === index ? "purple.450" : "gray.750"}
                        fontSize="sm"
                        fontWeight="medium"
                      >
                        {menu.display}
                      </Text>
                    </Box>
                  </Link>
                ))}
              </TabList>
            </Tabs>
            <Stack direction={["column", "row"]} justify="space-between" py={8}>
              <Stack direction="row" w={["full", "xs"]}>
                <FormControl>
                  <Select onChange={(e) => setQuery({ ...query, show: e.target.value })}>
                    {shows.map((show, index) => (
                      <option value={show} key={index}>
                        {show}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl w={["lg", "2xl"]}>
                  <InputGroup>
                    <Input type="date" />
                    <InputLeftElement>
                      <Icon as={CalendarIcon} color="gray.550" h="4" w="4" />
                    </InputLeftElement>
                  </InputGroup>
                </FormControl>
              </Stack>
              <FormControl width={["full", "xs"]}>
                <InputGroup>
                  <Input
                    type="text"
                    placeholder="Search"
                    onChange={(e) => {
                      if (e.target.value.length === 0 || e.target.value.length >= 3) {
                        return setQuery({ ...query, search: e.target.value })
                      }
                    }}
                  />
                  <InputRightElement>
                    <Button variant="ghost" _focus={{ boxShadow: "none" }}>
                      <Icon as={SearchIcon} color="gray.550" h="4" w="4" />
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
            </Stack>
            <Flex overflowX="auto" overflowY="hidden" direction="column">
              {payments.isLoading && (
                <Stack spacing={6}>
                  {[...Array(6)].map((e, i) => (
                    <Stack key={i}>
                      <Skeleton startColor="gray.100" endColor="gray.200" height="30px" />
                    </Stack>
                  ))}
                </Stack>
              )}
              {!payments.isLoading && (
                <Table variant="simple" size="md" mb={4}>
                  <Thead>
                    <Tr color="gray.100" borderTop="1px">
                      <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                        DATE
                      </Th>
                      <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                        NAMA
                      </Th>
                      <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                        EMAIl
                      </Th>
                      <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                        TOTAL
                      </Th>
                      <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                        DITERIMA
                      </Th>
                      <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                        STATUS
                      </Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {payments.data.map((payment, index) => (
                      <Tr key={index} fontSize="sm" color="gray.550">
                        <Td>{utcToLocal(payment.createdAt, "DD MMMM YYYY HH:mm")}</Td>
                        <Td>{payment.userContent.user.name}</Td>
                        <Td>{payment.userContent.user.email}</Td>
                        <Td>{convertToRupiah(payment.amount)}</Td>
                        <Td>{convertToRupiah(payment.xenditInvoice.receivedAmount)}</Td>
                        <Td>
                          <PaymentBadge
                            display={PAYMENT_STATUS[payment.status.toUpperCase()].display}
                            bgColor={PAYMENT_STATUS[payment.status.toUpperCase()].bgColor}
                            textColor={PAYMENT_STATUS[payment.status.toUpperCase()].textColor}
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                  <Tfoot></Tfoot>
                </Table>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Stack spacing="2" direction={["column", "row"]} justify="space-between" mt="4">
          <Text fontSize="sm" color="gray.550">
            Menampilkan 1 sampai {query.show} dari {pagination.totalItems} data
          </Text>
          {pagination.totalItems > query.show && (
            <Stack direction="row" spacing="1">
              {pagination.currentPage > 0 && (
                <Button variant="outline" onClick={() => setQuery({ ...query, page: query.page - 1 })}>
                  Sebelumnya
                </Button>
              )}
              {[...Array(pagination.totalPages)].map((e, i) => (
                <Button
                  variant={i === pagination.currentPage ? "solid" : "outline"}
                  onClick={() => setQuery({ ...query, page: i })}
                  key={i}
                >
                  {i + 1}
                </Button>
              ))}
              {pagination.currentPage < pagination.totalPages - 1 && (
                <Button variant="outline" onClick={() => setQuery({ ...query, page: query.page + 1 })}>
                  Berikutnya
                </Button>
              )}
            </Stack>
          )}
        </Stack>
      </Box>
    </Flex>
  )
}

export default AutomaticPayments
