import {
  Flex,
  Stack,
  FormControl,
  FormLabel,
  Text,
  Select,
  Input,
  Button,
  Center,
  Spinner,
  RadioGroup,
  Radio,
} from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import { CONTENT_TYPE } from "../../../constants/content"

function Form(props) {
  const { type, classBK, errors, register, handleSubmit, onSubmit, content, materials, getValues, setValue } = props
  const contentTypes = ["saintek", "soshum", "general"]
  const navigate = useNavigate()
  return (
    <Flex direction="column" justify="space-between">
      {(classBK.isLoading || materials.isLoading) && (
        <Flex direction="column" bg="white">
          <Center minH="60vh">
            <Spinner thickness="5px" speed="1s" emptyColor="gray.200" color="purple.450" size="lg" />
          </Center>
        </Flex>
      )}
      {!classBK.isLoading && !materials.isLoading && (
        <Stack mt="10" spacing="6" w={["full", "80%", "50%"]} alignItems={["none", "center"]}>
          <FormControl>
            <Flex direction={["column", "row"]}>
              <FormLabel w={["full", 1 / 3]} fontSize="sm" color="gray.550">
                Nama Kelas
              </FormLabel>
              <Flex w={["full", 2 / 3]} direction="column">
                <Text fontSize="sm" color="gray.550">
                  {classBK.data.name}
                </Text>
              </Flex>
            </Flex>
          </FormControl>
          <FormControl>
            <Flex direction={["column", "row"]}>
              <FormLabel w={["full", 1 / 3]} fontSize="sm" color="gray.550">
                Kategori
              </FormLabel>
              <Flex w={["full", 2 / 3]} direction="column">
                <Text fontSize="sm" color="gray.550">
                  {classBK.data.category.name}
                </Text>
              </Flex>
            </Flex>
          </FormControl>
          <FormControl>
            <Flex direction={["column", "row"]}>
              <FormLabel w={["full", 1 / 3]} fontSize="sm" color="gray.550">
                Sub Kategori
              </FormLabel>
              <Flex w={["full", 2 / 3]} direction="column">
                <Text fontSize="sm" color="gray.550">
                  {classBK.data.subCategory?.name}
                </Text>
              </Flex>
            </Flex>
          </FormControl>
          <FormControl>
            <Flex direction={["column", "row"]}>
              <FormLabel w={["full", 1 / 3]} fontSize="sm" color="gray.550">
                Materi
              </FormLabel>
              <Flex w={["full", 2 / 3]} direction="column">
                <Select
                  placeholder="Pilih Materi"
                  {...register("materialId")}
                  isInvalid={errors.materialId}
                  isDisabled={type === "update"}
                >
                  {materials.data.map((material) => (
                    <option key={material.id} value={material.id}>
                      {material.name}
                    </option>
                  ))}
                </Select>
                <Text fontSize="sm" color="red.500">
                  {errors.materialId?.message}
                </Text>
              </Flex>
            </Flex>
          </FormControl>
          <FormControl>
            <Flex direction={["column", "row"]}>
              <FormLabel w={["full", 1 / 3]} fontSize="sm" color="gray.550">
                Kelompok
              </FormLabel>
              <Flex w={["full", 2 / 3]} direction="column">
                <RadioGroup onChange={(e) => setValue("type", e)} defaultValue={getValues("type")}>
                  <Stack direction="row" spacing="6">
                    {contentTypes.map((item, index) => (
                      <Radio
                        colorScheme="purple"
                        value={item}
                        key={index}
                        cursor="pointer"
                        isDisabled={type === "update"}
                      >
                        <Text color="gray.600" textTransform="capitalize" fontSize="sm" cursor="pointer">
                          {CONTENT_TYPE[item].display}
                        </Text>
                      </Radio>
                    ))}
                  </Stack>
                </RadioGroup>
                <Text fontSize="sm" color="red.500">
                  {errors.type?.message}
                </Text>
              </Flex>
            </Flex>
          </FormControl>
          <FormControl>
            <Flex direction={["column", "row"]}>
              <FormLabel w={["full", 1 / 3]} fontSize="sm" color="gray.550">
                Waktu Ujian
              </FormLabel>
              <Flex w={["full", 2 / 3]} direction="column">
                <Stack direction="row" align="center" spacing="4">
                  <Input w="24" type="text" {...register("time")} isInvalid={errors.time} />
                  <Text w="full" fontSize="sm" color="gray.550">
                    Menit
                  </Text>
                </Stack>
                <Text fontSize="sm" color="red.500">
                  {errors.time?.message}
                </Text>
              </Flex>
            </Flex>
          </FormControl>
          <FormControl>
            <Flex direction={["column", "row"]}>
              <FormLabel w={["full", 1 / 3]} fontSize="sm" color="gray.550">
                Pelaksanaan
              </FormLabel>
              <Stack direction="column" spacing="3">
                <Stack direction="row" spacing="4">
                  <Stack w="full" direction="column">
                    <Text fontSize="sm" color="gray.550">
                      Tanggal Mulai
                    </Text>
                    <Input type="date" {...register("startDate")} isInvalid={errors.startDate} />
                  </Stack>
                  <Stack w="full" direction="column">
                    <Text fontSize="sm" color="gray.550">
                      Waktu Mulai
                    </Text>
                    <Input type="time" {...register("startTime")} isInvalid={errors.startTime} />
                  </Stack>
                </Stack>
                <Stack direction="row" spacing="4">
                  <Stack w="full" direction="column">
                    <Text fontSize="sm" color="gray.550">
                      Tanggal Selesai
                    </Text>
                    <Input type="date" {...register("endDate")} isInvalid={errors.endDate} />
                  </Stack>
                  <Stack w="full" direction="column">
                    <Text fontSize="sm" color="gray.550">
                      Waktu Selesai
                    </Text>
                    <Input type="time" {...register("endTime")} isInvalid={errors.endTime} />
                  </Stack>
                </Stack>
              </Stack>
            </Flex>
          </FormControl>
        </Stack>
      )}
      <Flex mt="8" mb="2" justify="flex-end">
        <Stack w="sm" direction="row" spacing="4">
          <Button w="full" variant="outline" onClick={() => navigate(type === "create" ? "/class" : "/content")}>
            Batal
          </Button>
          <Button w="full" isLoading={content.isLoading} onClick={handleSubmit(onSubmit)}>
            Submit
          </Button>
        </Stack>
      </Flex>
    </Flex>
  )
}

export default Form
