import {
  Flex,
  Box,
  Text,
  Icon,
  FormControl,
  Select,
  InputGroup,
  Input,
  InputRightElement,
  Button,
  Stack,
  Skeleton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tfoot,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Center,
  Spinner,
} from "@chakra-ui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"
import { SearchIcon, EyeIcon, TrashIcon } from "@heroicons/react/outline"
import { useEffect, useState, useCallback } from "react"
import { Link, useLocation } from "react-router-dom"
import { deleteQuestionByQuestionId, getQuestionByQuestionId, getQuestions } from "../../api/question"
import { utcToLocal } from "../../helpers/date"
import { DeleteAlert } from "../../components/Alert"
import parse from "html-react-parser"
import { QUESTION_LEVEL } from "../../constants/question"
import { Pagination } from "../../components"

const useQuery = () => new URLSearchParams(useLocation().search)

function PreviewModal(props) {
  const initQuestion = {
    id: "",
    question: "",
    createdAt: "",
    level: "easy",
    study: {
      name: "",
    },
    user: {
      name: "",
    },
    correction: {
      discussion: "",
    },
    choices: [],
  }
  const { previewModal, setPreviewModal } = props
  const [question, setQuestion] = useState({ data: { ...initQuestion }, isLoading: false, error: null })
  const onClose = () => {
    setPreviewModal({ ...previewModal, isOpen: false, questionId: "" })
  }
  useEffect(() => {
    const GetQuestion = async () => {
      setQuestion({ ...question, isLoading: true })
      const result = await getQuestionByQuestionId(previewModal.questionId)
      if (result.success) {
        setQuestion({ ...question, data: result.data, isLoading: false })
      }
    }
    if (previewModal.questionId !== "") {
      GetQuestion()
    }
    return () => {
      setQuestion({ data: { ...initQuestion }, isLoading: false, error: null })
    }
  }, [previewModal.questionId])

  return (
    <Modal size="4xl" isOpen={previewModal.isOpen} onClose={onClose} isCentered="true">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton _focus={{ boxShadow: "none" }} />
        <ModalBody px="6" py="7">
          <Flex direction="column" maxH="80vh">
            <Text color="gray.750" fontWeight="semibold" fontSize="xl">
              Preview Soal
            </Text>
            {question.isLoading && (
              <Center minH="60vh">
                <Spinner thickness="4px" speed="1s" emptyColor="gray.200" color="purple.450" size="lg" />
              </Center>
            )}
            {!question.isLoading && (
              <Stack mt="6" spacing="6" maxH="60vh" overflowY="auto">
                <Stack spacing="3">
                  <Text color="gray.750" fontWeight="medium">
                    Pertanyaan
                  </Text>
                  <Text color="gray.550" as="div">
                    {parse(question.data.question)}
                  </Text>
                  <Stack direction="column" spacing="2">
                    {question.data.choices.map((choice, index) => (
                      <Box
                        key={index}
                        p="2"
                        borderWidth="1px"
                        bg={choice.isCorrect ? "blue.50" : "white"}
                        borderRadius="lg"
                      >
                        <Stack spacing="2" direction="row" color="gray.550">
                          <Flex direction="column">
                            <Text px="4" fontSize="sm" textTransform="capitalize">
                              {String.fromCharCode(97 + index)}
                            </Text>
                          </Flex>
                          <Text as="div">{parse(choice.info)}</Text>
                        </Stack>
                      </Box>
                    ))}
                  </Stack>
                </Stack>
                <Stack spacing="3">
                  <Text color="gray.750" fontWeight="medium">
                    Pembahasan
                  </Text>
                  <Text color="gray.550" as="div">
                    {parse(question.data.correction.discussion)}
                  </Text>
                </Stack>

                <Stack spacing="4">
                  <Text color="gray.750" fontWeight="medium">
                    Informasi Soal
                  </Text>
                  <Stack direction="column" spacing="3">
                    <Stack direction="row" fontSize="sm">
                      <Text w={["30%", "15%"]} color="gray.550" fontWeight="medium">
                        Pelajaran
                      </Text>
                      <Text color="gray.750">{question.data.study.name}</Text>
                    </Stack>
                    <Stack direction="row" fontSize="sm">
                      <Text w={["30%", "15%"]} color="gray.550" fontWeight="medium">
                        Level
                      </Text>
                      <Text color="gray.750">{QUESTION_LEVEL[question.data.level.toUpperCase()].display}</Text>
                    </Stack>
                    <Stack direction="row" fontSize="sm">
                      <Text w={["30%", "15%"]} color="gray.550" fontWeight="medium">
                        Author
                      </Text>
                      <Text color="gray.750">{question.data.user.name}</Text>
                    </Stack>
                    <Stack direction="row" fontSize="sm">
                      <Text w={["30%", "15%"]} color="gray.550" fontWeight="medium">
                        Created
                      </Text>
                      <Text color="gray.750">{utcToLocal(question.data.createdAt, "DD MMMM YYYY HH:mm")}</Text>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            )}
            <Flex justify="flex-end">
              <Stack w={["full", "80%", "40%"]} direction="row" mt="10">
                <Button w="full" variant="outline" mr={3} onClick={onClose}>
                  Close
                </Button>
                <Stack w="full">
                  <Link to={`/question/update/${question.data.id}`}>
                    <Button w="full" isDisabled={question.isLoading}>
                      Edit
                    </Button>
                  </Link>
                </Stack>
              </Stack>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

function ListQuestion() {
  const shows = [10, 20, 50]
  const [questions, setQuestions] = useState({ data: [], isLoading: false, error: null })
  const [pagination, setPagination] = useState({ totalItems: 0, totalPages: 0, currentPage: 0 })
  const [previewModal, setPreviewModal] = useState({ isOpen: false, questionId: "" })
  const [deleteModal, setDeleteModal] = useState({ isOpen: false, isLoading: false, questionId: "" })
  const studyId = useQuery().get("studyId") !== null ? useQuery().get("studyId") : ""
  const [query, setQuery] = useState({
    page: 0,
    show: 10,
    search: "",
  })

  useEffect(() => {
    const GetQuestions = async () => {
      setQuestions({ ...questions, isLoading: true })
      const result = await getQuestions({ ...query, studyId })
      if (result.success) {
        const { totalItems, totalPages, currentPage } = result.data
        setQuestions({ ...questions, data: result.data.questions, isLoading: false })
        setPagination({ totalItems, totalPages, currentPage })
      }
    }
    GetQuestions()
    return () => {
      setQuestions({ data: [], isLoading: false, error: null })
    }
  }, [query, useQuery().get("studyId")])

  const submitDeleteQuestion = async (id) => {
    setDeleteModal({ ...deleteModal, isLoading: true })
    const result = await deleteQuestionByQuestionId(id)
    if (result.success) {
      setDeleteModal({ ...deleteModal, isOpen: false, isLoading: false })
      setQuestions({ ...questions, data: questions.data.filter((question) => question.id !== id) })
    }
  }

  const handlePaginationChange = useCallback((e) => setQuery({ ...query, page: e.selected }))

  return (
    <Flex direction="column">
      <Text color="gray.750" fontSize="2xl" fontWeight="medium">
        Bank Soal
      </Text>
      <Box bg="white" mt="4" px={[4, 5, 6]} py={8}>
        <Flex direction="column" minH="70vh">
          <Stack direction="row" justify="space-between">
            <Text color="gray.750" fontSize="xl" mb="2" fontWeight="medium">
              {useQuery().get("studyId") !== null ? useQuery().get("study").replaceAll("-", " ") : "Semua Soal"}
            </Text>
            <Link
              to={`/question/create?studyId=${useQuery().get("studyId") !== null ? useQuery().get("studyId") : ""}`}
            >
              <Button>Tambah Soal</Button>
            </Link>
          </Stack>
          <Stack direction="row" justify="space-between" mt="2" py="4">
            <Flex w={["30%", "10%"]}>
              <FormControl>
                <Select onChange={(e) => setQuery({ ...query, show: e.target.value })}>
                  {shows.map((show, index) => (
                    <option value={show} key={index}>
                      {show}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Flex>
            <FormControl width={["70%", "xs"]}>
              <InputGroup>
                <Input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => {
                    if (e.target.value.length === 0 || e.target.value.length >= 5) {
                      return setQuery({ ...query, search: e.target.value })
                    }
                  }}
                />
                <InputRightElement>
                  <Button variant="ghost" _focus={{ boxShadow: "none" }}>
                    <Icon as={SearchIcon} color="gray.550" h="4" w="4" />
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
          </Stack>
          <Flex overflowX="auto" overflowY="hidden" direction="column" mt="4">
            {questions.isLoading && (
              <Stack spacing={6}>
                {[...Array(8)].map((e, i) => (
                  <Stack key={i}>
                    <Skeleton startColor="gray.100" endColor="gray.200" height="30px" />
                  </Stack>
                ))}
              </Stack>
            )}
            {!questions.isLoading && (
              <Table variant="simple" size="md" mb={4}>
                <Thead>
                  <Tr color="gray.100" borderTop="1px">
                    <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                      #
                    </Th>
                    <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                      CREATED
                    </Th>
                    <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                      SOAL
                    </Th>
                    <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                      AUTHOR
                    </Th>
                    <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                      RIWAYAT
                    </Th>
                    <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                      LEVEL
                    </Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {questions.data.map((question, index) => (
                    <Tr key={index} fontSize="sm" color="gray.550">
                      <Td>{query.show * query.page + index + 1}</Td>
                      <Td>{utcToLocal(question.createdAt, "DD MMMM YYYY HH:mm")}</Td>
                      <Td maxW="sm" minW="xs">
                        {question.question.replace(/<[^>]*>?/gm, "").substring(0, 200) + "..."}
                      </Td>
                      <Td>{question.user.name}</Td>
                      <Td>{question.history}</Td>
                      <Td textTransform="capitalize">{QUESTION_LEVEL[question.level.toUpperCase()].display}</Td>
                      <Td minW="36">
                        <Menu>
                          <MenuButton
                            px={4}
                            py={2}
                            transition="all 0.2s"
                            borderRadius="md"
                            borderWidth="1px"
                            _focus={{ boxShadow: "none" }}
                            fontWeight="semibold"
                            fontSize="sm"
                          >
                            Atur
                            <Icon ml="2" as={ChevronDownIcon} h="5" w="5" />
                          </MenuButton>
                          <MenuList>
                            <MenuItem onClick={() => setPreviewModal({ isOpen: true, questionId: question.id })}>
                              <HStack w="full">
                                <Icon as={EyeIcon} h="4" w="4" />
                                <Text>Preview</Text>
                              </HStack>
                            </MenuItem>
                            <MenuItem onClick={() => setDeleteModal({ isOpen: true, questionId: question.id })}>
                              <HStack w="full">
                                <Icon as={TrashIcon} h="4" w="4" />
                                <Text>Delete</Text>
                              </HStack>
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
                <Tfoot></Tfoot>
              </Table>
            )}
          </Flex>
        </Flex>
        <Stack spacing="2" direction={["column", "row"]} justify="space-between" mt="4">
          <Text fontSize="sm" color="gray.550">
            Menampilkan 1 sampai {query.show} dari {pagination.totalItems} data
          </Text>
          <Pagination handleChange={handlePaginationChange} totalPages={pagination.totalPages} />
        </Stack>
      </Box>
      <PreviewModal previewModal={previewModal} setPreviewModal={setPreviewModal} />
      {deleteModal.isOpen && (
        <DeleteAlert
          isOpen={deleteModal.isOpen}
          title="Soal"
          isLoading={deleteModal.isLoading}
          action={() => submitDeleteQuestion(deleteModal.questionId)}
          setOpen={setDeleteModal}
        />
      )}
    </Flex>
  )
}

export default ListQuestion
