import Axios from "../helpers/axios"

export const getQuestions = async (query = {}) => {
  try {
    const { show = 10, page = 0, studyId = "", search = "", sortBy = "DESC" } = query
    const { data: response } = await Axios.get(
      `/question?show=${show}&page=${page}&studyId=${studyId}&sortBy=${sortBy}&search=${search}`
    )
    return response
  } catch (err) {
    return err.response.data
  }
}

export const getQuestionByQuestionId = async (id) => {
  try {
    const { data: response } = await Axios.get(`/question/${id}`)
    return response
  } catch (err) {
    return err.response.data
  }
}

export const storeQuestion = async (data) => {
  try {
    const { studyId, userId, level, question, choices, discussion } = data
    const { data: response } = await Axios.post("/question", {
      studyId,
      userId,
      level,
      question,
      choices,
      discussion,
    })
    return response
  } catch (err) {
    return err.response.data
  }
}

export const updateQuestionByQuestion = async (data) => {
  try {
    const { id, studyId, userId, level, choices, correction, question } = data
    const { data: response } = await Axios.put(`/question/${id}`, {
      studyId,
      userId,
      level,
      choices,
      correction,
      question,
    })
    return response
  } catch (err) {
    return err.response.data
  }
}

export const deleteQuestionByQuestionId = async (id) => {
  try {
    const { data: response } = await Axios.delete(`/question/${id}`)
    return response
  } catch (err) {
    return err.response.data
  }
}
