import { useNavigate } from "react-router-dom"
import { Flex, Text, Box } from "@chakra-ui/react"
import { Form } from "./components"
import { useForm } from "react-hook-form"
import { joiResolver } from "@hookform/resolvers/joi"
import Joi from "joi"
import { storeClass } from "../../api/classes"
import { useState } from "react"

function Create() {
  const navigate = useNavigate()
  const schema = Joi.object({
    name: Joi.string().required(),
    categoryId: Joi.string().required(),
    subCategoryId: Joi.string().allow("").allow(null),
    type: Joi.string().required(),
    price: Joi.number().required(),
  })
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    getValues,
  } = useForm({
    resolver: joiResolver(schema),
    defaultValues: {
      subCategoryId: null,
      type: "free",
    },
  })
  const [dataClass, setDataClass] = useState({ isLoading: false, error: null })

  const onSubmit = async (data) => {
    setDataClass({ ...dataClass, isLoading: true })
    if (data.subCategoryId === "") {
      data.subCategoryId = null
    }
    const result = await storeClass(data)
    if (result.success) {
      setDataClass({ ...dataClass, isLoading: false })
      setEmpty()
      navigate("/class")
    } else {
      setDataClass({ ...dataClass, isLoading: false, error: "Kelas gagal ditambahkan" })
    }
  }

  const setEmpty = () => {
    setValue("name", "")
    setValue("categoryId", "")
    setValue("subCategoryId", null)
    setValue("type", "")
    setValue("price", 0)
  }

  return (
    <Flex direction="column">
      <Text color="gray.750" fontSize="2xl" fontWeight="medium">
        Kelas
      </Text>
      <Box bg="white" mt="4" px={[4, 6, 8]} py={8}>
        <Form
          type="create"
          register={register}
          errors={errors}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          watch={watch}
          isLoading={dataClass.isLoading}
          isFormLoading={dataClass.isFormLoading}
          setValue={setValue}
          getValues={getValues}
        />
      </Box>
    </Flex>
  )
}

export default Create
