import {
  Flex,
  Stack,
  Box,
  Text,
  Button,
  FormControl,
  Select,
  Input,
  InputGroup,
  InputRightElement,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tfoot,
  Icon,
  Skeleton,
} from "@chakra-ui/react"
import { useState } from "react"
import { SearchIcon } from "@heroicons/react/outline"
import { useEffect } from "react"
import { getFinancesByContentId } from "../../api/finance"
import { useLocation, useParams } from "react-router-dom"
import { convertToRupiah } from "../../helpers/rupiah"
import { utcToLocal } from "../../helpers/date"
import { PAYMENT_VERIFICATION_METHOD } from "../../constants/payment"

const useQuery = () => new URLSearchParams(useLocation().search)

function FinanceByContent() {
  const shows = [10, 20, 50]
  const [payments, setPayments] = useState({ data: [], isLoading: false, error: null })
  const [pagination, setPagination] = useState({ totalItems: 0, totalPages: 0, currentPage: 0 })
  const [query, setQuery] = useState({
    page: 0,
    show: 10,
    search: "",
  })
  const { contentId } = useParams()
  const queryUrl = useQuery()

  useEffect(() => {
    const GetPayments = async () => {
      setPayments({ ...payments, isLoading: true })
      const result = await getFinancesByContentId(contentId, query)
      if (result.success) {
        const { totalItems, totalPages, currentPage } = result.data
        setPayments({ ...payments, data: result.data.payments, isLoading: false })
        setPagination({ totalItems, totalPages, currentPage })
      }
    }
    GetPayments()

    return () => {
      setPayments({ data: [], isLoading: false, error: null })
    }
  }, [query])

  return (
    <Flex direction="column">
      <Text fontSize="2xl" fontWeight="medium" color="gray.700">
        Detail Keuangan
      </Text>
      <Box bg="white" px={[4, 5, 6]} py={8} mt="4">
        <Flex direction="column" minH="70vh">
          <Text fontSize="lg" color="gray.700" fontWeight="medium">
            {queryUrl.get("class").replaceAll("-", " ")}
          </Text>
          <Stack direction="row" justify="space-between" mt="2" py="4">
            <Flex w={["30%", "10%"]}>
              <FormControl>
                <Select onChange={(e) => setQuery({ ...query, show: e.target.value })}>
                  {shows.map((show, index) => (
                    <option value={show} key={index}>
                      {show}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Flex>
            <FormControl width={["70%", "xs"]}>
              <InputGroup>
                <Input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => {
                    if (e.target.value.length === 0 || e.target.value.length >= 3) {
                      return setQuery({ ...query, search: e.target.value })
                    }
                  }}
                />
                <InputRightElement>
                  <Button variant="ghost" _focus={{ boxShadow: "none" }}>
                    <Icon as={SearchIcon} color="gray.550" h="4" w="4" />
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
          </Stack>
          <Flex overflowX="auto" overflowY="hidden" direction="column" mt="4">
            {payments.isLoading && (
              <Stack spacing={6}>
                {[...Array(8)].map((e, i) => (
                  <Stack key={i}>
                    <Skeleton startColor="gray.100" endColor="gray.200" height="30px" />
                  </Stack>
                ))}
              </Stack>
            )}
            {!payments.isLoading && (
              <Table variant="simple" size="md" mb={4}>
                <Thead>
                  <Tr color="gray.100" borderTop="1px">
                    <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                      #
                    </Th>
                    <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                      TANGGAL
                    </Th>
                    <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                      NAMA
                    </Th>
                    <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                      EMAIL
                    </Th>
                    <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                      HARGA
                    </Th>
                    <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                      METODE
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {payments.data.map((payment, index) => (
                    <Tr key={index} fontSize="sm" color="gray.550">
                      <Td>{query.show * query.page + index + 1}</Td>
                      <Td>{utcToLocal(payment.updatedAt, "DD MMMM YYYY HH:mm")}</Td>
                      <Td>{payment.userContent.user.name}</Td>
                      <Td>{payment.userContent.user.email}</Td>
                      <Td>{convertToRupiah(payment.amount)}</Td>
                      <Td>
                        {payment.verificationMethod === PAYMENT_VERIFICATION_METHOD.AUTOMATIC ? "Otomatis" : "Manual"}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
                <Tfoot></Tfoot>
              </Table>
            )}
          </Flex>
        </Flex>
        <Stack spacing="2" direction={["column", "row"]} justify="space-between" mt="4">
          <Text fontSize="sm" color="gray.550">
            Menampilkan 1 sampai {query.show} dari {pagination.totalItems} data
          </Text>
          {pagination.totalItems > query.show && (
            <Stack direction="row" spacing="1">
              {pagination.currentPage > 0 && (
                <Button variant="outline" onClick={() => setQuery({ ...query, page: query.page - 1 })}>
                  Sebelumnya
                </Button>
              )}
              {[...Array(pagination.totalPages)].map((e, i) => (
                <Button
                  variant={i === pagination.currentPage ? "solid" : "outline"}
                  onClick={() => setQuery({ ...query, page: i })}
                  key={i}
                >
                  {i + 1}
                </Button>
              ))}
              {pagination.currentPage < pagination.totalPages - 1 && (
                <Button variant="outline" onClick={() => setQuery({ ...query, page: query.page + 1 })}>
                  Berikutnya
                </Button>
              )}
            </Stack>
          )}
        </Stack>
      </Box>
    </Flex>
  )
}

export default FinanceByContent
