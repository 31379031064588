import { Flex, Text, Box, Tabs, TabList } from "@chakra-ui/react"
import { Link, useLocation } from "react-router-dom"
import { Admin, Author, Mentor } from "./components"

const featureMenus = [
  {
    menu: "Admin",
    page: "admin",
    component: <Admin />,
  },
  {
    menu: "Author",
    page: "author",
    component: <Author />,
  },
]

const useQuery = () => new URLSearchParams(useLocation().search)

export default function User() {
  return (
    <Flex direction="column">
      <Text color="gray.750" fontSize="2xl" fontWeight="medium">
        User
      </Text>
      <Box bg="white" mt="4" px={[4, 5, 6]} py={8}>
        <Flex direction="column">
          <Tabs overflowX="auto">
            <TabList>
              {featureMenus.map((featureMenu, index) => (
                <Box
                  p={4}
                  borderBottom={useQuery().get("page") === featureMenu.page ? "2px" : "none"}
                  borderBottomColor={useQuery().get("page") === featureMenu.page ? "purple.450" : ""}
                  key={index}
                  position="relative"
                  mb="-2px"
                >
                  <Link to={`/setting/user?page=${featureMenu.page}`}>
                    <Text
                      color={useQuery().get("page") === featureMenu.page ? "purple.450" : "gray.750"}
                      fontSize="sm"
                      fontWeight="medium"
                    >
                      {featureMenu.menu}
                    </Text>
                  </Link>
                </Box>
              ))}
            </TabList>
          </Tabs>
          {featureMenus.map((featureMenu, index) => (
            <Box key={index}>{featureMenu.page === useQuery().get("page") && featureMenu.component}</Box>
          ))}
        </Flex>
      </Box>
    </Flex>
  )
}
