import { Box, Center, Flex, Spinner } from "@chakra-ui/react"
import { useContext, useEffect, useState } from "react"
import { Navbar, Sidebar } from "../components"
import { StateContext } from "../store"
import { getCurrentUser } from "../api/account"
import { USER_ROLE } from "../constants/user"
import { useNavigate, Outlet } from "react-router-dom"

export default function DashboardLayout() {
  const navigate = useNavigate()
  const [_, dispatch] = useContext(StateContext)
  const [isLoading, setLoading] = useState(false)
  useEffect(() => {
    const GetCurrentUser = async () => {
      setLoading(true)
      const result = await getCurrentUser()
      if (result.success) {
        if (result.data.role === USER_ROLE.STUDENT) {
          return navigate("/logout")
        } else {
          dispatch({ type: "SET_CURRENT_USER", payload: result.data })
          dispatch({ type: "SET_IS_AUTHENTICATED", payload: true })
          setLoading(false)
        }
      }
    }
    GetCurrentUser()
    return () => {
      setLoading(false)
      dispatch({ type: "REMOVE_CURRENT_USER" })
      dispatch({ type: "SET_IS_AUTHENTICATED", payload: false })
    }
  }, [])
  return (
    <>
      {isLoading && (
        <Center minH="100vh">
          <Spinner thickness="4px" speed="1s" emptyColor="gray.200" color="purple.450" size="lg" />
        </Center>
      )}
      {!isLoading && (
        <Flex direction="column">
          <Flex mt="52px">
            <Sidebar display={["none", null, "flex"]} />
            <Flex ml={["none", null, "16%"]} overflow="auto" w="full" as="main">
              <Box minH="100vh" w="full" bg="gray.50" px={["3", "6"]} py="8">
                <Outlet />
              </Box>
            </Flex>
          </Flex>
          <Navbar />
        </Flex>
      )}
    </>
  )
}
