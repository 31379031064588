import {
  Flex,
  Text,
  Box,
  Stack,
  Button,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  FormControl,
  InputGroup,
  Select,
  Input,
  InputRightElement,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Checkbox,
  Center,
  Spinner,
  useToast,
} from "@chakra-ui/react"
import { PlusIcon, SearchIcon } from "@heroicons/react/outline"
import { useState, useEffect, useCallback } from "react"
import { useNavigate, useParams } from "react-router-dom"
import parse from "html-react-parser"
import { utcToLocal } from "../../helpers/date"
import { QUESTION_LEVEL } from "../../constants/question"
import { getStudiesByMaterialId } from "../../api/material"
import { getQuestions } from "../../api/question"
import { getQuestionByQuestionId } from "../../api/question"
import { storeContentQuestion } from "../../api/content"
import { Pagination, Toast } from "../../components"

function PreviewModal(props) {
  const initQuestion = {
    id: "",
    question: "",
    createdAt: "",
    level: "easy",
    study: {
      name: "",
    },
    user: {
      name: "",
    },
    correction: {
      discussion: "",
    },
    choices: [],
  }
  const { previewModal, setPreviewModal } = props
  const [question, setQuestion] = useState({ data: { ...initQuestion }, isLoading: false, error: null })
  const onClose = () => {
    setPreviewModal({ ...previewModal, isOpen: false, questionId: "" })
  }
  useEffect(() => {
    const GetQuestion = async () => {
      setQuestion({ ...question, isLoading: true })
      const result = await getQuestionByQuestionId(previewModal.questionId)
      if (result.success) {
        setQuestion({ ...question, data: result.data, isLoading: false })
      }
    }
    if (previewModal.questionId !== "") {
      GetQuestion()
    }
    return () => {
      setQuestion({ data: { ...initQuestion }, isLoading: false, error: null })
    }
  }, [previewModal.questionId])

  return (
    <Modal size="6xl" isOpen={previewModal.isOpen} onClose={onClose} isCentered="'true">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton _focus={{ boxShadow: "none" }} />
        <ModalBody>
          <Flex direction="column" minH="85vh" maxH="85vh" pt="6" pb="4">
            <Text color="gray.750" fontWeight="semibold" fontSize="xl">
              Preview Soal
            </Text>
            {question.isLoading && (
              <Center minH="60vh">
                <Spinner thickness="5px" speed="1s" emptyColor="gray.200" color="purple.450" size="xl" />
              </Center>
            )}
            {!question.isLoading && (
              <Stack mt="6" spacing="6" maxH="65vh" overflowY="auto">
                <Stack spacing="3">
                  <Text color="gray.750" fontWeight="medium">
                    Pertanyaan
                  </Text>
                  <Text color="gray.550">{parse(question.data.question)}</Text>
                  <Stack direction="column" spacing="2">
                    {question.data.choices.map((choice, index) => (
                      <Box
                        key={index}
                        p="2"
                        borderWidth="1px"
                        bg={choice.isCorrect ? "blue.50" : "white"}
                        borderRadius="lg"
                      >
                        <Stack spacing="2" direction="row" color="gray.550">
                          <Flex direction="column">
                            <Text px="4" fontSize="sm" textTransform="capitalize">
                              {String.fromCharCode(97 + index)}
                            </Text>
                          </Flex>
                          <Text>{parse(choice.info)}</Text>
                        </Stack>
                      </Box>
                    ))}
                  </Stack>
                </Stack>
                <Stack spacing="3">
                  <Text color="gray.750" fontWeight="medium">
                    Pembahasan
                  </Text>
                  <Text color="gray.550">{parse(question.data.correction.discussion)}</Text>
                </Stack>

                <Stack spacing="4">
                  <Text color="gray.750" fontWeight="medium">
                    Informasi Soal
                  </Text>
                  <Stack direction="column" spacing="3">
                    <Stack direction="row" fontSize="sm">
                      <Text w={["30%", "15%"]} color="gray.550" fontWeight="medium">
                        Pelajaran
                      </Text>
                      <Text color="gray.750">{question.data.study.name}</Text>
                    </Stack>
                    <Stack direction="row" fontSize="sm">
                      <Text w={["30%", "15%"]} color="gray.550" fontWeight="medium">
                        Level
                      </Text>
                      <Text color="gray.750">{QUESTION_LEVEL[question.data.level.toUpperCase()].display}</Text>
                    </Stack>
                    <Stack direction="row" fontSize="sm">
                      <Text w={["30%", "15%"]} color="gray.550" fontWeight="medium">
                        Author
                      </Text>
                      <Text color="gray.750">{question.data.user.name}</Text>
                    </Stack>
                    <Stack direction="row" fontSize="sm">
                      <Text w={["30%", "15%"]} color="gray.550" fontWeight="medium">
                        Created
                      </Text>
                      <Text color="gray.750">{utcToLocal(question.data.createdAt, "DD MMMM YYYY HH:mm")}</Text>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            )}
            <Flex justify="flex-end">
              <Stack direction="row" mt="10">
                <Button variant="outline" mr={3} onClick={onClose}>
                  Close
                </Button>
              </Stack>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

function QuestionModal(props) {
  const { questionModal, setQuestionModal, setPreviewModal, materials, setMaterials } = props
  const shows = [10, 20, 50]
  const [query, setQuery] = useState({
    page: 0,
    show: 10,
    search: "",
  })
  const [pagination, setPagination] = useState({ totalItems: 0, totalPages: 0, currentPage: 0 })
  const [questions, setQuestions] = useState({ data: [], isLoading: false, error: null })
  const [selectedQuestions, setSelectedQuestions] = useState(questionModal.activeQuestions)

  const onClose = () => {
    setQuestionModal({
      isOpen: false,
      studyId: "",
      study: "",
      activeQuestions: [],
      indexMaterial: null,
      indexStudy: null,
    })
    setSelectedQuestions([questionModal])
  }

  useEffect(() => {
    const GetQuestions = async () => {
      setQuestions({ ...questions, isLoading: true })
      const result = await getQuestions({ ...query, studyId: questionModal.studyId })
      if (result.success) {
        const customQuestions = result.data.questions.map((question) => ({
          isChecked: selectedQuestions.some((item) => item.id === question.id),
          ...question,
        }))
        setQuestions({ ...questions, data: customQuestions, isLoading: false })
        const { totalItems, totalPages, currentPage } = result.data
        setPagination({ totalItems, totalPages, currentPage })
      }
    }
    if (questionModal.studyId !== "") {
      GetQuestions()
    }
  }, [query, questionModal.studyId])

  const handleSelectedQuestion = (id, question) => {
    const indexQuestion = selectedQuestions.map((item) => item.id).indexOf(id)
    if (indexQuestion === -1) {
      const newQuestions = [...selectedQuestions, { id, question }]
      setSelectedQuestions(newQuestions)
    } else {
      const newQuestions = selectedQuestions.filter((item, index) => index != indexQuestion)
      setSelectedQuestions(newQuestions)
    }
  }

  const onSumbit = () => {
    materials.data[questionModal.indexMaterial].studies[questionModal.indexStudy].questions = [...selectedQuestions]
    onClose()
  }

  const handlePaginationChange = useCallback((e) => setQuery({ ...query, page: e.selected }))

  return (
    <Modal isOpen={questionModal.isOpen} onClose={onClose} size="6xl" isCentered="true">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Flex minH="85vh" maxH="85vh" pt="6" pb="2" direction="column">
            <Stack direction={["column", "row"]} justify="space-between" spacing="4">
              <Stack direction="column">
                <Text fontSize="xl" color="gray.750" fontWeight="medium">
                  {questionModal.study}
                </Text>
              </Stack>
              <Flex direction="row" width={["full", "md"]}>
                <FormControl w="40%">
                  <Select onChange={(e) => setQuery({ ...query, show: e.target.value })}>
                    {shows.map((show, index) => (
                      <option value={show} key={index}>
                        {show}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl w="full" ml="2" mr={["0", "8"]}>
                  <InputGroup>
                    <Input
                      type="text"
                      placeholder="Search"
                      onChange={(e) => {
                        if (e.target.value.length === 0 || e.target.value.length >= 5) {
                          return setQuery({ ...query, search: e.target.value })
                        }
                      }}
                    />
                    <InputRightElement>
                      <Button variant="ghost" _focus={{ boxShadow: "none" }}>
                        <Icon as={SearchIcon} color="gray.550" h="4" w="4" />
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
              </Flex>
            </Stack>
            <Stack minH="55vh" mt="8" overflow="auto" direction="column">
              {questions.isLoading && (
                <Flex direction="column">
                  <Center minH="25rem" bg="white">
                    <Spinner thickness="5px" speed="1s" emptyColor="gray.200" color="purple.450" size="lg" />
                  </Center>
                </Flex>
              )}
              {!questions.isLoading && (
                <Table variant="simple" size="md" mb={4} overflowX="auto">
                  <Thead>
                    <Tr color="gray.100" borderTop="1px">
                      <Th></Th>
                      <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                        CREATED
                      </Th>
                      <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                        SOAL
                      </Th>
                      <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                        AUTHOR
                      </Th>
                      <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                        RIWAYAT
                      </Th>
                      <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                        LEVEL
                      </Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {questions.data.map((question, index) => (
                      <Tr key={index} fontSize="sm" color="gray.550">
                        <Td>
                          <Checkbox
                            colorScheme="purple"
                            defaultChecked={question.isChecked}
                            onChange={() => handleSelectedQuestion(question.id, question.question)}
                          />
                        </Td>
                        <Td>{utcToLocal(question.createdAt, "DD MMMM YYYY HH:mm")}</Td>
                        <Td maxW="sm" minW="xs">
                          {question.question.replace(/<[^>]*>?/gm, "").substring(0, 200) + "..."}
                        </Td>
                        <Td>{question.user.name}</Td>
                        <Td>{question.history}</Td>
                        <Td textTransform="capitalize">{QUESTION_LEVEL[question.level.toUpperCase()].display}</Td>
                        <Td>
                          <Button
                            size="sm"
                            fontSize="xs"
                            variant="outline"
                            color="purple.450"
                            borderColor="purple.450"
                            px="4"
                            onClick={() => setPreviewModal({ isOpen: true, questionId: question.id })}
                          >
                            Preview
                          </Button>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                  <Tfoot></Tfoot>
                </Table>
              )}
            </Stack>
            <Stack spacing="2" direction={["column-reverse", "row"]} justify="space-between" mt="6">
              <Pagination handleChange={handlePaginationChange} totalPages={pagination.totalPages} />
              <Text fontSize="sm" color="gray.550">
                Menampilkan 1 sampai {query.show} dari {pagination.totalItems} data
              </Text>
            </Stack>
            <Stack mt="2" direction="row" justify="space-between">
              <Stack direction="column" alignSelf="center">
                <Text color="gray.750" fontSize="md" fontWeight="medium">
                  Pilih Soal ({selectedQuestions.length})
                </Text>
              </Stack>
              <Stack direction="row" spacing="4">
                <Button variant="outline" onClick={onClose}>
                  Batal
                </Button>
                <Button onClick={() => onSumbit()}>Submit</Button>
              </Stack>
            </Stack>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

function Material() {
  const [questionModal, setQuestionModal] = useState({
    isOpen: false,
    studyId: "",
    study: "",
    activeQuestions: [],
    indexMaterial: null,
    indexStudy: null,
  })
  const [previewModal, setPreviewModal] = useState({ isOpen: false, questionId: "" })
  const [materials, setMaterials] = useState({ data: [], isLoading: false, error: null })
  const [storeMaterial, setStoreMaterial] = useState({ isLoading: false })
  const { materialId, contentId } = useParams()
  const navigate = useNavigate()
  const toast = useToast()

  useEffect(() => {
    const GetMaterial = async () => {
      setMaterials({ ...materials, isLoading: true })
      const result = await getStudiesByMaterialId(materialId, contentId)
      if (result.success) {
        let customStudies = result.data.studies.reduce((acc, currentValue) => {
          const indexOfStudy = acc.findIndex((item) => item.studyType === currentValue.studyType.name)
          const study = {
            id: currentValue.study.id,
            name: currentValue.study.name,
            time: currentValue.time,
            questions: currentValue.study.questions,
          }
          if (indexOfStudy < 0) {
            acc = [
              ...acc,
              {
                studyType: currentValue.studyType.name,
                studies: [study],
              },
            ]
          } else {
            acc[indexOfStudy].studies.push(study)
          }
          return acc
        }, [])
        setMaterials({ ...materials, data: customStudies, isLoading: false })
      }
    }
    GetMaterial()
    return () => {
      setMaterials({ data: [], isLoading: false, error: null })
    }
  }, [materialId, contentId])

  const openQuestionModal = ({ indexMaterial, indexStudy, study, studyId }) => {
    const activeQuestions = materials.data[indexMaterial].studies[indexStudy].questions.map((question) => ({
      id: question.id,
      question: question.question,
    }))
    setQuestionModal({ isOpen: true, study, studyId, activeQuestions, indexMaterial, indexStudy })
  }

  const onSubmit = async () => {
    let questions = []
    materials.data.forEach((material) => {
      material.studies.forEach((study) => {
        study.questions.forEach((question) => {
          questions.push(question.id)
        })
      })
    })
    if (questions.length > 0) {
      setStoreMaterial({ isLoading: true })
      const result = await storeContentQuestion({ questions, id: contentId })
      if (result.success) {
        setStoreMaterial({ isLoading: false })
        navigate("/content")
      }
    } else {
      toast({
        position: "top",
        isClosable: true,
        // eslint-disable-next-line react/display-name
        render: () => <Toast message="Tambahkan soal telebih dahulu" color="white" bgColor="red.400" />,
      })
    }
  }

  return (
    <Flex direction="column">
      <Text color="gray.750" fontSize="2xl" fontWeight="medium">
        Materi Kelas
      </Text>
      <Stack direction="column" spacing="4" mt="4">
        {materials.isLoading && (
          <Flex direction="column" bg="white">
            <Center minH="70vh">
              <Spinner thickness="4px" speed="1s" emptyColor="gray.200" color="purple.450" size="lg" />
            </Center>
          </Flex>
        )}
        {!materials.isLoading &&
          materials.data.map((material, indexMaterial) => (
            <Box key={indexMaterial} bg="white" p="6" borderRadius="md">
              <Flex direction="column">
                <Text fontSize="md" fontWeight="semibold" color="gray.600">
                  {material.studyType}
                </Text>
                <Stack direction="column" spacing="2">
                  {material.studies.map((study, indexStudy) => (
                    <Box key={indexStudy} bg="white" mt="4" p={[4, 6]} boxShadow="md" borderRadius="lg">
                      <Stack direction={["column", "row"]} spacing="4" justify="space-between">
                        <Flex direction="row" justify="space-between" align="center">
                          <Text color="gray.550" fontSize="md" fontWeight="medium">
                            {study.name + " (" + study.questions.length + ") "}
                          </Text>
                          <Stack
                            display={["flex", "none"]}
                            direction="row"
                            fontSize="sm"
                            fontWeight="medium"
                            spacing="2"
                            color="gray.550"
                          >
                            <Text>Waktu {study.time} Menit</Text>
                          </Stack>
                        </Flex>
                        <Stack direction="row" justify="space-between" spacing="8">
                          <Stack direction="column" justify="center">
                            <Stack
                              display={["none", "flex"]}
                              direction="row"
                              fontSize="sm"
                              fontWeight="medium"
                              spacing="2"
                              color="gray.550"
                            >
                              <Text>Waktu {study.time} Menit</Text>
                            </Stack>
                          </Stack>
                          <Button
                            variant="outline"
                            size="md"
                            color="purple.450"
                            onClick={() =>
                              openQuestionModal({ indexMaterial, indexStudy, study: study.name, studyId: study.id })
                            }
                          >
                            <Icon as={PlusIcon} h="5" w="5" mr="2" />
                            <Text>Tambah Soal</Text>
                          </Button>
                        </Stack>
                      </Stack>
                      {study.questions.length > 0 && (
                        <Stack direction="column" mt="4" spacing="3">
                          {study.questions.map((question) => (
                            <Box key={question.id} borderWidth="1px" borderRadius="md" overflowX="auto" p="5">
                              <Stack direction="row" spacing="5">
                                <Stack direction="row" justify="space-between" spacing="4" minW="sm" w="full">
                                  <Text color="gray.550" fontSize="md">
                                    {question.question.replace(/<[^>]*>?/gm, "").substring(0, 400) + "..."}
                                  </Text>
                                  <Flex direction="column" alignSelf="center" p="2">
                                    <Button
                                      size="sm"
                                      fontSize="xs"
                                      variant="outline"
                                      color="purple.450"
                                      borderColor="purple.450"
                                      px="4"
                                      onClick={() => setPreviewModal({ isOpen: true, questionId: question.id })}
                                    >
                                      Preview
                                    </Button>
                                  </Flex>
                                </Stack>
                              </Stack>
                            </Box>
                          ))}
                        </Stack>
                      )}
                    </Box>
                  ))}
                </Stack>
              </Flex>
            </Box>
          ))}
      </Stack>
      <Stack direction="row" mt="8" justify="flex-end" spacing="4" w="full">
        <Button variant="outline" w={["full", 1 / 4, 1 / 8]} onClick={() => navigate("/content")}>
          Batal
        </Button>
        <Button w={["full", 1 / 4, 1 / 8]} onClick={() => onSubmit()} isLoading={storeMaterial.isLoading}>
          Submit
        </Button>
      </Stack>
      {questionModal.isOpen && (
        <QuestionModal
          materials={materials}
          setMaterials={setMaterials}
          questionModal={questionModal}
          setQuestionModal={setQuestionModal}
          previewModal={previewModal}
          setPreviewModal={setPreviewModal}
        />
      )}
      <PreviewModal previewModal={previewModal} setPreviewModal={setPreviewModal} />
    </Flex>
  )
}

export default Material
