import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { logout } from "../../api/auth"
import { removeToken } from "../../helpers/token"

export default function Logout() {
  const navigate = useNavigate()
  useEffect(() => {
    const logoutUser = async () => {
      const result = await logout()
      if (result.success) {
        removeToken()
        navigate("/")
      }
    }
    logoutUser()
  }, [])
  return <></>
}
