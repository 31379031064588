import { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { joiResolver } from "@hookform/resolvers/joi"
import Joi from "joi"
import {
  Flex,
  Button,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Text,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Icon,
  HStack,
  Skeleton,
  InputGroup,
  InputRightElement,
  Alert,
  AlertIcon,
  Box,
} from "@chakra-ui/react"
import { PlusIcon, ChevronDownIcon } from "@heroicons/react/solid"
import { PencilIcon, TrashIcon, SearchIcon } from "@heroicons/react/outline"
import {
  deleteStudyByStudyId,
  getStudies,
  getStudyByStudyId,
  storeStudy,
  updateStudyByStudyId,
} from "../../../../api/study"
import { MODAL_TYPE } from "../../../../constants/modal"
import { DeleteAlert } from "../../../../components/Alert"

const schema = Joi.object({
  id: Joi.string().allow(""),
  name: Joi.string().required(),
})

export default function Study() {
  const [changeState, setChangeState] = useState(false)
  const [studies, setStudies] = useState({ data: [], error: null, isLoading: false })
  const [pagination, setPagination] = useState({ totalItems: 0, totalPages: 0, currentPage: 0 })
  const [query, setQuery] = useState({
    page: 0,
    show: 10,
    search: "",
    admin: true,
    role: "student",
  })
  const [study, setStudy] = useState({
    isLoading: false,
    error: null,
  })
  const [formModal, setFormModal] = useState({
    type: MODAL_TYPE.STORE,
    isOpen: false,
    isLoading: false,
    error: null,
  })
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    studyId: "",
    error: null,
    isLoading: false,
  })
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ resolver: joiResolver(schema) })

  useEffect(() => {
    const GetStudies = async () => {
      setStudies({ ...studies, isLoading: true })
      const result = await getStudies(query)
      if (result.success === true) {
        const { totalItems, totalPages, currentPage } = result.data
        setStudies({ ...studies, data: result.data.studies, isLoading: false })
        setPagination({ totalItems, totalPages, currentPage })
      } else {
        setStudies({ ...studies, error: result.error.message, isLoading: false })
      }
    }
    GetStudies()
    return () => {
      setStudies({ data: [], error: null, isLoading: false })
    }
  }, [changeState, query])

  const submitStoreStudy = async (data) => {
    setFormModal({ ...formModal, isLoading: true })
    const result = await storeStudy(data)
    if (result.success === true) {
      setFormModal({ ...formModal, isLoading: false })
      closeModal()
      setChangeState(!changeState)
    } else {
      setFormModal({ ...formModal, isLoading: false, error: result.error.message })
    }
  }
  const submitUpdateStudy = async (data) => {
    setFormModal({ ...formModal, isLoading: true })
    const result = await updateStudyByStudyId(data)
    if (result.success === true) {
      setFormModal({ ...formModal, isLoading: false })
      closeModal()
      setChangeState(!changeState)
    } else {
      setFormModal({ ...formModal, isLoading: false, error: result.error.message })
    }
  }
  const submitDeleteStudy = async (id) => {
    setDeleteModal({ ...deleteModal, isLoading: true })
    const result = await deleteStudyByStudyId(id)
    if (result.success === true) {
      setDeleteModal({ ...deleteModal, isOpen: false, isLoading: false })
      setChangeState(!changeState)
    } else {
      setDeleteModal({ ...deleteModal, error: "Pelajaran gagal dihapus", isLoading: false })
    }
  }
  const closeModal = () => {
    setValue("id", "")
    setValue("name", "")
    setFormModal({ ...formModal, isOpen: false, error: null })
  }

  const openModal = async (studyId = "", type = MODAL_TYPE.STORE) => {
    setFormModal({ ...formModal, type, isOpen: true })
    if (type === MODAL_TYPE.UPDATE) {
      setStudy({ ...study, isLoading: true })
      const result = await getStudyByStudyId(studyId)
      if (result.success) {
        const {
          study: { name, id },
        } = result.data
        setValue("id", id)
        setValue("name", name)
        setStudy({ ...study, isLoading: false })
      }
    }
  }
  const openDeleteModal = (studyId) => {
    setDeleteModal({ ...deleteModal, studyId, isOpen: true })
  }

  return (
    <Flex direction="column" minH="70vh">
      <Flex py="8" justify="space-between" spacing={16} direction={["column", "row"]}>
        <FormControl width={["full", "xs"]}>
          <InputGroup>
            <Input type="text" placeholder="Search" />
            <InputRightElement>
              <Button variant="ghost" _focus={{ boxShadow: "none" }}>
                <Icon as={SearchIcon} color="gray.550" h="4" w="4" />
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <Button onClick={() => openModal()} mt={["2", "0"]}>
          Tambah Pelajaran
          <Icon as={PlusIcon} h="5" w="5" ml="2" />
        </Button>
        <Modal isCentered size="sm" blockScrollOnMount={true} isOpen={formModal.isOpen} onClose={closeModal}>
          <ModalOverlay />
          <ModalContent p="2">
            <ModalHeader>
              <Text color="gray.750">{formModal.type === MODAL_TYPE.STORE ? "Tambah" : "Edit"} Pelajaran</Text>
            </ModalHeader>
            <ModalCloseButton _focus={{ boxShadow: "none" }} />
            <Box px={6}>
              {formModal.error && (
                <Alert size="sm" status="error">
                  <AlertIcon />
                  Pelajaran gagal di{formModal.type === MODAL_TYPE.STORE ? "tambahkan" : "edit"}
                </Alert>
              )}
            </Box>
            <ModalBody my="3">
              <form onSubmit={handleSubmit(formModal.type === MODAL_TYPE.STORE ? submitStoreStudy : submitUpdateStudy)}>
                <Stack spacing="3">
                  {study.isLoading && (
                    <Stack>
                      <Skeleton startColor="gray.100" endColor="gray.200" height="40px" />
                    </Stack>
                  )}
                  {!study.isLoading && (
                    <FormControl>
                      <FormLabel fontSize="sm" color="gray.550">
                        Nama
                      </FormLabel>
                      <Input type="text" {...register("name")} isInvalid={errors.name} />
                      <Text fontSize="sm" color="red.500">
                        {errors.name?.message}
                      </Text>
                    </FormControl>
                  )}
                </Stack>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button mr={3} onClick={closeModal} variant="outline" isDisabled={formModal.isLoading}>
                Batal
              </Button>
              <Button
                onClick={handleSubmit(formModal.type === MODAL_TYPE.STORE ? submitStoreStudy : submitUpdateStudy)}
                isLoading={formModal.isLoading}
                type="submit"
              >
                Simpan
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
      <Flex overflowX="auto" overflowY="hidden" direction="column">
        {studies.isLoading ? (
          <Stack spacing={6}>
            {[...Array(6)].map((e, i) => (
              <Stack key={i}>
                <Skeleton startColor="gray.100" endColor="gray.200" height="30px" />
              </Stack>
            ))}
          </Stack>
        ) : (
          <Table variant="simple" size="md" mb={4}>
            <Thead>
              <Tr color="gray.100" borderTop="1px">
                <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                  NAMA
                </Th>
                <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                  DIBUAT OLEH
                </Th>
                <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                  PERTANYAAN
                </Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {studies.data.map((study) => (
                <Tr key={study.id} fontSize="sm" color="gray.550">
                  <Td fontWeight="semibold">{study.name}</Td>
                  <Td>Super Admin</Td>
                  <Td>{study.totalQuest}</Td>
                  <Td>
                    <Menu>
                      <MenuButton
                        px={4}
                        py={2}
                        transition="all 0.2s"
                        borderRadius="md"
                        borderWidth="1px"
                        _focus={{ boxShadow: "none" }}
                        fontWeight="semibold"
                      >
                        Atur
                        <Icon ml="2" as={ChevronDownIcon} h="5" w="5" />
                      </MenuButton>
                      <MenuList>
                        <MenuItem onClick={() => openModal(study.id, MODAL_TYPE.UPDATE)}>
                          <HStack w="full">
                            <Icon as={PencilIcon} h="4" w="4" />
                            <Text>Edit</Text>
                          </HStack>
                        </MenuItem>
                        <MenuItem onClick={() => openDeleteModal(study.id)}>
                          <HStack w="full">
                            <Icon as={TrashIcon} h="4" w="4" />
                            <Text>Hapus</Text>
                          </HStack>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              ))}
            </Tbody>
            <Tfoot></Tfoot>
          </Table>
        )}
      </Flex>
      <Stack spacing="2" direction={["column", "row"]} justify="space-between" mt="4">
        <Text fontSize="sm" color="gray.550">
          Menampilkan 1 sampai {query.show} dari {pagination.totalItems} data
        </Text>
        {pagination.totalItems > query.show && (
          <Stack direction="row" spacing="1">
            {pagination.currentPage > 0 && (
              <Button variant="outline" onClick={() => setQuery({ ...query, page: query.page - 1 })}>
                Sebelumnya
              </Button>
            )}
            {[...Array(pagination.totalPages)].map((e, i) => (
              <Button
                variant={i === pagination.currentPage ? "solid" : "outline"}
                onClick={() => setQuery({ ...query, page: i })}
                key={i}
              >
                {i + 1}
              </Button>
            ))}
            {pagination.currentPage < pagination.totalPages - 1 && (
              <Button variant="outline" onClick={() => setQuery({ ...query, page: query.page + 1 })}>
                Berikutnya
              </Button>
            )}
          </Stack>
        )}
      </Stack>
      <DeleteAlert
        isOpen={deleteModal.isOpen}
        title="Pelajaran"
        isLoading={deleteModal.isLoading}
        action={() => submitDeleteStudy(deleteModal.studyId)}
        setOpen={setDeleteModal}
      />
    </Flex>
  )
}
