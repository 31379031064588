import { useContext, useEffect } from "react"
import { StateContext } from "../../store"
import { Flex, Text, Stack } from "@chakra-ui/react"
import {
  Summary,
  Gender,
  TypeBuyer,
  Region,
  RankProduct,
  RankCategory,
  Statistics,
  TodayInformation,
} from "./components"
import { USER_ROLE } from "../../constants/user"
import { useNavigate } from "react-router-dom"

function Dashboard() {
  const navigate = useNavigate()
  const [state] = useContext(StateContext)
  useEffect(() => {
    if (state.currentUser.role === USER_ROLE.AUTHOR) {
      navigate("/content")
      return <></>
    }
  }, [])

  return (
    <Flex direction="column">
      <Text fontSize="2xl" fontWeight="medium" color="gray.700">
        Dashboard
      </Text>
      <Stack mt="3" spacing="8">
        <Stack direction={["column-reverse", "column-reverse", "row"]} spacing="5">
          <Flex direction="column" w={["full", "full", "75%"]}>
            <Text mb="3" fontSize="lg" fontWeight="medium" color="gray.700">
              Statistik
            </Text>
            <Stack spacing="5" direction={["column", "column", "row"]}>
              <Statistics />
            </Stack>
          </Flex>
          <Flex direction="column" w={["full", "50%", "25%"]}>
            <Text mb="3" fontSize="lg" fontWeight="medium" color="gray.700">
              Penting Hari Ini
            </Text>
            <Stack spacing="5" direction={["column", "column", "row"]}>
              <TodayInformation />
            </Stack>
          </Flex>
        </Stack>
        <Flex direction="column">
          <Stack spacing="5" direction={["column", "column", "row"]} mt="-3">
            <Flex w="full">
              <RankCategory />
            </Flex>
            <Flex w="full">
              <RankProduct />
            </Flex>
          </Stack>
        </Flex>
        <Flex direction="column">
          <Text mb="3" fontSize="lg" fontWeight="medium" color="gray.700">
            Ringkasan
          </Text>
          <Summary />
        </Flex>
        <Flex direction="column">
          <Text fontSize="lg" fontWeight="medium" color="gray.700">
            Profile
          </Text>
          <Stack direction={["column", "column", "column", "row"]} mt="3" spacing="5">
            <Gender />
            <TypeBuyer />
          </Stack>
          <Flex mt="5">
            <Region />
          </Flex>
        </Flex>
      </Stack>
    </Flex>
  )
}

export default Dashboard
