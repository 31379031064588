import Axios from "../helpers/axios"

export const getStudies = async (query = {}) => {
  try {
    const { show = 10, page = 0, materialId = "" } = query
    const { data: response } = await Axios.get(`/study?show=${show}&page=${page}&materialId=${materialId}`)
    return response
  } catch (err) {
    return err.response.data
  }
}

export const storeStudy = async (data) => {
  try {
    const { name } = data
    const { data: response } = await Axios.post("/study", { name })
    return response
  } catch (err) {
    return err.response.data
  }
}

export const getStudyByStudyId = async (id) => {
  try {
    const { data: response } = await Axios.get(`/study/${id}`)
    return response
  } catch (err) {
    return err.response.data
  }
}

export const updateStudyByStudyId = async (data) => {
  try {
    const { id, name } = data
    const { data: response } = await Axios.put(`/study/${id}`, { name })
    return response
  } catch (err) {
    return err.response.data
  }
}

export const deleteStudyByStudyId = async (id) => {
  try {
    const { data: response } = await Axios.delete(`/study/${id}`)
    return response
  } catch (err) {
    return err.response.data
  }
}

export const updateStudyTimeByStudyTimeId = async (data) => {
  try {
    const { studyTimeId, time, studyTypeId } = data
    const { data: response } = await Axios.put(`/study-time/${studyTimeId}`, { time, studyTypeId })
    return response
  } catch (err) {
    return err.response.data
  }
}

export const deleteStudyTimeByStudyTimeId = async (studyTimeId) => {
  try {
    const { data: response } = await Axios.delete(`/study-time/${studyTimeId}`)
    return response
  } catch (err) {
    return err.response.data
  }
}
