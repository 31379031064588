import { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { joiResolver } from "@hookform/resolvers/joi"
import Joi from "joi"
import {
  Flex,
  Button,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Text,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Icon,
  HStack,
  Skeleton,
  InputGroup,
  InputRightElement,
  Alert,
  AlertIcon,
  Box,
  Select,
} from "@chakra-ui/react"
import { PlusIcon, ChevronDownIcon } from "@heroicons/react/solid"
import { PencilIcon, TrashIcon, SearchIcon } from "@heroicons/react/outline"
import { deleteUserByUserId, getUsers, getUserByUserId, storeUser, updateUserByUserId } from "../../../../api/user"
import { MODAL_TYPE } from "../../../../constants/modal"
import { USER_ROLE, USER_STATUS } from "../../../../constants/user"
import { DeleteAlert } from "../../../../components/Alert"

const schema = Joi.object({
  id: Joi.string().allow(""),
  name: Joi.string().required(),
  email: Joi.string().required(),
  phoneNumber: Joi.string().required(),
  password: Joi.string().allow(""),
  status: Joi.string().allow(""),
})

export default function Author() {
  const [changeState, setChangeState] = useState(false)
  const [users, setUsers] = useState({ data: [], error: null, isLoading: false })
  const [user, setUser] = useState({
    isLoading: false,
    error: null,
  })
  const [formModal, setFormModal] = useState({
    type: MODAL_TYPE.STORE,
    isOpen: false,
    isLoading: false,
    error: null,
  })
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    userId: "",
    error: null,
    isLoading: false,
  })
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
  } = useForm({ resolver: joiResolver(schema) })

  useEffect(() => {
    const GetUsers = async () => {
      setUsers({ ...users, isLoading: true })
      const result = await getUsers({ role: USER_ROLE.AUTHOR })
      if (result.success === true) {
        setUsers({ ...users, data: result.data.users, isLoading: false })
      } else {
        setUsers({ ...users, error: result.error.message, isLoading: false })
      }
    }
    GetUsers()
    return () => {
      setUsers({ data: [], error: null, isLoading: false })
    }
  }, [changeState])

  const submitStoreUser = async (data) => {
    setFormModal({ ...formModal, isLoading: true })
    const result = await storeUser({ ...data, role: USER_ROLE.AUTHOR, status: USER_STATUS.ACTIVE })
    if (result.success === true) {
      setFormModal({ ...formModal, isLoading: false })
      closeModal()
      setChangeState(!changeState)
    } else {
      setFormModal({ ...formModal, isLoading: false, error: result.error.message })
    }
  }
  const submitUpdateUser = async (data) => {
    setFormModal({ ...formModal, isLoading: true })
    const result = await updateUserByUserId({ ...data, role: USER_ROLE.AUTHOR })
    if (result.success === true) {
      setFormModal({ ...formModal, isLoading: false })
      closeModal()
      setChangeState(!changeState)
    } else {
      setFormModal({ ...formModal, isLoading: false, error: result.error.message })
    }
  }
  const submitDeleteUser = async (id) => {
    setDeleteModal({ ...deleteModal, isLoading: true })
    const result = await deleteUserByUserId(id)
    if (result.success === true) {
      setDeleteModal({ ...deleteModal, isOpen: false, isLoading: false })
      setChangeState(!changeState)
    } else {
      setDeleteModal({ ...deleteModal, error: "Author gagal dihapus", isLoading: false })
    }
  }
  const closeModal = () => {
    setValue("id", "")
    setValue("name", "")
    setValue("email", "")
    setValue("phoneNumber", "")
    setValue("password", "")
    setValue("status", "")
    setFormModal({ ...formModal, isOpen: false, error: null })
  }

  const openModal = async (userId = "", type = MODAL_TYPE.STORE) => {
    setFormModal({ ...formModal, type, isOpen: true })
    if (type === MODAL_TYPE.UPDATE) {
      setUser({ ...user, isLoading: true })
      const result = await getUserByUserId(userId)
      if (result.success) {
        const { id, name, email, phoneNumber, status } = result.data
        setValue("id", id)
        setValue("name", name)
        setValue("email", email)
        setValue("phoneNumber", phoneNumber)
        setValue("status", status)
        setUser({ ...user, isLoading: false })
      }
    }
  }
  const openDeleteModal = (userId) => {
    setDeleteModal({ ...deleteModal, userId, isOpen: true })
  }

  return (
    <Flex direction="column" minH="70vh">
      <Flex py="8" justify="space-between" spacing={16} direction={["column", "row"]}>
        <FormControl width={["full", "xs"]}>
          <InputGroup>
            <Input type="text" placeholder="Search" />
            <InputRightElement>
              <Button variant="ghost" _focus={{ boxShadow: "none" }}>
                <Icon as={SearchIcon} color="gray.550" h="4" w="4" />
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <Button onClick={() => openModal()} mt={["2", "0"]}>
          Tambah Author
          <Icon as={PlusIcon} h="5" w="5" ml="2" />
        </Button>
        <Modal isCentered size="sm" blockScrollOnMount={true} isOpen={formModal.isOpen} onClose={closeModal}>
          <ModalOverlay />
          <ModalContent p="2">
            <ModalHeader>
              <Text color="gray.750">{formModal.type === MODAL_TYPE.STORE ? "Tambah" : "Edit"} Author</Text>
            </ModalHeader>
            <ModalCloseButton _focus={{ boxShadow: "none" }} />
            <Box px={6}>
              {formModal.error && (
                <Alert size="sm" status="error">
                  <AlertIcon />
                  Author gagal di{formModal.type === MODAL_TYPE.STORE ? "tambahkan" : "edit"}
                </Alert>
              )}
            </Box>
            <ModalBody my="3">
              <form onSubmit={handleSubmit(formModal.type === MODAL_TYPE.STORE ? submitStoreUser : submitUpdateUser)}>
                {user.isLoading && (
                  <Stack spacing="5">
                    <Skeleton startColor="gray.100" endColor="gray.200" height="40px" />
                    <Skeleton startColor="gray.100" endColor="gray.200" height="40px" />
                    <Skeleton startColor="gray.100" endColor="gray.200" height="40px" />
                    <Skeleton startColor="gray.100" endColor="gray.200" height="40px" />
                  </Stack>
                )}
                {!user.isLoading && (
                  <Stack spacing="3">
                    <FormControl>
                      <FormLabel fontSize="sm" color="gray.550">
                        Nama
                      </FormLabel>
                      <Input type="text" {...register("name")} isInvalid={errors.name} />
                      <Text fontSize="sm" color="red.500">
                        {errors.name?.message}
                      </Text>
                    </FormControl>
                    <FormControl>
                      <FormLabel fontSize="sm" color="gray.550">
                        Email
                      </FormLabel>
                      <Input type="text" {...register("email")} isInvalid={errors.email} />
                      <Text fontSize="sm" color="red.500">
                        {errors.email?.message}
                      </Text>
                    </FormControl>
                    <FormControl>
                      <FormLabel fontSize="sm" color="gray.550">
                        Telepone
                      </FormLabel>
                      <Input type="text" {...register("phoneNumber")} isInvalid={errors.phoneNumber} />
                      <Text fontSize="sm" color="red.500">
                        {errors.phoneNumber?.message}
                      </Text>
                    </FormControl>
                    {formModal.type === MODAL_TYPE.STORE && (
                      <FormControl>
                        <FormLabel fontSize="sm" color="gray.550">
                          Password
                        </FormLabel>
                        <Input type="password" {...register("password")} isInvalid={errors.password} />
                        <Text fontSize="sm" color="red.500">
                          {errors.password?.message}
                        </Text>
                      </FormControl>
                    )}
                    {formModal.type === MODAL_TYPE.UPDATE && (
                      <FormControl>
                        <FormLabel fontSize="sm" color="gray.550">
                          Status
                        </FormLabel>

                        <Select placeholder="Select Status" {...register("status")} defaultValue={getValues("status")}>
                          <option value={USER_STATUS.ACTIVE}>{USER_STATUS.ACTIVE}</option>
                          <option value={USER_STATUS.INACTIVE}>{USER_STATUS.INACTIVE}</option>
                          <option value={USER_STATUS.UNVERIFIED}>{USER_STATUS.UNVERIFIED}</option>
                        </Select>
                        <Text fontSize="sm" color="red.500">
                          {errors.status?.message}
                        </Text>
                      </FormControl>
                    )}
                  </Stack>
                )}
              </form>
            </ModalBody>
            <ModalFooter>
              <Button mr={3} onClick={closeModal} variant="outline" isDisabled={formModal.isLoading}>
                Batal
              </Button>
              <Button
                onClick={handleSubmit(formModal.type === MODAL_TYPE.STORE ? submitStoreUser : submitUpdateUser)}
                isLoading={formModal.isLoading}
                type="submit"
              >
                Simpan
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
      <Flex overflowX="auto" overflowY="hidden" direction="column">
        {users.isLoading ? (
          <Stack spacing={6}>
            {[...Array(6)].map((e, i) => (
              <Stack key={i}>
                <Skeleton startColor="gray.100" endColor="gray.200" height="30px" />
              </Stack>
            ))}
          </Stack>
        ) : (
          <Table variant="simple" size="md" mb={4}>
            <Thead>
              <Tr color="gray.100" borderTop="1px">
                <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                  NAMA
                </Th>
                <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                  EMAIL
                </Th>
                <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                  TELEPONE
                </Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {users.data.map((user) => (
                <Tr key={user.id} fontSize="sm" color="gray.550">
                  <Td fontWeight="semibold">{user.name}</Td>
                  <Td>{user.email}</Td>
                  <Td>{user.phoneNumber}</Td>
                  <Td>
                    <Menu>
                      <MenuButton
                        px={4}
                        py={2}
                        transition="all 0.2s"
                        borderRadius="md"
                        borderWidth="1px"
                        _focus={{ boxShadow: "none" }}
                        fontWeight="semibold"
                      >
                        Atur
                        <Icon ml="2" as={ChevronDownIcon} h="5" w="5" />
                      </MenuButton>
                      <MenuList>
                        <MenuItem onClick={() => openModal(user.id, MODAL_TYPE.UPDATE)}>
                          <HStack w="full">
                            <Icon as={PencilIcon} h="4" w="4" />
                            <Text>Edit</Text>
                          </HStack>
                        </MenuItem>
                        <MenuItem onClick={() => openDeleteModal(user.id)}>
                          <HStack w="full">
                            <Icon as={TrashIcon} h="4" w="4" />
                            <Text>Hapus</Text>
                          </HStack>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              ))}
            </Tbody>
            <Tfoot></Tfoot>
          </Table>
        )}
      </Flex>
      <DeleteAlert
        isOpen={deleteModal.isOpen}
        title="Author"
        isLoading={deleteModal.isLoading}
        action={() => submitDeleteUser(deleteModal.userId)}
        setOpen={setDeleteModal}
      />
    </Flex>
  )
}
