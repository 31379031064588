import { Flex, Stack, Box, Text, Tabs, TabList, Avatar, Skeleton } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useLocation, useParams, Link } from "react-router-dom"
import { getUserProfileByUserId } from "../../api/user"
import { getProvinces } from "../../api/region"
import { Account } from "./components"

const useQuery = () => new URLSearchParams(useLocation().search)
function Detail() {
  const { userId } = useParams()
  const initUser = {
    name: "",
    email: "",
    phoneNumber: "",
    dateOfBirth: new Date(),
    gender: "",
    imageUrl: "",
    nameOfInstitution: "",
    provinceId: "",
    province: "",
  }
  const [user, setUser] = useState({ data: initUser, isLoading: false, error: null })

  const GetUserByUserId = async (userId) => {
    setUser({ ...user, isLoading: true })
    const response = await getProvinces()
    if (response.success) {
      const result = await getUserProfileByUserId(userId)
      if (result.success) {
        const userProvince = response.data.provinces.find((item) => item.id === result.data.provinceId)
        setUser({ ...user, data: { ...result.data, province: userProvince?.nama }, isLoading: false })
      }
    }
  }

  useEffect(() => {
    GetUserByUserId(userId)

    return () => {
      setUser({ data: initUser, isLoading: false, error: null })
    }
  }, [userId])

  const menus = [
    {
      display: "Akun",
      page: "account",
      component: <Account user={user} />,
    },
  ]

  return (
    <Flex direction="column">
      <Text color="gray.750" fontSize="2xl" fontWeight="medium">
        Detail Member
      </Text>
      <Stack direction={["column-reverse", "column-reverse", "row"]} mt="4" spacing="5">
        <Flex direction="column" w={["full", "full", "70%"]}>
          <Box bg="white" px={[4, 5, 6]} py={10}>
            <Flex direction="column">
              <Tabs overflowX="auto">
                <TabList>
                  {menus.map((menu, index) => (
                    <Box
                      pb={4}
                      borderBottom={useQuery().get("page") === menu.page ? "2px" : "none"}
                      borderBottomColor={useQuery().get("page") === menu.page ? "purple.450" : ""}
                      key={index}
                      position="relative"
                      mb="-1px"
                    >
                      <Link to={`/member/${userId}?page=${menu.page}`}>
                        <Text
                          color={useQuery().get("page") === menu.page ? "purple.450" : "gray.750"}
                          fontSize="sm"
                          fontWeight="medium"
                        >
                          {menu.display}
                        </Text>
                      </Link>
                    </Box>
                  ))}
                </TabList>
              </Tabs>
              {menus.map((menu, index) => (
                <Box key={index} mt="6">
                  {menu.page === useQuery().get("page") && menu.component}
                </Box>
              ))}
            </Flex>
          </Box>
        </Flex>
        <Flex direction="column" w={["full", "full", "30%"]}>
          <Box bg="white" p="8">
            <Flex direction="column">
              <Flex direction="row" justify="center">
                <Avatar size="xl" name={user.data.name} src={user.data.imageUrl} />
              </Flex>
              <Flex direction="row" justify="center" mt="4">
                {!user.isLoading && (
                  <Text fontSize="lg" fontWeight="medium" color="gray.750">
                    {user.data.name}
                  </Text>
                )}
                {user.isLoading && <Skeleton startColor="gray.100" endColor="gray.200" height="26px" w="full" />}
              </Flex>
            </Flex>
          </Box>
        </Flex>
      </Stack>
    </Flex>
  )
}

export default Detail
