import { Link, useLocation } from "react-router-dom"
import {
  Flex,
  Text,
  Box,
  Tabs,
  TabList,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Icon,
  HStack,
  FormControl,
  InputGroup,
  Input,
  InputRightElement,
  Select,
  Stack,
  Skeleton,
} from "@chakra-ui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"
import { PencilIcon, TrashIcon, SearchIcon, EyeIcon } from "@heroicons/react/outline"
import { useContext, useEffect, useState } from "react"
import { getContents, deleteContentByContentId } from "../../api/content"
import { getCategories } from "../../api/category"
import { DeleteAlert } from "../../components/Alert"
import { utcToLocal } from "../../helpers/date"
import { CONTENT_TYPE } from "../../constants/content"
import { USER_ROLE } from "../../constants/user"
import { StateContext } from "../../store"

const menuClass = ["Semua"]
const useQuery = () => new URLSearchParams(useLocation().search)

export default function List() {
  const [state] = useContext(StateContext)
  const shows = [5, 10, 20, 50]
  const [query, setQuery] = useState({
    page: 0,
    show: 5,
    category: "",
    tab: "0",
    search: "",
    admin: true,
  })
  const [contents, setContents] = useState({ data: [], error: null, isLoading: false })
  const [categories, setCategories] = useState({ data: [], error: null, isLoading: false })
  const [deleteModal, setDeleteModal] = useState({ isOpen: false, isLoading: false, contentId: "" })
  const [pagination, setPagination] = useState({ totalItems: 0, totalPages: 0, currentPage: 0 })

  useEffect(() => {
    const GetContents = async () => {
      setContents({ ...contents, isLoading: true })
      const result = await getContents({ ...query })
      if (result.success === true) {
        const { totalItems, totalPages, currentPage } = result.data
        setContents({ ...contents, data: result.data.contents, isLoading: false })
        setPagination({ totalItems, totalPages, currentPage })
      }
    }
    GetContents()
    return () => {
      setContents({ data: [], error: null, isLoading: false })
      setPagination({ totalItems: 0, totalPages: 0, currentPage: 0 })
    }
  }, [query])

  useEffect(() => {
    const GetCategories = async () => {
      setCategories({ ...categories, isLoading: true })
      const { success, data } = await getCategories()
      if (success) {
        setCategories({ ...categories, data: data.categories, isLoading: false })
      }
    }
    GetCategories()
  }, [])

  const submitDeleteContent = async (id) => {
    setDeleteModal({ ...deleteModal, isLoading: true })
    const result = await deleteContentByContentId(id)
    if (result.success) {
      setDeleteModal({ ...deleteModal, isOpen: false, isLoading: false })
      setContents({ ...contents, data: contents.data.filter((content) => content.id !== id) })
    }
  }

  return (
    <Flex direction="column">
      <Text color="gray.750" fontSize="2xl" fontWeight="medium">
        Konten
      </Text>
      <Box bg="white" mt="4" px={[4, 5, 6]} py={6}>
        <Flex direction="column" minH="70vh">
          <Flex direction="column">
            <Tabs overflowX="auto">
              <TabList>
                {menuClass.map((menu, index) => (
                  <Link to={`/class?tab=${index}`} key={index}>
                    <Box
                      p={4}
                      borderBottom={
                        parseInt(useQuery().get("tab")) === index || (useQuery().get("tab") === null && index === 0)
                          ? "2px"
                          : "none"
                      }
                      borderBottomColor={
                        parseInt(useQuery().get("tab")) === index || (useQuery().get("tab") === null && index === 0)
                          ? "purple.450"
                          : ""
                      }
                      position="relative"
                      mb="-2px"
                      onClick={() => setQuery({ ...query, tab: index.toString() })}
                    >
                      <Text
                        color={useQuery().get("tab") === index ? "purple.450" : "gray.750"}
                        fontSize="sm"
                        fontWeight="medium"
                      >
                        {menu}
                      </Text>
                    </Box>
                  </Link>
                ))}
              </TabList>
            </Tabs>
            <Flex direction={["column", "row"]} justify="space-between" py={8}>
              <Flex w={["full", "xs"]}>
                <FormControl w="2xl">
                  <Select
                    placeholder="Pilih Kategori"
                    onChange={(e) => setQuery({ ...query, category: e.target.value })}
                  >
                    {categories.data.map((category, index) => (
                      <option value={category.slug} key={index}>
                        {category.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl ml="2">
                  <Select onChange={(e) => setQuery({ ...query, show: e.target.value })}>
                    {shows.map((show, index) => (
                      <option value={show} key={index}>
                        {show}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Flex>
              <FormControl width={["full", "xs"]} mt={[2, 0]}>
                <InputGroup>
                  <Input
                    type="text"
                    placeholder="Search"
                    onChange={(e) => {
                      if (e.target.value.length === 0 || e.target.value.length >= 5) {
                        return setQuery({ ...query, search: e.target.value })
                      }
                    }}
                  />
                  <InputRightElement>
                    <Button variant="ghost" _focus={{ boxShadow: "none" }}>
                      <Icon as={SearchIcon} color="gray.550" h="4" w="4" />
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
            </Flex>
            <Flex overflowX="auto" overflowY="hidden" direction="column">
              {contents.isLoading && (
                <Stack spacing={6}>
                  {[...Array(6)].map((e, i) => (
                    <Stack key={i}>
                      <Skeleton startColor="gray.100" endColor="gray.200" height="30px" />
                    </Stack>
                  ))}
                </Stack>
              )}
              {!contents.isLoading && (
                <Table variant="simple" size="md" mb={4}>
                  <Thead>
                    <Tr color="gray.100" borderTop="1px">
                      <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                        KELAS
                      </Th>
                      <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                        KELOMPOK
                      </Th>
                      <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                        PELAKSANAAN
                      </Th>
                      <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                        PELAJARAN
                      </Th>
                      <Th fontSize="14px" fontWeight="semibold" color="gray.550">
                        SOAL
                      </Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {contents.data.map((content, index) => (
                      <Tr key={index} fontSize="sm" color="gray.550">
                        <Td fontWeight="semibold">{content.classBK.name}</Td>
                        <Td textTransform="capitalize">{CONTENT_TYPE[content.type].display}</Td>
                        <Td>
                          {`${utcToLocal(content.start, "DD MMMM YYYY")} -  
                          ${utcToLocal(content.end, "DD MMMM YYYY")}`}
                        </Td>
                        <Td>{content.totalStudy}</Td>
                        <Td>{content.totalQuestion}</Td>
                        <Td>
                          <Menu>
                            <MenuButton
                              px={4}
                              py={2}
                              transition="all 0.2s"
                              borderRadius="md"
                              borderWidth="1px"
                              _focus={{ boxShadow: "none" }}
                              fontWeight="semibold"
                            >
                              Atur
                              <Icon ml="2" as={ChevronDownIcon} h="5" w="5" />
                            </MenuButton>
                            <MenuList>
                              <Link to={`/content/${content.id}/material/${content.materialId}`}>
                                <MenuItem>
                                  <HStack w="full">
                                    <Icon as={EyeIcon} h="4" w="4" />
                                    <Text>Atur Soal</Text>
                                  </HStack>
                                </MenuItem>
                              </Link>
                              {state.currentUser.role !== USER_ROLE.AUTHOR && (
                                <>
                                  <Link to={`/content/update/${content.id}`}>
                                    <MenuItem>
                                      <HStack w="full">
                                        <Icon as={PencilIcon} h="4" w="4" />
                                        <Text>Edit</Text>
                                      </HStack>
                                    </MenuItem>
                                  </Link>
                                  <MenuItem>
                                    <HStack
                                      onClick={() =>
                                        setDeleteModal({ ...deleteModal, isOpen: true, contentId: content.id })
                                      }
                                      w="full"
                                    >
                                      <Icon as={TrashIcon} h="4" w="4" />
                                      <Text>Hapus</Text>
                                    </HStack>
                                  </MenuItem>
                                </>
                              )}
                            </MenuList>
                          </Menu>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                  <Tfoot></Tfoot>
                </Table>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Stack spacing="2" direction={["column", "row"]} justify="space-between" mt="4">
          <Text fontSize="sm" color="gray.550">
            Menampilkan 1 sampai {query.show} dari {pagination.totalItems} data
          </Text>
          {pagination.totalItems > query.show && (
            <Stack direction="row" spacing="1">
              {pagination.currentPage > 0 && (
                <Button variant="outline" onClick={() => setQuery({ ...query, page: query.page - 1 })}>
                  Sebelumnya
                </Button>
              )}
              {[...Array(pagination.totalPages)].map((e, i) => (
                <Button
                  variant={i === pagination.currentPage ? "solid" : "outline"}
                  onClick={() => setQuery({ ...query, page: i })}
                  key={i}
                >
                  {i + 1}
                </Button>
              ))}
              {pagination.currentPage < pagination.totalPages - 1 && (
                <Button variant="outline" onClick={() => setQuery({ ...query, page: query.page + 1 })}>
                  Berikutnya
                </Button>
              )}
            </Stack>
          )}
        </Stack>
      </Box>
      <DeleteAlert
        isOpen={deleteModal.isOpen}
        title="Konten"
        isLoading={deleteModal.isLoading}
        action={() => submitDeleteContent(deleteModal.contentId)}
        setOpen={setDeleteModal}
      />
    </Flex>
  )
}
